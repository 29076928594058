import { Button, Divider } from "@mui/material";
import styled from "styled-components";

export const StyledProfileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-left: 2rem;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-bottom: 1rem;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin-bottom: 1rem;
`;

export const StyledPhoneUpdate = styled.div`
  display: flex;
  gap: 1rem;
  width: 30%;

  @media (max-width: 900px) {
    width: 90%;
    margin-right: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #ffe71d;
  font-size: 1.6rem;
  width: 20%;
  color: #333333;
  align-self: center;
`;
