import { FC } from "react";
import { useState, useEffect } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import {
  StyledFooterMobile,
  StyledList,
  StyledListItemButton,
  StyledListItemText,
} from "./FooterMobile.styled";
import { Collapse } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { uniTokens } from "../../theme/uniTokens";

interface ContentItem {
  id: number;
  title: string;
  url: string;
}

interface DataItem {
  id: number;
  title: string;
  contents: ContentItem[];
}

const FooterMobile: FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, changeLanguage } = useCustomTranslation();

  const handleClick = (itemId: number) => {
    const isOpen = openItems.includes(itemId);
    if (isOpen) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  useEffect(() => {
    const apiUrl = "https://cms-test.basedashboard.com/api/footer";
    const requestBody = {
      lang: localStorage.getItem("language") || "tr",
      device: "mobile",
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    // TODO: use axios instance instead of fetch and location
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setData(data.footer);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      });
  }, [t]);
  useEffect(() => {
    changeLanguage(localStorage.getItem("language") || "tr");
  }, []);

  const renderedData = data.map((item) => (
    <div key={item.id}>
      <StyledListItemButton onClick={() => handleClick(item.id)}>
        <StyledListItemText disableTypography={true}>
          {item.title}
        </StyledListItemText>
        {openItems.includes(item.id) ? (
          <ExpandLess sx={{ color: uniTokens.white }} />
        ) : (
          <ExpandMore sx={{ color: uniTokens.white }} />
        )}
      </StyledListItemButton>
      <Collapse in={openItems.includes(item.id)} timeout="auto" unmountOnExit>
        <StyledList disablePadding>
          {item.contents.map((content: any) => (
            <ListItemButton
              sx={{
                borderBottom: "1px solid black",
              }}
              href={content.url}
              key={content.id}
            >
              <ListItemText
                primary={content.title}
                primaryTypographyProps={{
                  sx: { fontSize: 14 },
                }}
                sx={{
                  color: uniTokens.white,
                  minWidth: "340px",
                }}
              />
            </ListItemButton>
          ))}
        </StyledList>
      </Collapse>
    </div>
  ));
  return (
    <StyledFooterMobile>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          margin: "0",
          padding: "0",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {renderedData}
      </List>
    </StyledFooterMobile>
  );
};

export default FooterMobile;
