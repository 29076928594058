import { useState } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import { FC } from "react";
import { UniInput } from "../../components/CustomComponents";
import { StyledFormGroup, StyledHeader } from "./Registration.styled";
import { InputTypes } from "./Registration";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { uniTokens } from "../../theme/uniTokens";

interface RegistrationThirdPageProps {
  formChange: (type: InputTypes, value: string) => void;
  formState: RegistrationThirdPageState;
  checked0: boolean;
  checked1: boolean;
}

export interface RegistrationThirdPageState {
  username: string;
  usernameError: string;
  password: string;
  passwordError: string;
  passwordAgain: string;
  passwordAgainError: string;
  identityNumber: string;
  identityNumberError: string;
}

const RegistrationThirdPage: FC<RegistrationThirdPageProps> = ({
  formChange,
  formState,
}) => {
  const { t } = useCustomTranslation();
  const handleInputChange = (type: InputTypes) => (value: string) => {
    if (type === InputTypes.IDENTITY_NUMBER) {
      value = value.replace(/\D/g, ""); // Allow only digits
    }
    formChange(type, value);
  };

  const [checked0, setChecked0] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAgain, setShowPasswordAgain] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowPasswordAgain = () =>
    setShowPasswordAgain(!showPasswordAgain);
  return (
    <div>
      <StyledFormGroup>
        <StyledHeader variant="h6">
          {t(`registration.yourAccountIsSafeWithUs`)}
        </StyledHeader>
        <UniInput
          label={t(`registration.username`)}
          onChange={handleInputChange(InputTypes.USER_NAME)}
          value={formState.username}
          error={!!formState.usernameError}
          helperText={formState.usernameError}
        />
        <UniInput
          label={t(`registration.password`)}
          onChange={handleInputChange(InputTypes.PASSWORD)}
          value={formState.password}
          error={!!formState.passwordError}
          helperText={formState.passwordError}
          required
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <UniInput
          label={t(`registration.passwordRepeat`)}
          onChange={handleInputChange(InputTypes.PASSWORD_AGAIN)}
          value={formState.passwordAgain}
          error={!!formState.passwordAgainError}
          helperText={formState.passwordAgainError}
          required
          type={showPasswordAgain ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordAgain}
                  edge="end"
                >
                  {showPasswordAgain ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <UniInput
          label={t(`registration.identityNumber`)}
          onChange={handleInputChange(InputTypes.IDENTITY_NUMBER)}
          value={formState.identityNumber}
          error={!!formState.identityNumberError}
          helperText={formState.identityNumberError}
        />
        <FormControlLabel
          componentsProps={{ typography: { fontSize: "1.4rem" } }}
          sx={{ color: checked0 ? uniTokens.green : uniTokens.red, mb: "10px" }}
          control={
            <Checkbox
              checked={checked0}
              onClick={() =>
                checked0 ? setChecked0(false) : setChecked0(true)
              }
            />
          }
          label={t(`registration.termAccount`)}
        />

        <FormControlLabel
          componentsProps={{ typography: { fontSize: "1.4rem" } }}
          control={
            <Checkbox
              checked={checked1}
              onClick={() =>
                checked1 ? setChecked1(false) : setChecked1(true)
              }
            />
          }
          label={
            <Link
              href="#"
              sx={{ color: checked1 ? uniTokens.green : uniTokens.red }}
            >
              {t(`registration.termsAndConditions`)}
            </Link>
          }
        />
      </StyledFormGroup>
    </div>
  );
};
export default RegistrationThirdPage;
