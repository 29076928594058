import React, { useState, useEffect, useRef } from "react";
import { StyledCodeInput, StyledSubmitButton } from "./TwoFa.styled";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { useBooleanStates } from "../../utils/hooks/useBooleanStates";
import { Switch, TextField, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import Modal from "../../components/CustomComponents/Modal/Modal";
import { Counter } from "./Counter";

export const TwoFaForm = () => {
  let [code, setCode] = useState(new Array(6).fill(""));
  const inputRefs = useRef<Array<React.RefObject<HTMLInputElement>>>([]);

  const { t } = useCustomTranslation();
  const isAllCodeFilled = code.every((digit) => digit.trim() !== "");
  const state = useSelector((state: any) => state);
  const phoneNumber = state.auth.user.phoneNumber;
  let is2FaActive = state.auth.user.twoFactorAuthentication;
  const {
    modalOpen,
    setModalOpen,
    isCodeBoxOpen,
    setIsCodeBoxOpen,
    change2FAStatus,
    setChange2FAStatus,
    sendSMS,
    setSendSMS,
    expired,
    setExpired,
    sendCode,
    setSendCode,
    startTimer,
    setStartTimer,
    sendNewSMS,
  } = useBooleanStates({
    modalOpen: false,
    isCodeBoxOpen: false,
    change2FAStatus: false,
    sendSMS: true,
    expired: false,
    sendCode: false,
    startTimer: false,
    setSendNewSMS: false,
  });

  function DigitInput({
    onChange,
    value,
    autoFocus,
    onKeyDown,
    id,
  }: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    autoFocus: boolean;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    id: string;
  }) {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
        type="tel"
        variant="outlined"
        inputRef={inputRef}
        sx={{
          width: "4rem",
          margin: "10px",
          padding: "0px",
          border: "1px solid #000",
          borderRadius: "5px",
          fontSize: "1.0rem",
          minWidth: "3.0rem",
        }}
      />
    );
  }
  const handleCodeInputChange = (e: any, index: any) => {
    const { value } = e.target;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (
      value &&
      index < code.length - 1 &&
      inputRefs.current[index + 1]?.current
    ) {
      inputRefs.current[index + 1]?.current?.focus();
    }
  };
  const handleBackspace = (index: any) => (e: any) => {
    if (e.key === "Backspace" && !code[index]) {
      const prevIndex = index - 1;
      if (prevIndex >= 0 && inputRefs.current[prevIndex]?.current) {
        inputRefs.current[prevIndex]?.current?.focus();
      }
    }
  };

  const startCountdown = () => {
    setTimeout(() => {
      setExpired(true);
      setSendSMS(true);
    }, 180000);
  };

  return (
    <Container sx={{ textAlign: "center" }}>
      <div>
        {is2FaActive === 0
          ? t("profile.activate2Fa")
          : t("profile.deactivate2Fa")}
      </div>
      <Switch
        checked={change2FAStatus}
        onChange={() => {
          setChange2FAStatus(!change2FAStatus);
        }}
      />
      <Typography variant="h6">{`${localStorage.getItem("language") === "tr" ? `${phoneNumber} numaralı telefona 6 haneli SMS kodu göndereceğiz` : `We'll be sending 6 digit code to ${phoneNumber}`}`}</Typography>

      <Container
        sx={{
          display: !isCodeBoxOpen ? "none" : "flex",
          flexDirection: "column",
        }}
      >
        {startTimer && <Counter />}
        <StyledCodeInput>
          {code.map((digit, index) => (
            <DigitInput
              key={index}
              id={`digit-${index}`}
              value={digit}
              onChange={(e) => handleCodeInputChange(e, index)}
              onKeyDown={handleBackspace(index)}
              autoFocus={false}
            />
          ))}
        </StyledCodeInput>
      </Container>
      <StyledSubmitButton
        sx={{ display: sendSMS ? "inline" : "none" }}
        variant="contained"
        color="primary"
        onClick={() => {
          uniAxiosInstance.get("user-bff/two-factor-authentication/sms/send");
          setSendSMS(false);
          setStartTimer(true);
          setIsCodeBoxOpen(true);
          setModalOpen(true);
          setIsCodeBoxOpen(!isCodeBoxOpen);
          startCountdown();
        }}
        disabled={!change2FAStatus}
      >
        {t("profile.sendSMS")}
      </StyledSubmitButton>
      <StyledSubmitButton
        sx={{ display: sendSMS || expired ? "none" : "inline" }}
        variant="contained"
        onClick={() => {
          const codeString = code.join("");
          uniAxiosInstance.get(
            `user-bff/two-factor-authentication/sms/confirm?code=${codeString}&status=${is2FaActive ? 0 : 1}`,
          );
        }}
        disabled={!setExpired}
      >
        {t("global.submit")}
      </StyledSubmitButton>
      <StyledSubmitButton
        variant="contained"
        sx={{ display: sendNewSMS ? "inline" : "none" }}
        onClick={() => {
          uniAxiosInstance.get("user-bff/two-factor-authentication/sms/send");
          setExpired(false);
          setStartTimer(true);
          setIsCodeBoxOpen(true);
          setModalOpen(true);
          startCountdown();
        }}
      >
        {t("profile.resendSMS")}
      </StyledSubmitButton>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Typography variant="h5">2fa status message !!!</Typography>
      </Modal>
    </Container>
  );
};
