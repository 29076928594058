import styled from "styled-components";
import { Link } from "react-router-dom";
import { uniTokens } from "../../theme/uniTokens";

export const StyledLinkItem = styled(Link)`
  color: ${uniTokens.green};
  text-decoration: none;
  font-size: 1.6rem;
  &:hover {
    //
  }
`;
