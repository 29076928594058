import { Dispatch, FC, SetStateAction, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  StyledPaymentProviderImg,
  StyledPaymentProviderTitle,
  StyledPaymentInfoTitle,
  StyledPaymentInfoText,
} from "../../components/Payment/Payment.styled";

import {
  StyledDetailContainer,
  StyledDetailItem,
  StyledLinkContainer,
  StyledLink,
  StyledCurrencyContainer,
  StyledCurrency,
  StyledDetailForm,
  StyledDetailFormTitle,
  StyledDetailFormButton,
  StyledPaymentButton,
} from "../../components/Payment/PaymentProviderDetail.styled";
import {
  ButtonGroup,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UniInput } from "../CustomComponents";
import {
  IProvider,
  fetchBanks,
  selectBanks,
} from "../../redux/slices/paymentSlice";
import { selectUser } from "../../redux/slices/authSlice";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { TFunction } from "i18next";

interface IPaymentProviderDetailProps {
  selectedProvider: IProvider;
  setSelectedProvider: Dispatch<SetStateAction<IProvider | {}>>;
}

interface ChangeAction {
  payload: object;
}

interface DepositBankFormState {
  amount: number;
  bankTypes: string[];
  bankTypesValue?: number;
  bankTypesMap: { [n: string]: number };
  quickWagers: number[];
  nameValue: string;
  customerNote: string;
}

const initialDepositBankFormState: DepositBankFormState = {
  amount: 0,
  bankTypes: [],
  bankTypesValue: undefined,
  bankTypesMap: {},
  quickWagers: [],
  nameValue: "",
  customerNote: "",
};

const depositBankFormReducer = (
  state: DepositBankFormState,
  action: ChangeAction,
): DepositBankFormState => {
  return { ...state, ...action.payload };
};

const BankPaymentForm: FC<{
  depositBankFormState: DepositBankFormState;
  depositBankFormDispatch: Dispatch<ChangeAction>;
  providerName: string;
  t: TFunction;
}> = ({ depositBankFormState, depositBankFormDispatch, providerName, t }) => (
  <FormGroup sx={{ margin: "1rem" }}>
    {providerName === "BANK_HAVALE" && (
      <>
        <FormControl>
          <InputLabel
            variant="outlined"
            sx={{
              fontSize: "1.6rem",
              marginLeft: "-1rem",
            }}
          >
            {t(`payments.bankType`)}
          </InputLabel>
          <Select
            size="small"
            sx={{ marginTop: "1rem", fontSize: "1.4rem" }}
            value={"" + depositBankFormState.bankTypesValue}
            onChange={(event: SelectChangeEvent) =>
              depositBankFormDispatch({
                payload: { bankTypesValue: event.target.value },
              })
            }
          >
            {Object.entries(depositBankFormState.bankTypesMap).map(
              ([bankName, value]) => (
                <MenuItem
                  sx={{ fontSize: "1.4rem" }}
                  key={value}
                  value={value as number}
                >
                  {bankName}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <UniInput
          label={t(`payments.nameSurname`)}
          value={depositBankFormState.nameValue}
        ></UniInput>
      </>
    )}
    {(providerName === "BANK_HAVALE" || providerName === "UNIBAHIS_OZEL") && (
      <UniInput
        label={t(`payments.customerNote`)}
        onChange={(value) =>
          depositBankFormDispatch({
            payload: {
              customerNote: value,
            },
          })
        }
      ></UniInput>
    )}
    <UniInput
      label={t(`payments.amount`)}
      value={"" + depositBankFormState.amount}
      onChange={(value) => {
        const replacedValue = value.replace(/\D/g, "");

        if (replacedValue) {
          depositBankFormDispatch({
            payload: { amount: +replacedValue },
          });
        }
      }}
    ></UniInput>
    <ButtonGroup
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1rem",
      }}
    >
      {depositBankFormState.quickWagers?.map((wager) => (
        <StyledPaymentButton
          key={wager}
          variant="contained"
          onClick={() =>
            depositBankFormDispatch({ payload: { amount: wager } })
          }
        >
          {wager}
        </StyledPaymentButton>
      ))}
    </ButtonGroup>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6">Deposit</Typography>
      <Typography variant="h6">{depositBankFormState.amount} ₺</Typography>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6">Total</Typography>
      <Typography variant="h6">{depositBankFormState.amount} ₺</Typography>
    </div>
  </FormGroup>
);
const PaymentProviderDetail: FC<IPaymentProviderDetailProps> = ({
  selectedProvider,
  setSelectedProvider,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    description,
    providerId,
    providerName,
    type,
    typeName,
    imageUrl,
    min,
    max,
  } = selectedProvider;
  const [depositBankFormState, depositBankFormDispatch] = useReducer(
    depositBankFormReducer,
    initialDepositBankFormState,
  );
  const user = useAppSelector(selectUser);
  const banks = useAppSelector(selectBanks);

  useEffect(() => {
    dispatch(fetchBanks());
  }, [dispatch]);

  useEffect(() => {
    const providerMap: { [n: string]: number[] } = {
      BANK_HAVALE: [300, 500, 1000],
      BITCOIN: [50, 100, 200],
      ETHEREUM: [50, 100, 200],
      RIPPLE: [50, 100, 200],
      UNIBAHIS_OZEL: [300, 500, 1000],
    };
    const bankTypesMap: DepositBankFormState["bankTypesMap"] = banks.reduce(
      (prev, curr) => {
        return { ...prev, [curr.desc]: curr.code };
      },
      {},
    );

    const bankTypes = Object.keys(bankTypesMap);

    depositBankFormDispatch({
      payload: {
        bankTypes,
        bankTypesMap,
        bankTypesValue: bankTypesMap[bankTypes[0]],
        quickWagers: providerMap[providerName],
        nameValue: `${user?.name} ${user?.surname}`,
      },
    });
  }, [banks.length, user, providerName]);

  const doDeposit = async () => {
    const data = {
      providerId,
      providerName,
      type,
      typeName,
    };

    const windowReference = window.open();

    const response: AxiosResponse<{
      error?: string;
      code: number;
      message: string;
      url: string;
    }> = await uniAxiosInstance("payment-api/deposit/do", {
      method: "POST",
      data,
    });

    if (response && response.data.error && response.data.code) {
      //TODO: show a modal to indicate that there is an error
    } else if (response && response.data.url) {
      (windowReference as Window).location = response.data.url;
    }
  };

  const doManuelDepositBank = async () => {
    const { amount, bankTypesValue, nameValue, customerNote } =
      depositBankFormState;

    const data = {
      providerId,
      providerName,
      type,
      typeName,
      amount,
      bank: bankTypesValue,
      nameSurname: nameValue,
      customerNote,
    };

    const response: AxiosResponse<{
      error: string;
      code: number;
      message: string;
    }> = await uniAxiosInstance("payment-api/deposit/do-manual", {
      method: "POST",
      data,
    });

    if (response && response.data.error && response.data.code) {
      // TODO: show a error modal with translation
    } else if (
      response &&
      response.data.code === 200 &&
      response.data.message
    ) {
      alert(t(`payments.yourRequestHasBeenReceived`));
    }
  };

  const doManuelDepositCoin = async () => {
    const { amount, customerNote } = depositBankFormState;

    const data = {
      customerNote,
      providerId,
      providerName,
      type,
      typeName,
      amount,
    };

    const response: AxiosResponse<{
      error: string;
      code: number;
      message: string;
    }> = await uniAxiosInstance("payment-api/deposit/do-manual", {
      method: "POST",
      data,
    });

    if (response && response.data.error && response.data.code) {
      // TODO: show an error message with the following text t(`errorMessages.${response.data.code}`) || response.data.code
    } else if (
      response &&
      response.data.code === 200 &&
      response.data.message
    ) {
      alert(t(`payments.yourRequestHasBeenReceived`));
    }
  };
  const handleReturn = () => {
    setSelectedProvider({});
  };
  return (
    <StyledDetailContainer>
      <StyledDetailItem>
        <StyledLinkContainer>
          <ChevronLeftIcon
            sx={{ width: "20px", height: "20px", color: "#147b45" }}
          />
          <StyledLink onClick={handleReturn}>
            {t(`payments.changePaymentMethod`)}
          </StyledLink>
        </StyledLinkContainer>
        <StyledPaymentProviderImg src={imageUrl} />
        <StyledPaymentProviderTitle>{description}</StyledPaymentProviderTitle>
        <StyledCurrencyContainer>
          <StyledCurrency>
            <StyledPaymentInfoTitle>{t(`payments.min`)}</StyledPaymentInfoTitle>
            <StyledPaymentInfoText>{min} ₺</StyledPaymentInfoText>
          </StyledCurrency>
          <StyledCurrency>
            <StyledPaymentInfoTitle>{t(`payments.max`)}</StyledPaymentInfoTitle>
            <StyledPaymentInfoText>{max} ₺</StyledPaymentInfoText>
          </StyledCurrency>
        </StyledCurrencyContainer>
      </StyledDetailItem>
      <StyledDetailForm>
        <StyledDetailFormTitle>
          {t(`depositProviders.${providerName}`)}
        </StyledDetailFormTitle>
        {type === -1 && (
          <BankPaymentForm
            depositBankFormDispatch={depositBankFormDispatch}
            depositBankFormState={depositBankFormState}
            providerName={providerName}
            t={t}
          />
        )}
        <StyledDetailFormButton
          onClick={() => {
            if (type === -1 && providerName === "BANK_HAVALE") {
              doManuelDepositBank();
            } else if (type === -1) {
              doManuelDepositCoin();
            } else {
              doDeposit();
            }
          }}
        >
          {t(`payments.moneyDeposit`)}
        </StyledDetailFormButton>
      </StyledDetailForm>
    </StyledDetailContainer>
  );
};

export default PaymentProviderDetail;
