const errorMessages = {
  4001: "Your session has been terminated. Please login your account.",
  4002: "",
  4003: "",
  4004: "You've been logged out. Please log in again or contact Customer Support for help.",
  4005: "",
  4009: "",
  4010: "",
  4011: "",
  4012: "",
  4013: "",
  4014: "",
  4015: "",
  4058: "Please check your login details and try again.",
  4059: "You have entered too many incorrect member numbers and/or passwords! You can get information about the subject by connecting to the live support line.",
  4060: "Problem occurred related login request. Please get support from chat operator.",
  4061: "You cannot withdraw while you have an active bonus.",
  4062: "",
  4063: "",
  4064: "",
  4065: "",
  4066: "",
  4067: "",
  4068: "",
  4069: "",
  4070: "",
  4071: "",
  4072: "",
  4073: "",
  4074: "You have already pending deposit request",
  4075: "You have already pending withdrawal request",
  4076: "Girilen tutar max ve min arasinda degildir.",
  4077: "You have unsufficient balance for this process.",
  4088: "Problem occurred related withdrawal request. Please get support from chat operator",
  4099: "Problem occurred related deposit request. Please get support from chat operator",
  4100: "Problem occurred related login request. Please get support from chat operator",
  4101: "Problem occurred related play request. Please get support from chat operator",
  4102: "",
  4103: "",
  4104: "",
  4105: "Your identity number does not match.",
  4122: "An unexpected error occurred, please try again later.",
  4123: "Please check your login details and try again.",
};

export default errorMessages;
