import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { uniTokens } from "../../theme/uniTokens";
import { useNavigate } from "react-router-dom";
import { Message } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import ProfileMenuAccordion from "./ProfileMenuAccordion";
import { ProfileMenuContext } from "../../ProfileMenuContext";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { logout } from "../../redux/slices/authSlice";
import Modal from "../CustomComponents/Modal/Modal";

const StyledDrawerBox = styled(Box)`
  background-color: ${uniTokens.grey};
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 16px;
  max-height: 100vh;
  overflow: auto;
`;

const StyledAccordionTitle = styled(Typography)`
  font-size: 14px;
`;

const StyledMessagesWrapper = styled(Box)`
  display: flex;
  gap: 8px;
  padding-left: 16px;
  color: white;
  padding-top: 8px;
  margin-bottom: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledLogoutWrapper = styled(Box)`
  position: fixed;
  bottom: 0;
  background-color: ${uniTokens.grey};
  right: 0;
  width: 360px;
  padding: 0 32px;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`;

const ProfileMenu = () => {
  const navigate = useNavigate();
  const username = useAppSelector((state) => state.auth.user?.username);
  const userId = useAppSelector((state) => state.auth.user?.userId);
  const totalBalance = useAppSelector((state) => state.auth.user?.totalBalance);
  const casinoBonus = useAppSelector((state) => state.auth.user?.casinoBonus);
  const currency = useAppSelector((state) => state.auth.user?.currency_name);
  const sportsBonus = useAppSelector(
    (state) => state.auth.user?.sportsBookBonus,
  );

  const lastLoginDate = useAppSelector(
    (state) => state.auth.user?.lastLoginDate,
  );
  const { t } = useCustomTranslation();
  const { isMenuOpen, setIsMenuOpen } = React.useContext(ProfileMenuContext);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    navigator.clipboard.writeText(userId ?? "");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Drawer
      PaperProps={{ style: { width: isMobile ? "100%" : "360px" } }}
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      anchor="right"
    >
      <StyledDrawerBox>
        <IconButton
          onClick={() => setIsMenuOpen(false)}
          sx={{ position: "absolute", top: "1%", right: "3%" }}
        >
          <CloseIcon sx={{ color: uniTokens.white }} />
        </IconButton>
        <Divider
          variant="fullWidth"
          sx={{
            pt: 4,
            mb: 2,
            borderColor: uniTokens.divider,
          }}
        />
        <Box display="flex" px={2} justifyContent="space-between">
          <Typography sx={{ fontSize: 14, color: uniTokens.white }}>
            {t(`global.mainBalance`)}
          </Typography>

          <Typography
            sx={{ fontSize: 14, color: uniTokens.white }}
            textAlign="right"
          >
            {} TRY
          </Typography>
        </Box>
        <Box mt={2} display="flex" px={2} justifyContent="space-between">
          <Typography sx={{ fontSize: 14, color: uniTokens.white }}>
            Bonus
          </Typography>

          <Typography sx={{ fontSize: 14, color: "#3aaa35" }} textAlign="right">
            {casinoBonus ?? 0 + (sportsBonus ?? 0)} {currency}
          </Typography>
        </Box>
        <Box mt={2} display="flex" px={2} justifyContent="space-between">
          <Typography sx={{ fontSize: 16, color: uniTokens.white }}>
            {t(`global.totalFunds`)}
          </Typography>

          <Typography sx={{ fontSize: 16, color: "#3aaa35" }} textAlign="right">
            {totalBalance} TRY
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ my: 2, width: "100%", fontSize: 14 }}
          onClick={() => {
            navigate("/balance/deposit");
            setIsMenuOpen(false);
          }}
        >
          Yatırım
        </Button>

        <ProfileMenuAccordion />
        <Box
          onClick={() => {
            navigate("/messages");
            setIsMenuOpen(false);
          }}
        >
          <Divider
            variant="fullWidth"
            sx={{
              borderColor: uniTokens.divider,
            }}
          />
          <StyledMessagesWrapper>
            <Message />
            <StyledAccordionTitle>
              {t(`profileMenu.messages`)}
            </StyledAccordionTitle>
          </StyledMessagesWrapper>
        </Box>
        <Divider
          variant="fullWidth"
          sx={{
            borderColor: uniTokens.divider,
          }}
        />
        <Typography
          pl={2}
          color={uniTokens.white}
          fontSize={18}
          fontWeight="bold"
          mt={1}
          mb={2}
        >
          {username}
        </Typography>
        <Typography
          display="flex"
          alignItems="center"
          gap={0.5}
          pl={2}
          color="#a9a9a9"
          fontSize={14}
          mt={1}
          onClick={() => {
            navigator.clipboard.writeText(userId ?? "");
            handleOpenModal();
          }}
        >
          {userId}
          <FileCopyIcon sx={{ color: "#3aaa35", width: 20 }} />
        </Typography>
        <Typography pl={2} color="#a9a9a9" fontSize={14} mt={1}>
          {`Son Giriş: ${lastLoginDate}`}
        </Typography>
        <StyledLogoutWrapper>
          <Divider
            variant="fullWidth"
            sx={{
              mb: 1,
              pt: 0,
              borderColor: uniTokens.divider,
            }}
          />
          <Typography
            display="flex"
            alignItems="center"
            gap={1}
            pb={1.5}
            color={uniTokens.white}
            fontSize={13}
            onClick={() => {
              dispatch(logout());
            }}
            sx={{ cursor: "pointer" }}
          >
            {t(`global.logout`)}
            <ArrowCircleLeftRoundedIcon />
          </Typography>
        </StyledLogoutWrapper>
      </StyledDrawerBox>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Typography variant="h5">User ID copied to clipboard!</Typography>
      </Modal>
    </Drawer>
  );
};

export default ProfileMenu;
