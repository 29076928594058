const registration = {
  firstName: "Ad",
  lastName: "Soyad",
  email: "E-Posta",
  emailPlaceholder: "ornek@gmail.com",
  dateOfBirth: "Doğum Tarihi",
  gender: "Cinsiyet",
  male: "Erkek",
  female: "Kadın",
  continue: "Devam Et",
  back: "Geri",
  address: "Adres",
  postCode: "Posta Kodu",
  city: "Şehir",
  countryOfResidence: "Ülke",
  mobileNumber: "Cep Telefonu",
  currency: "Para Birimi",
  username: "Kullanıcı Adı",
  password: "Şifre",
  passwordRepeat: "Şifre Tekrar",
  join: "Kayıt Ol",
  day: "Gün",
  month: "Ay",
  year: "Yıl",
  firstHeader: "Kişisel detaylarınızla başlayalım",
  registerWithUs: "Kayıt olun",
  hi: "Merhaba",
  greatHavingYouWithUs: "bizimle olman harika!",
  addressPlaceHolder: "Sokak Adı & Apartman Numarası",
  identityNumber: "Kimlik Numarası",
  yourAccountIsSafeWithUs: "Hesabınız bizimle güvende.",
  termAccount:
    "18 yaşında veya daha büyüğüm ve bir hesaba sahip olmak için şartlar ve koşullar ile gizlilik bildirimini kabul ediyorum.",
  termsAndConditions:
    "Unibahis Hükümler ve Koşullarını okudum ve kabul ediyorum.",
  alreadyHaveAccount: "Zaten bir hesabınız var mı?",
  login: "Giriş",
  errorMessages: {
    firstName:
      "Adınızı yazmanız gerekmektedir. Rakam ve özel karakterler kullanamazsınız.",
    lastName:
      "Soyadınızı yazmanız gerekmektedir. Rakam ve özel karakterler kullanamazsınız.",
    email: "Yanlış e-posta adresi formatı.",
    dateOfBirth: "Lütfen doğum tarihinizi seçiniz.",
    phoneNumber:
      "Cep telefonu alanı min. 6, maks. 16 rakam içerebilir. Cep telefonu numarası çok kısa veya çok uzun.",
    phoneNumberWithTurkey:
      "Cep telefonu alanı sadece 10 rakam olabilir. Cep telefonu numarası çok kısa veya çok uzun.",
    address: "Adres alanı 3-250 karakter olabilir.",
    username:
      "Kullanıcı adı alanı min. 4, maks. 15 karakter ile (-,_,|) sembollerini barındırabilir.",
    password:
      "Şifreniz, en az bir tane harf ve bir tane rakam olan, en az 8 en fazla 19 karakter içermelidir.",
    rePassword: "Şifreler uyuşmuyor.",
    identityNumber: "TC kimlik numarası 11 haneden ibaret olmalıdır (Tur).",
    // // gender: "Please tell us if you are male or female",
    postCode: "Posta kodu 3-7 karakter olabilir.",
    city: "Şehir alanı zorunludur.",
    country: "Ülke alanı zorunludur.",

    // // mobileCode: "Your mobile country code is not valid",
    // // currency: "Your currency is not valid",
    // // agree:"Please acknowledge you accept the terms and conditions and privacy notice by checking the box.",
  },
  successRegisterMessage: "Başarıyla kayıt işlemi gerçekleştirildi",
  captchaError:
    "Kayıt işlemi başarız oldu, lütfen tekrar deneyiniz. Sorun devam ederse canlı destek ile iletişime geçiniz.",
  errorList: {
    4011: "Kullanıcı zaten mevcut.",
    4117: "Kullanıcı adı ve soyadı boş olamaz.",
    4118: "Kullanıcı adı 30 karakterden az olmalıdır.",
    4119: "Kullanıcı soyadı 30 karakterden az olmalıdır.",
    4003: "Telefon numarası sistemimizde kayıtlı.",
    4001: "T.C. kimlik numarası sistemimizde kayıtlı.",
    4002: "E-posta adresi sistemimizde kayıtlı.",
    4012: "18 yaşından küçükler giremez.",
  },
};

export default registration;
