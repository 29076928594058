import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import AuthSlice from "./slices/authSlice";
import PromotionsSlice from "./slices/promotionsSlice";
import GeneralInfoSlice from "./slices/generalInfoSlice";
import PaymentSlice from "./slices/paymentSlice";

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    promotions: PromotionsSlice,
    generalInfo: GeneralInfoSlice,
    payment: PaymentSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
