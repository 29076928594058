import styled from "styled-components";
import {
  Box,
  Container,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";

export const StyledMainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: ${uniTokens.backgroundLightGrey};
`;

export const StyledRegistrationContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 1rem auto;
  width: 460px;
  background-color: ${uniTokens.white};

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledHeader = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: ${uniTokens.grey};
  text-align: left;
  font-size: 2rem;
`;

export const StyledFormGroup = styled(FormGroup)`
  padding: 0;
  margin: 0 auto;
  width: 80%;
`;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 80%;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const StyledDateContainer = styled(FormGroup)`
  margin-bottom: 2rem;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledPhoneNumberContainer = styled.div``;

export const StyledFormHelperText = styled(FormHelperText)`
  display: flex;
  color: red;
  justify-content: start;
  width: 100%;
  font-size: 12px;
`;

export const StyledIconWrapper = styled.div`
  background-color: #2f4453;
  padding: 0.5rem;
  border-radius: 5px;
`;
