import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchPaymentProviders,
  selectPaymentProviders,
  selectPaymentProviderListLoading,
  IProvider,
} from "../../redux/slices/paymentSlice";

import {
  StyledPaymentListItem,
  StyledPaymentProvider,
  StyledPaymentProviderImg,
  StyledPaymentProviderTitle,
  StyledPaymentInfo,
  StyledPaymentInfoContainer,
  StyledPaymentInfoTitle,
  StyledPaymentInfoText,
  StyledPaymentDetailIconContainer,
} from "../../components/Payment/Payment.styled";

interface IPaymentListProps {
  paymentType: "deposit" | "withdraw";
  setSelectedProvider: (provider: IProvider) => void;
}
const PaymentList: FC<IPaymentListProps> = ({
  paymentType,
  setSelectedProvider,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const providers = useAppSelector(selectPaymentProviders);
  const providerListLoading = useAppSelector(selectPaymentProviderListLoading);

  const handleChangeProvider = (provider: IProvider) => {
    setSelectedProvider(provider);
  };

  useEffect(() => {
    dispatch(fetchPaymentProviders(paymentType));
  }, [paymentType]);

  if (providerListLoading === "pending") {
    return (
      <CircularProgress sx={{ margin: "10px 50%" }} color="inherit" size={16} />
    );
  }

  return (
    <>
      {providers.map((provider) => (
        <StyledPaymentListItem
          key={provider.id}
          onClick={() => {
            handleChangeProvider(provider);
            window.scrollTo(0, 0);
          }}
        >
          <StyledPaymentProvider>
            <StyledPaymentProviderImg src={provider.imageUrl} />
            <StyledPaymentProviderTitle>
              {provider.description}
            </StyledPaymentProviderTitle>
          </StyledPaymentProvider>
          <StyledPaymentInfo>
            <StyledPaymentInfoContainer>
              <StyledPaymentInfoTitle>
                {t("payments.min")}
              </StyledPaymentInfoTitle>
              <StyledPaymentInfoText>{provider.min} ₺</StyledPaymentInfoText>
            </StyledPaymentInfoContainer>
            <StyledPaymentInfoContainer>
              <StyledPaymentInfoTitle>
                {t("payments.max")}
              </StyledPaymentInfoTitle>
              <StyledPaymentInfoText>{provider.max} ₺</StyledPaymentInfoText>
            </StyledPaymentInfoContainer>
            <StyledPaymentDetailIconContainer>
              <ChevronLeftIcon sx={{ width: "20px", height: "20px" }} />
            </StyledPaymentDetailIconContainer>
          </StyledPaymentInfo>
        </StyledPaymentListItem>
      ))}
    </>
  );
};

export default PaymentList;
