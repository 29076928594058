const depositProviders = {
  EXPRESS_PAPARA: "Deposit with Papara",
  EXPRESS_PAY: "Deposit with USDT(TRC20)",
  EXPRESS_COIN: "Deposit with Crypto",
  EXPRESS_HAVALE: "Deposit with Express Transfer",
  EXPRESS_CEP_BANK: "Deposit with Cepbank",
  EXPRESS_QR: "Deposit with Fast QR",
  EXPRESS_CREDIT_CARD: "Deposit with Credit Card",
  EXPRESS_PAYFIX: "Deposit with Payfix",
  MINIPAY_HAVALE: "Havale min. 100 TL",
  BITCOIN:
    "Deposit with Bitcoin To learn our Bitcoin account and make payment use Live Chat.",
  ETHEREUM:
    "Deposit with Ethereum To learn our Ethereum account and make payment use Live Chat.",
  RIPPLE:
    "Deposit with Ripple To learn our Ripple account and make payment use Live Chat.",
  BANK_HAVALE: "Deposit with Bank Transfer",
  PARAZULA: "Deposit with Parazula",
  MEFETE: "Deposit with MEFETE",
  UNIBAHIS_OZEL: "Deposit with Unibahis Special",
} as const;

export default depositProviders;
