import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Counter } from "../routes/Profile/Counter";
import { FC, useEffect, useRef, useState } from "react";

import { StyledCodeInput } from "../routes/Profile/TwoFa.styled";
import useCustomTranslation from "../utils/hooks/useCustomTranslation";
import { uniAxiosInstance } from "../utils/axiosInstances";
import { useSelector } from "react-redux";

export const LoginFAForm: FC<{ username: string; password: string }> = ({
  username,
  password,
}) => {
  const [code, setCode] = useState(new Array(6).fill(""));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [expired, setExpired] = useState(false);
  const { t } = useCustomTranslation();
  useEffect(() => {
    const allFilled = code.every((digit) => digit.trim() !== "");
    setIsButtonDisabled(!allFilled);
  }, [code]);

  useEffect(() => {
    uniAxiosInstance(
      `user-bff/two-factor-authentication/send-for-login?username=${username}`,
    );

    //TODO: re consider this approach once more
    const timer = setTimeout(() => {
      setExpired(true);
    }, 180000);
    // TODO: cleanup function should clean the timer
  }, []);

  const handleChange = (index: number) => (e: { target: { value: any } }) => {
    const newCode = [...code];
    newCode[index] = e.target.value;
    setCode(newCode);

    if (e.target.value && index < code.length - 1) {
      const nextInput = document.getElementById(`digit-${index + 2}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleCodeInputChange = (e: any, index: number) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < code.length - 1) {
      const nextInputIndex = index + 1;
      const nextInput = document.getElementById(`digit-${nextInputIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleBackspace = (index: number) => (e: { key: string }) => {
    if (e.key === "Backspace" && index > 0 && !code[index]) {
      const prevInput = document.getElementById(`digit-${index + 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };
  function DigitInput({
    onChange,
    value,
    autoFocus,
    onKeyDown,
    id,
  }: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    autoFocus: boolean;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    id: string;
  }) {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);
    return (
      <TextField
        id={id}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
        type="tel"
        variant="outlined"
        inputRef={inputRef}
        sx={{
          width: "4rem",
          margin: "10px",
          padding: "0px",
          border: "1px solid #000",
          borderRadius: "5px",
          fontSize: "1.0rem",
          minWidth: "3.0rem",
        }}
      />
    );
  }
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="h4" style={{ marginBottom: "1rem" }}>
          2FA
        </Typography>
        <Counter />
        <Typography variant="h6" style={{ marginTop: "1rem" }}>
          2FA Code
        </Typography>
        <StyledCodeInput style={{ padding: "0" }}>
          {code.map((digit, index) => (
            <DigitInput
              key={index}
              id={`digit-${index}`}
              value={digit}
              autoFocus={index === 0}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCodeInputChange(e, index)
              }
              onKeyDown={handleBackspace(index)}
            />
          ))}
        </StyledCodeInput>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: "1.6rem", marginTop: "20px" }}
          onClick={() => {
            if (isSubmitting) return;
            setIsSubmitting(true);
            uniAxiosInstance.post(
              `user-bff/two-factor-authentication/confirm-and-login`,

              {
                code: code.join(""),
                username,
                password,
                deviceType: "MOBILE", // TODO: get the actual device type
                deviceKey: null, // TODO: get the actual device key,
                trustDeviceStatus: 0, // TODO: get the actual trust device status
              },
            );
          }}
          disabled={isButtonDisabled || expired}
        >
          {t("global.submit")}
        </Button>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          label="Trust This Device"
          sx={{ fontSize: "1.7rem" }}
        />
      </div>
    </div>
  );
};
