import {
  AccountBalance,
  ExpandMore,
  History,
  Person,
} from "@mui/icons-material";
import { uniTokens } from "../../theme/uniTokens";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import { t } from "i18next";
import styled from "styled-components";

import promotionsIcon from "../../assets/images/headerIcons/promotionsIcon.svg";
import { Link } from "react-router-dom";
import { ProfileMenuContext } from "../../ProfileMenuContext";
import { useContext } from "react";

const RIGHT_MENU = [
  {
    title: "balanceManagement",
    icon: <AccountBalance />,
    children: [
      { title: "deposit", to: "/balance/deposit" },
      { title: "withdraw", to: "/balance/withdraw" },
      { title: "history", to: "/balance/history" },
      { title: "pendings", to: "/balance/pendings" },
    ],
  },
  {
    title: "gameHistory",
    icon: <History />,
    children: [
      { title: "openBets", to: "/history/open-bets" },
      { title: "betHistory", to: "/history/bets" },
      { title: "casinoHistory", to: "/history/casino-bets" },
    ],
  },
  {
    title: "bonuses",
    icon: <img src={promotionsIcon} alt="Promotions" />,
    children: [
      { title: "sport", to: "/bonus/sport" },
      { title: "casino", to: "/bonus/casino" },
      { title: "history", to: "/bonus/history" },
    ],
  },
  {
    title: "myProfile",
    icon: <Person />,
    children: [
      { title: "personalDetails", to: "/profile/details" },
      { title: "changePassword", to: "/profile/change-password" },
      { title: "twoFa", to: "/profile/2fa" },
    ],
  },
];

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  border: none;
  color: ${uniTokens.white};
  & .Mui-expanded.MuiAccordionSummary-root {
    background-color: ${uniTokens.backgroundSecondary};
    border-left: 5px solid green;
  }
  & .MuiAccordionSummary-content {
    margin: auto 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  & .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const StyledAccordionTitle = styled(Typography)`
  font-size: 14px;
`;

const ProfileMenuAccordion = () => {
  const { setIsMenuOpen } = useContext(ProfileMenuContext);
  return RIGHT_MENU.map((parentItem, parentMenuIndex) => {
    return (
      <StyledAccordion square key={parentMenuIndex} disableGutters>
        <StyledAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMore sx={{ color: uniTokens.white }} />}
        >
          {parentItem.icon}
          <StyledAccordionTitle>
            {t(`profileMenu.${parentItem.title}`)}
          </StyledAccordionTitle>
        </StyledAccordionSummary>
        <AccordionDetails
          sx={{
            bgcolor: uniTokens.backgroundSecondary,
            color: uniTokens.white,
          }}
        >
          <Box p="4px 12px">
            {parentItem.children?.map((childItem, childMenuIndex) => {
              return (
                <Link
                  key={childMenuIndex}
                  to={childItem.to}
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <Typography
                    mb={
                      childMenuIndex !== parentItem.children.length - 1 ? 2 : 0
                    }
                    fontSize={13}
                  >
                    {t(`profileMenu.${childItem.title}`)}
                  </Typography>
                </Link>
              );
            })}
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    );
  });
};

export default ProfileMenuAccordion;
