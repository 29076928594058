import RightMenuNavigation from "../../components/RightMenuNavigation/RightMenuNavigation";
import Deposit from "./Deposit";
import { Box, Typography } from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { Route, Routes } from "react-router-dom";
import Withdraw from "./Withdraw";
import PaymentHistory from "./PaymentHistory";
import PaymentPendings from "./PaymentPendings";

const data = [
  { title: "deposit", to: "/balance/deposit" },
  { title: "withdraw", to: "/balance/withdraw" },
  { title: "history", to: "/balance/history" },
  { title: "pendings", to: "/balance/pendings" },
];

const Payments = (props: any) => {
  const { t } = useCustomTranslation();
  const translatedData = data.map((item) => ({
    ...item,
    title: t(`payments.${item.title}`),
  }));

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: uniTokens.white,
        padding: "15px 0 0 0",
        margin: "24px 5px",
        minHeight: "80vh",
      })}
    >
      <Typography
        textAlign="center"
        sx={{ fontSize: 14, fontWeight: 900, mb: 1 }}
        variant="h1"
      >
        {t(`payments.payments`)}
      </Typography>
      <RightMenuNavigation data={translatedData} />
      <Routes>
        <Route path="/" element={<Deposit />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="withdraw" element={<Withdraw />} />
        <Route path="history" element={<PaymentHistory />} />
        <Route path="pendings" element={<PaymentPendings />} />
      </Routes>
    </Box>
  );
};

export default Payments;
