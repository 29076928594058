const profile = {
  timeoutLimits: "Zaman Aşımı Sınırı",
  realityChecks: "Gerçeğe Uygunluk",
  depositLimits: "Para Yatırma Limitleri",
  name: "Ad",
  surname: "Soyad",
  birthDate: "Doğum Tarihi",
  address: "Adres",
  city: "Şehir",
  country: "Ülke",
  userName: "Kullanıcı Adı",
  phoneNumber: "Telefon Numarası",
  email: "E-posta Adresi",
  profile: "Profil",
  newPassword: "Yeni Şifre",
  repeatNewPassword: "Yeni Şifre Tekrar",
  currentPassword: "Mevcut Şifre",
  changePassword: "Şifre Değiştir",
  successChangePasswordMessage:
    "Şifre değiştirme işlemi başarılı bir sekilde gerçekleştirilmiştir.",
  errorChangePasswordMessage:
    "Lütfen giriş bilgilerinizi kontrol edip tekrar deneyiniz.",
  successResetPasswordMessage: "Şifreniz başarılı bir şekilde değiştirildi.",
  successResetPasswordDetailFirst: "Şifrenizi istediğiniz zaman profilinizden",
  successResetPasswordCTA: "Kişisel Bilgilerim",
  successResetPasswordDetailSecond: "kısmına giderek değiştirebilirsiniz.",
  myProfile: "Profilim",
  updatePhone: "Telefon Numarası Güncelle",
  updatePhonePlaceholder: "Yeni Telefon (5XX XXX XX XX)",
  updatePhoneBtn: "Güncelle",
  approve: "Onayla",
  enterCode: "Kodu girin",
  resetPassword: "Şifrenizi Sıfırlayın",
  linkExpired: "Bağlantınızın süresi dolmuş. ",
  linkExpiredDetail:
    "Şifrenizi sıfırlamak için kullandığınız bağlantının süresi dolmuş. Merak etmeyin size yeni bağlantı gönderebiliriz.",
  twoFa: "2FA",
  sendSMS: "SMS Gönder",
  activate2Fa: "2FA'yı Aktifleştir",
  deactivate2Fa: "2FA'yı Deaktifleştir",
  resendSMS: "SMS'i Tekrar Gönder",
};

export default profile;
