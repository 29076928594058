import axios from "axios";
export const uniAxiosInstance = axios.create({
  baseURL: "https://api-test.basedashboard.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

const userData: { token?: string; userId?: string } = JSON.parse(
  localStorage.getItem("uniRefreshData") || "{}",
);

if (userData?.token) {
  uniAxiosInstance.defaults.headers.common["Auth-Token"] = userData.token;
  uniAxiosInstance.defaults.headers.common["User-Id"] = userData.userId;
}

uniAxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //@TODO implement unauthorized request error handling
    console.error(error);
    if (error.response.status === 401) {
      // reduxStore.dispatch(loggedOut());
      localStorage.removeItem("uniRefreshData");
      // reduxStore.dispatch(openUnauthorizedPopup(error.response.data.code));
      // history.push("/");
    }
    return Promise.reject(error);
  },
);

export const cmsAxiosInstance = axios.create({
  baseURL: "https://cms-test.basedashboard.com/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

cmsAxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //@TODO implement unauthorized request error handling
    console.error(error);
    if (error.response.status === 401) {
      // reduxStore.dispatch(loggedOut());
      localStorage.removeItem("uniRefreshData");
      // reduxStore.dispatch(openUnauthorizedPopup(error.response.data.code));
      // history.push("/");
    }
    return Promise.reject(error);
  },
);
