import { FC } from "react";

const SportsBook: FC = () => (
  <div
    style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
    }}
  >
    Our Sportsbook Route and it's SubRoutes.
    <div
      style={{
        marginLeft: "auto",
        flexBasis: "300px",
        background: "grey",
        padding: "1rem",
      }}
    >
      Betslip
    </div>
  </div>
);

export default SportsBook;
