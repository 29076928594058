import {
  StyledMobileHeaderContainer,
  StyledLink,
  StyledMainLogo,
  StyledButtonGroup,
  StyledButton,
  StyledBox,
} from "./HeaderMobile.styled";
import uniBahisLogo from "../../assets/images/headerIcons/unibahis-main-logo.svg";
import { uniTokens } from "../../theme/uniTokens";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { UniModal, UniMoneyAmount } from "../CustomComponents";
import Login from "../Login/Login";
import { useContext, useState } from "react";
import useIsMobile from "../../utils/hooks/useIsMobile";
import depositIcon from "../../assets/images/headerIcons/deposit-icon.svg";

import profileIcon from "../../assets/images/headerIcons/profile-icon.svg";
import promotionsIcon from "../../assets/images/headerIcons/promotionsIcon.svg";
import { useAppSelector } from "../../redux/hooks";
import { Divider, Box } from "@mui/material";
import { ProfileMenuContext } from "../../ProfileMenuContext";
import { Link } from "react-router-dom";
import ProfileMenu from "../ProfileMenu/ProfileMenu";

const HeaderMobile: React.FC = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const { t } = useCustomTranslation();
  const isMobile = useIsMobile();

  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const totalBalance = useAppSelector((state) => state.auth.user?.totalBalance);
  const casinoBonus = useAppSelector((state) => state.auth.user?.casinoBonus);

  const { setIsMenuOpen } = useContext(ProfileMenuContext);

  return (
    <StyledMobileHeaderContainer>
      <StyledLink href="/">
        <StyledMainLogo src={uniBahisLogo} alt="unibahis logo" />
      </StyledLink>
      {isAuthenticated ? (
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          // TODO: take a look at this
          sx={{ fontSize: 12, fontWeight: "bold", marginLeft: "auto" }}
          pr={1}
        >
          <img src={depositIcon} alt="Yatırım yap" />
          <UniMoneyAmount amount={totalBalance} />

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderWidth: "0 2px 0 0", borderColor: "#0e5f31" }}
          />
          <img src={promotionsIcon} alt={t(`header.bonus`)} />
          <UniMoneyAmount amount={casinoBonus} />
          <Divider
            orientation="vertical"
            sx={{ borderWidth: "0 2px 0 0", borderColor: "#0e5f31" }}
            flexItem
          />

          <img
            onClick={() => setIsMenuOpen(true)}
            src={profileIcon}
            alt={t(`header.profile`)}
          />
        </Box>
      ) : (
        <StyledButtonGroup>
          <StyledButton
            style={{ backgroundColor: uniTokens.backgroundHeaderButtonGreen }}
            onClick={() => setLoginModalOpen(true)}
          >
            {t(`header.login`)}
          </StyledButton>
          <StyledButton
            component={Link}
            to="/registration"
            style={{
              backgroundColor: uniTokens.backgroundHeaderButtonYellow,
              color: uniTokens.grey,
            }}
          >
            {t(`header.register`)}
          </StyledButton>
        </StyledButtonGroup>
      )}

      <UniModal
        open={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      >
        <Login setLoginModalOpen={setLoginModalOpen} />
      </UniModal>
    </StyledMobileHeaderContainer>
  );
};

export default HeaderMobile;
