import "./App.css";
import { FC } from "react";

const App: FC = () => {
  // do some global app logic here
  // like initializing store etc.
  return null;
};

export default App;
