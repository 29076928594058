import styled from "styled-components";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";

export const StyledFooterMobile = styled.div`
  width: 100%;
  background-color: ${uniTokens.grey};
`;

export const StyledList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: ${uniTokens.grey};
  color: ${uniTokens.grey};
`;

export const StyledListItemButton = styled(ListItemButton)`
  background-color: ${uniTokens.backgroundSecondary};
  color: ${uniTokens.grey};

  &:hover {
    background-color: ${uniTokens.background};
    color: ${uniTokens.white};
  }
`;

export const StyleLanguageChange = styled(List)`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  flex-direction: row;
  align-items: start;
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: ${uniTokens.grey};
  color: ${uniTokens.white};
`;

export const StyledSocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 0rem;
  margin: 0;
  gap: 1rem;
  width: 100%;
  background-color: ${uniTokens.background};
  color: ${uniTokens.white};
`;

export const SocialMediaIcon = styled.a`
  color: ${uniTokens.white};
  text-decoration: none;
  &:hover {
    `;
export const SiteMapContainer = styled.div`
  background-color: ${uniTokens.background};
  color: ${uniTokens.white};
  margin: 0;
  padding: 0;
`;

export const StyledListItemText = styled(ListItemText)`
  color: ${uniTokens.white};
  font-size: 1.4rem;
  text-transformation: uppercase;
`;
