import "./regForm.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
export const SecondPage = () => {
  return (
    <div>
      <div className="header">
        <h1
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "3rem",
          }}
        >
          <div
            onClick={() => {
              alert("add a function to go back to the first page");
            }}
          >
            <ArrowBackIcon />
          </div>
          <span style={{ marginLeft: "7rem" }}>Create an Account</span>
        </h1>
        <h2>Step 1/2: Fill out your details</h2>
      </div>
      <div className="terms">
        <h1>Terms and Conditions</h1>
        <h2>1. STAKE.COM</h2>
        <p>
          1.1 Stake.com is owned and operated by Medium Rare, N.V. (hereinafter
          "Stake", "We" or "Us"), a company with head office at Korporaalweg 10,
          Willemstad, Curaçao. Stake is licensed and regulated by the Government
          of Curaçao under the gaming license 8048/JAZ issued to Antillephone.
          Some credit card payment processing are handled by its wholly owned
          subsidiary, Medium Rare Limited.
        </p>
        <h2>2. IMPORTANT NOTICE</h2>
        <p>
          2.1 By registering on www.stake.com (the “Website”), you enter into a
          contract with Medium Rare N.V., and agree to be bound by (i) these
          Terms and Conditions; (ii) our Privacy Policy; (iii) our Cookies
          Policy and (iv) the rules applicable to our betting or gaming products
          as further referenced in these Terms and Conditions (“Terms and
          Conditions” or “Agreement”), and the betting and/or gaming specific
          rules, and are deemed to have accepted and understood all the terms.
        </p>
        <p>
          2.2 Please read this Agreement carefully to make sure you fully
          understand its content. If you have any doubts as to your rights and
          obligations resulting from the acceptance of this Agreement, please
          consult a legal advisor in your jurisdiction before further using the
          Website(s) and accessing its content. If you do not accept the terms,
          do not use, visit or access any part (including, but not limited to,
          sub-domains, source code and/or website APIs, whether visible or not)
          of the Website.
        </p>
        <h2>3. GENERAL</h2>
        <p>
          3.1 When registering on www.stake.com You (“You”, “Your”, Yourself” or
          the “Player” interchangeably) enter into an agreement with Stake.
        </p>
        <p>
          3.2 This Agreement should be read by You in its entirety prior to your
          use of Stake's service or products. Please note that the Agreement
          constitutes a legally binding agreement between you and Stake.
        </p>
        <p>
          3.3 These Terms and Conditions come into force as soon as you complete
          the registration process, which includes checking the box accepting
          these Terms and Conditions and successfully creating an account. By
          using any part of the Website following account creation, you agree to
          these Terms and Conditions applying to the use of the Website.
        </p>
      </div>
      <div className="checkbox-container">
        <input type="checkbox" id="agree" name="vehicle1" value="Bike" />
        <label htmlFor="agree" style={{ fontSize: "1.5rem", color: "#ffffff" }}>
          I have read and agree to the terms and conditions
        </label>
      </div>
      <div
        className="button-container"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <button
          style={{
            backgroundColor: "#1FFF20",
            color: "black",
            border: "none",
            padding: "10px 20px",
            fontSize: "1.5rem",
            cursor: "pointer",
            alignItems: "center",
            flexBasis: "100%",
            borderRadius: "4px",
          }}
        >
          Play Now
        </button>
      </div>
    </div>
  );
};
