import { FC, ReactNode } from "react";
import { IconButton, Modal as MuiModal } from "@mui/material";
import { StyledModalBox, StyledBackdrop } from "./Modal.styled";
import CloseIcon from "@mui/icons-material/Close";

interface IModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

const Modal: FC<IModalProps> = ({ children, open, onClose }) => {
  return (
    open && (
      <MuiModal
        slots={{ backdrop: StyledBackdrop }}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StyledModalBox>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "3%",
              right: "3%",
              color: "#b1bad3",
            }}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </StyledModalBox>
      </MuiModal>
    )
  );
};

export default Modal;
