import { Dispatch, SetStateAction, useState } from "react";
import { Box } from "@mui/material";
import PaymentList from "../../components/Payment/PaymentList";
import WithdrawProviderDetail from "../../components/Payment/WithdrawProviderDetail";
import { IProvider } from "../../redux/slices/paymentSlice";

const Withdraw = () => {
  const [selectedProvider, setSelectedProvider]: [
    IProvider | {},
    Dispatch<SetStateAction<IProvider | {}>>,
  ] = useState({});

  return (
    <Box>
      {(selectedProvider as IProvider).id ? (
        <WithdrawProviderDetail
          selectedProvider={selectedProvider as IProvider}
          setSelectedProvider={setSelectedProvider}
        />
      ) : (
        <PaymentList
          paymentType="withdraw"
          setSelectedProvider={setSelectedProvider}
        />
      )}
    </Box>
  );
};

export default Withdraw;
