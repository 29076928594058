import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { cmsAxiosInstance } from "../../utils/axiosInstances";
import { AxiosResponse } from "axios";

type IGeneralInfoLeftMenu = {
  id: number;
  title: string;
  position_order: number;
  url: string;
  content_type: number;
}[];

interface IGeneralInfo {
  id: number;
  title: string;
  position_order: number;
  url: string;
  category_id: number;
  html: string;
  device: number;
  content_type: number;
  category_title: string;
  leftMenu: IGeneralInfoLeftMenu;
}

interface IGeneralInfoState {
  data?: IGeneralInfo;
  loading: "idle" | "pending";
  error?: IGeneralInfoError;
}

interface IGeneralInfoRequestPayload {
  device: "desktop" | "mobile";
  footer: boolean;
  footerLeftMenu: boolean;
  lang: string;
  leftMenu: boolean;
  url: string;
}

interface IGeneralInfoResponse {
  success: boolean;
  message: string;
  lang: string;
  detail: IGeneralInfo;
  leftMenu: IGeneralInfoLeftMenu;
}

interface IGeneralInfoError {
  error: Error;
  code?: number;
}

const initialState: IGeneralInfoState = {
  loading: "idle",
  error: undefined,
};

interface IThunkApi {
  dispatch: AppDispatch;
  state: RootState;
  rejectWithValue: IGeneralInfoError;
}

export const fetchGeneralInfo = createAsyncThunk<
  IGeneralInfoResponse,
  IGeneralInfoRequestPayload,
  IThunkApi
>(
  "generalInfo/fetch",
  async (
    { url, ...generalInfoFetchData }: IGeneralInfoRequestPayload,
    { rejectWithValue },
  ) => {
    try {
      const res: AxiosResponse<IGeneralInfoResponse> = await cmsAxiosInstance(
        `/general-info/${url}`,
        {
          method: "POST",
          data: generalInfoFetchData,
        },
      );

      if (
        res.status !== 200 ||
        (res.data as unknown as IGeneralInfoError).error
      ) {
        window.scrollTo(0, 0);
        return rejectWithValue(
          (res.data as unknown as IGeneralInfoError).error,
        );
      } else {
        window.scrollTo(0, 0);
        return res.data as IGeneralInfoResponse;
      }
    } catch (err) {
      return rejectWithValue({
        error: (err as Error).message,
      });
    }
  },
);

const generalInfoSlice = createSlice({
  name: "generalInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneralInfo.pending, (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(fetchGeneralInfo.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (action.payload) {
          state.data = {
            ...action.payload.detail,
            leftMenu: action.payload.leftMenu,
          };
        }

        state.error = undefined;
      }
    });
    builder.addCase(fetchGeneralInfo.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";

        if (action.payload) {
          state.error = action.payload as IGeneralInfoError;
        }
      }
    });
  },
});

export default generalInfoSlice.reducer;

export const selectGeneralInfo = (
  state: RootState,
): IGeneralInfoState["data"] => state.generalInfo.data;
