const registration = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "E-mail",
  emailPlaceholder: "example@gmail.com",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  male: "Male",
  female: "Female",
  continue: "Continue",
  back: "Back",
  address: "Address",
  postCode: "Post Code",
  city: "City",
  countryOfResidence: "Country Of Residence",
  mobileNumber: "Mobile Number",
  currency: "Currency",
  username: "Username",
  password: "Password",
  passwordRepeat: "Password Repeat",
  join: "Join",
  day: "Day",
  month: "Month",
  year: "Year",
  firstHeader: "Let's start with your personal details.",
  registerWithUs: "Register with us",
  hi: "Hi",
  greatHavingYouWithUs: "great having you with us!",
  addressPlaceHolder: "Street Name & Number",
  identityNumber: "Identity Number",
  yourAccountIsSafeWithUs: "Your Account is safe with us",
  termAccount:
    "I am 18 years or older and accept the terms and conditions and privacy notice for having an account.",
  termsAndConditions:
    "I’ve read and accept the Terms and Conditions of Unibahis.",
  alreadyHaveAccount: "Already have an account?",
  login: "Login",
  errorMessages: {
    firstName:
      "You must write your name. You cannot use numbers, and special characters.",
    lastName:
      "You must write your surname. You cannot use numbers, and special characters.",
    email: "Wrong Email address format characters",
    dateOfBirth: "Please select your birth date",
    phoneNumber:
      "Mobile field is required. Mobile field must contain min. 6 and max. 16 digits only",
    phoneNumberWithTurkey:
      "Mobile field is required. Mobile field must be 10 digits only",
    address: "Adress field must contain from 3 to 250 characters.",
    username:
      "Username field can contain min. 4, max. 15 characters and special characters (-,_, |).",
    password:
      "Your password must contain at least 8 and no more than 19 characters, including at least one letter and one number.",
    rePassword: "Passwords do not match.",
    identityNumber:
      "Passport/ID number field must contain minimum 5 and maximum 20 symbols including only Latin letters and/or number",
    // // gender: "Please tell us if you are male or female",
    postCode: "Post code must contain from 3 to 7 characters",
    city: "City field is required",
    country: "Country field is required.",

    // // mobileCode: "Your mobile country code is not valid",
    // // currency: "Your currency is not valid",
    // // agree:"Please acknowledge you accept the terms and conditions and privacy notice by checking the box.",
  },
  successRegisterMessage: "You have successfully registered",
  captchaError:
    "Registration failed, please try again later. If the problem persists, please contact our live support.",
  errorList: {
    4011: "User already exists.",
    4117: "User name and surname must be at least 4 characters long.",
    4118: "User name must be less than 30 characters long.",
    4119: "User surname must be less than 30 characters long.",
    4003: "Phone number registered in our system.",
    4001: "Identity number registered in our system.",
    4002: "Email registered in our system.",
    4012: "Users under the age of 18 cannot register.",
  },
};
export default registration;
