import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import Root from "./routes/Root";
import Index from "./routes/Index";
import Casino from "./routes/Casino";
import SportsBook from "./routes/Sportsbook";
import Registration from "./routes/Registration/Registration";
import "./index.css";
import "../localization/i18next.js";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-datepicker/dist/react-datepicker.css";
import Payments from "./routes/Payments/Payments";
import Promotions from "./routes/Promotions";
import StaticContent from "./routes/StaticContentPage";
import Messages from "./routes/Messages/Messages";
import LiveCasino from "./routes/LiveCasino";
import LiveSports from "./routes/LiveSports";
import VirtualSports from "./routes/VirtualSports";
import Profile from "./routes/Profile/Profile";
import { Bonuses } from "./routes/Bonuses/Bonuses";
import GameHistory from "./routes/GameHistory/GameHistory";
import ResetPassword from "./routes/Profile/ResetPassword";

const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <Root>
        <App />
      </Root>
    ),
    children: [
      { index: true, element: <Index /> },
      { path: "casino", element: <Casino /> },
      { path: "sportsbook", element: <SportsBook /> },
      { path: "registration", element: <Registration /> },
      { path: "balance/*", element: <Payments /> },
      { path: "promotions", element: <Promotions /> },
      {
        path: "promotions/:category",
        element: <Promotions />,
      },
      {
        path: "promotions/detail/:category/:promotion",
        element: <StaticContent />,
      },
      {
        path: "general-info/:infoSlug",
        element: <StaticContent />,
      },
      {
        path: "messages",
        element: <Messages />,
      },
      {
        path: "prematch",
        element: <SportsBook />,
      },
      { path: "bonus/*", element: <Bonuses /> },
      {
        path: "live-casino",
        element: <LiveCasino />,
      },
      {
        path: "live-sport",
        element: <LiveSports />,
      },
      {
        path: "virtual-sports",
        element: <VirtualSports />,
      },
      {
        path: "profile/*",
        element: <Profile />,
      },
      {
        path: "history/*",
        element: <GameHistory />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);
