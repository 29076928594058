const global = {
  adjustment: "Düzeltmeler",
  all: "Hepsi",
  all_uppercase: "HEPSİ",
  american: "Amerikan",
  amount: "Miktar",
  amount_uppercase: "MİKTAR",
  approved: "Onaylandı",
  balance: "Bakiye",
  balanceManagement: "Bakiye Yönetimi",
  balanceManagement_uppercase: "BAKİYE YÖNETİMİ",
  betHistory: "Bahis Geçmişi",
  bonus: "Bonus",
  bonuses: "Bonuslar",
  bonuses_uppercase: "BONUSLAR",
  bonusName: "Ad",
  cancel: "İptal",
  cancelledbyclient: "Müşteri Tarafından İptal Edildi",
  cancelledbyoperator: "Operator İptali",
  casino: "Casino",
  casinoBalance: "Casino Bakiyesi",
  casinoBonus: "Casino Bonus",
  casinoHistory: "Casino (Bahis) Geçmişi",
  casinoBets: "Casino Bahisleri",
  changePassword: "Şifre Değiştir",
  convertedBalance: "Ana Paraya Geçen",
  couponId: "Kupon ID",
  "correction credit": "Bakiye Azaltma",
  "correction debit": "Bakiye Yükseltme",
  customRange: "Tarih Aralığı",
  date: "Tarih",
  date_uppercase: "TARİH",
  decimal: "Ondalık",
  deposit: "Yatırım",
  deposits_uppercase: "Yatırımlar",
  fractional: "Kesirli",
  games: "Oyun",
  gameHistory_uppercase: "OYUN GEÇMİŞİ",
  history: "Geçmiş",
  playTime: "Oyun Zamanı",
  gameName: "Oyun Adı",
  stakeAmount: "Bahis Tutarı",
  winAmount: "Kazanç Tutarı",
  bonusNameHistory: "Bonus Adı",
  balanceBefore: "Önceki Bakiye",
  balanceAfter: "Güncel Bakiye",
  loss: "Kayıp",
  lost: "Kayıp",
  win: "Kazanç",
  history_uppercase: "GEÇMİŞ",
  hongkong: "Hongkong",
  indo: "İndo",
  lastLogin: "Son Giriş:",
  lastTwoDays: "Son 2 Gün",
  live: "Canlı Bahis",
  liveCasino: "Canlı Casino",
  liveInPlay: "Canlı Bahis",
  logout: "Çıkış",
  mainBalance: "Ana Bakiye",
  malay: "Malay",
  matchResult: "Maç Sonucu",
  matches: "Maç",
  max: "Maks.",
  messages: "Mesajlar",
  messages_uppercase: "MESAJLAR",
  min: "Min.",
  month: "Ay",
  myProfile: "Kişisel Bilgiler",
  myProfile_uppercase: "KİŞİSEL BİLGİLER",
  nameSurname: "İsim Soyisim",
  no: "Hayir",
  noTransaction: "Herhangi bir işlem yok.",
  notResulted: "Sonuçlanmadı",
  oddsFormat: "Oran Formatı",
  openBets: "Açık Bahisler",
  operationType: "İşlem Türü",
  passwordRules:
    "Şifreniz, en az bir tane harf ve bir tane rakam olan, en az 8 en fazla 19 karakter içermelidir. Yeni şifre eski şifreyle aynı olamaz.",
  passwordRepeatRule: "Parolalar uyuşmuyor.",
  pendings: "Bekleyenler",
  personalDetails: "Kişisel detaylar",
  preMatch: "Spor Bahisleri",
  promotions: "Promosyonlar",
  returnAmount: "İade Tutarı",
  show: "Göster",
  hide: "Gizle",
  sportBonus: "Spor Bonus",
  sport: "Spor",
  sports: "Spor Bahisleri",
  state: "Durum",
  success: "Başarılı",
  error: "Hata",
  today: "Bugün",
  totalFunds: "Toplam Bakiye",
  virtualSports: "Sanal Sporlar",
  yes: "Evet",
  week: "Hafta",
  withdraw: "Çekim",
  withdrawals: "Çekimler",
  withdrawals_uppercase: "ÇEKİMLER",
  won: "Kazanç",
  onHold: "Beklemede",
  cashout: "Bahis Bozma",
  cashoutBtn: "Bahis Bozdur",
  cashoutDone: "Bahis Bozuldu",
  cashoutOut: "Bahis Bozulmadi",
  userId: "Kullanıcı ID:",
  copiedUserId: "Üye numarası kopyalandı.",
  declined: "Reddedilmiş",
  unsettled: "Sonuçlanmamış",
  returned: "İade edilmiş",
  cashedOut: "Bozulmuş Bahis",
  pick: "Seçim",
  noGameMatched: "Seçtiğiniz ligde uygun oyun bulunamadı",
  info: "Bilgi",
  cashoutNewPrice: "Yeni Fiyat",
  playForReal: "Oyna",
  playForFun: "Demo oyna",
  done: "Bitti",
  loadMore: "Daha Fazla",
  favorite: "Favori",
  favorites: "Favoriler",
  removeAll: "Tümünü Kaldır",
  events: "Karşılaşmalar",
  leagues: "Ligler",
  score: "Skor",
  h2hChart: "H2H Grafik",
  stats: "İstatistikler",
  timeline: "Zaman Çizelgesi",
  dangerousAttack: "Tehlikeli Atak",
  corner: "Korner",
  shotOnTarget: "İsabetli Şut",
  shotOffTarget: "İsabetsiz Şut",
  yellowCard: "Sarı Kart",
  redCard: "Kırmızı Kart",
  markets: "Marketler",
  chooseYourLang: "Dili Değiştir",
  gameEnded: "Karşılaşma bulunamadı",
  backToList: "Listeye Dön",
  paymentType: "Ödeme Türü",
  casinoSearchText: "Aradığınız oyunu yazınız…",
  firstHalf: "İlk Yarı",
  secondHalf: "İkinci Yarı",
  noMessage: "Şu anda hiç mesajınız yoktur.",
  footerLegal:
    "www.unibahis.com 164032 lisans numarası altında Abraham Mendez Chumaceiro Bulvarı 03, Çuraçao adresine kayıtlı United Betting B.V. grubu tarafından işletilmektedir. Bu websitesi Çuraçao eGaming tarafından lisanslandırılmıştır ve bu makam tarafından regüle edilmektedir.",
  noBetPlaced: "Bahis Yapılmadı",
  noFavoritesText:
    "Favori oyununuz veya yarışmanız bulunmuyor. Oyun veya yarışma sırasındakı yıldıza tıklayarak oyunları veya yarışmaları favorilerinize alabilirsiniz.",
  notStarted: "Başlamadı",
  firstSet: "1. Set",
  secondSet: "2. Set",
  thirdSet: "3. Set",
  fourthSet: "4. Set",
  fifthSet: "5. Set",
  casinoNoResult: "Aramanızla eşleşen sonuç bulunamadı.",
  loading: "Yükleniyor...",
  submit: "Gönder",
  resetPassword: "Şifre Sıfırla",
  newPassword: "Yeni Şifre",
  confirmNewPassword: "Yeni Şifreyi Onayla",
  invalidPassword:
    "Şifreniz, en az bir tane harf ve bir tane rakam olan, en az 8 en fazla 19 karakter içermelidir.",
  passWordNotMatch: "Parolalar uyuşmuyor",
};

export default global;
