import styled from "styled-components";

export const StyledPaymentListItem = styled.div`
  display: flex;
  border-top: 1px solid #ccc;
  padding: 32px 25px 0;
`;

export const StyledPaymentProvider = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 0;
`;

export const StyledPaymentProviderImg = styled.img`
  height: 40px;
  width: 100px;
  margin: 0 0 15px 15px;
`;

export const StyledPaymentProviderTitle = styled.span`
  font-size: 10px;
  color: #828282;
  margin: 0 0 6px 15px;
  padding: 0 0 15px;
  font-weight: 600;
  letter-spacing: 0.7px;
`;

export const StyledPaymentInfo = styled.div`
  flex: 1;
  display: flex;
  position: relative;
`;

export const StyledPaymentInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const StyledPaymentInfoTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 7px;
`;

export const StyledPaymentInfoText = styled.span`
  font-size: 14px;
  font-weight: 300;
`;

export const StyledPaymentDetailIconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 40%;

  @media (max-width: 576px) {
    right: -10px;
  }
`;
