import { FC, useReducer, useState } from "react";
import styled from "styled-components";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import { UniInput, UniModal } from "../../components/CustomComponents";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { StyledButton, StyledDivider } from "./ProfileDetails.styled";

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 10px;
`;

export enum InputTypes {
  OLD_PASSWORD = "oldPassword",
  NEW_PASSWORD = "newPassword",
  REPEAT_NEW_PASSWORD = "repeatNewPassword",
}
interface ChangeAction {
  type: InputTypes;
  payload: string;
}
interface FormState {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  oldPasswordRuleError: string;
  newPasswordRuleError: string;
  passwordMatchingError: string;
}

const formReducer = (state: FormState, action: ChangeAction) => {
  const passwordRegex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*\/\\]).{8,16}$/;

  switch (action.type) {
    case InputTypes.OLD_PASSWORD:
      const isOldPasswordValid = passwordRegex.test(action.payload);

      return {
        ...state,
        oldPassword: action.payload,
        oldPasswordRuleError: isOldPasswordValid
          ? ""
          : t("global.passwordRules"),
      };
    case InputTypes.NEW_PASSWORD:
      const isNewPasswordValid = passwordRegex.test(action.payload);
      const matching = state.repeatNewPassword === action.payload;

      return {
        ...state,
        newPassword: action.payload,
        passwordMatchingError: matching ? "" : t("global.passwordRepeatRule"),
        newPasswordRuleError: isNewPasswordValid
          ? ""
          : t("global.passwordRules"),
      };
    case InputTypes.REPEAT_NEW_PASSWORD:
      const isPasswordMatching = state.newPassword === action.payload;

      return {
        ...state,
        repeatNewPassword: action.payload,
        passwordMatchingError: isPasswordMatching
          ? ""
          : t("global.passwordRepeatRule"),
      };
    default:
      return state;
  }
};

const SuccessNotification: FC<{
  isSuccessOpen: boolean;
  onSuccessClose: () => void;
}> = ({ isSuccessOpen, onSuccessClose }) => {
  const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3aaa35;
    gap: 15px;

    span {
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    p {
      font-weight: 500;
      font-size: 1.4rem;
      color: #333;
      text-align: center;
    }
  `;

  return (
    <UniModal open={isSuccessOpen} onClose={onSuccessClose}>
      <StyledContainer>
        <CheckCircleOutlineIcon
          sx={{ width: 75, height: 75, color: "#3aaa35" }}
        />
        <span>{t("global.success")}</span>
        <p>{t("profile.successChangePasswordMessage")}</p>
      </StyledContainer>
    </UniModal>
  );
};

const ErrorNotification: FC<{
  isErrorOpen: boolean;
  onErrorClose: () => void;
}> = ({ isErrorOpen, onErrorClose }) => {
  const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(243, 46, 46);
    gap: 15px;

    span {
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    p {
      font-weight: 500;
      font-size: 1.4rem;
      color: #333;
      text-align: center;
    }
  `;

  return (
    <UniModal open={isErrorOpen} onClose={onErrorClose}>
      <StyledContainer>
        <HighlightOffIcon
          sx={{ width: 75, height: 75, color: "rgb(243, 46, 46)" }}
        />
        <span>{t("global.error")}</span>
        <p>{t("profile.errorChangePasswordMessage")}</p>
      </StyledContainer>
    </UniModal>
  );
};

const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const [formState, formDispatch] = useReducer(formReducer, {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
    oldPasswordRuleError: "",
    newPasswordRuleError: "",
    passwordMatchingError: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);

  const onChangeHandler: (type: InputTypes, value: string) => void = (
    type,
    value,
  ) => {
    formDispatch({ type, payload: value });
  };

  const onSuccessClose = () => {
    setIsSuccessOpen(false);
    navigate("/");
  };

  const onErrorClose = () => {
    setIsErrorOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const res = await uniAxiosInstance("user-bff/change-password", {
        method: "POST",
        data: {
          oldPassword: formState.oldPassword,
          newPassword: formState.newPassword,
        },
      });

      if (res.data.error) {
        setIsErrorOpen(true);
        return;
      }

      setIsSuccessOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <SuccessNotification
        isSuccessOpen={isSuccessOpen}
        onSuccessClose={onSuccessClose}
      />
      <ErrorNotification
        isErrorOpen={isErrorOpen}
        onErrorClose={onErrorClose}
      />
      <StyledDivider />
      <StyledForm onSubmit={handleSubmit}>
        <UniInput
          label={t("profile.currentPassword")}
          size="small"
          type={showOldPassword ? "text" : "password"}
          value={formState.oldPassword}
          onChange={(value) => onChangeHandler(InputTypes.OLD_PASSWORD, value)}
          helperText={formState.oldPasswordRuleError}
          InputProps={{
            name: "oldPassword",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowOldPassword((prev) => !prev)}
                  edge="end"
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <UniInput
          label={t("profile.newPassword")}
          size="small"
          type={showNewPassword ? "text" : "password"}
          value={formState.newPassword}
          onChange={(value) => onChangeHandler(InputTypes.NEW_PASSWORD, value)}
          helperText={formState.newPasswordRuleError}
          InputProps={{
            name: "newPassword",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPassword((prev) => !prev)}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <UniInput
          label={t("profile.repeatNewPassword")}
          size="small"
          type={showRepeatNewPassword ? "text" : "password"}
          value={formState.repeatNewPassword}
          onChange={(value) =>
            onChangeHandler(InputTypes.REPEAT_NEW_PASSWORD, value)
          }
          helperText={formState.passwordMatchingError}
          InputProps={{
            name: "repeatNewPassword",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowRepeatNewPassword((prev) => !prev)}
                  edge="end"
                >
                  {showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <StyledButton
          type="submit"
          size="large"
          disabled={
            !formState.oldPassword ||
            !formState.newPassword ||
            !formState.repeatNewPassword ||
            !!formState.oldPasswordRuleError ||
            !!formState.newPasswordRuleError ||
            !!formState.passwordMatchingError
          }
        >
          {t(`global.changePassword`)}
        </StyledButton>
      </StyledForm>
    </div>
  );
};

export default ChangePassword;
