import { Box, Button, Divider, MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { predefinedDateRanges } from "../../constants/dates";
import DatePicker from "react-datepicker";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import dayjs, { Dayjs } from "dayjs";
import useIsMobile from "../../utils/hooks/useIsMobile";

const StyledButton = styled(Button)`
  font-size: 1.4rem;
  border: 1px solid #bdbdbd;
  &.active {
    background: ${(props) => props.theme.palette.secondary.main};
    font-weight: 600;
  }
`;
export const StyledDatePicker = styled(DatePicker)`
  && {
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #bdbdbd !important;
    border-radius: 4px !important;
    background-color: #eaeaea;
    font-size: 14px !important;
    padding: 10px;
  }
`;

export const StyledBox = styled(Box)`
  ${(props) => props.theme.breakpoints.down("md")} {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr 1fr;
    padding: 20px 8px;
  }
`;
const StyledTableMobileContainer = styled(Box)`
  background-color: #eeeeee;
  margin-bottom: 21px !important;
  padding: 0 16px;
  margin: 0 16px;
`;
const StyledTableRowContainer = styled(Box)`
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  &:not(:last-child) {
    border-bottom: 1px solid #c4c4c4;
  }
`;

const StyledTableTitle = styled(Box)`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
`;

const StyledTableContent = styled(Box)`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  display: flex;
  justify-content: flex-end;
`;

const StyledHistoryTableRowContainer = styled(Box)`
  margin-left: 32px;
  &.adjustment {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const StyledHistoryTableTitle = styled(Box)`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

const StyledHistoryTableRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 50px;
  &.adjustment {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  &:first-child {
    border-bottom: 1px solid #e8e8e8;
  }
`;

const StyledHistoryTableContent = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  display: flex;
  align-items: center;
  padding-right: 20px;
`;

const PAYMENT_TYPES = ["WITHDRAW", "DEPOSIT", "ADJUSTMENT", "ALL"];

const PaymentHistory = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { t } = useCustomTranslation();
  const [activeDateType, setActiveDateType] = React.useState(0);
  const [startDate, setStartDate] = React.useState<Date>(
    predefinedDateRanges[0].fromDate,
  );
  const [endDate, setEndDate] = React.useState<Date>(
    predefinedDateRanges[0].toDate,
  );
  const [data, setData] = React.useState<any[]>([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    load();
  }, [activeTab]);

  const load = () => {
    const requestBody = {
      page: 0,
      size: 100,
      paymentType: PAYMENT_TYPES[activeTab],
      historicalData: true,
      from: startDate?.valueOf(),
      to: endDate.valueOf(),
    };

    uniAxiosInstance("payment-api/get", {
      method: "POST",
      data: requestBody,
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
      });
  };
  const handleDateTypeChange = (e: any) => {
    if (!e || !e.target || !e.target.value) {
      return;
    }

    if (e.target.value == -1) {
      setActiveDateType(e.target.value as number);
      return;
    }
    setActiveDateType(e.target.value as number);
    setStartDate(predefinedDateRanges[e.target.value].fromDate);
    setEndDate(predefinedDateRanges[e.target.value].toDate);
  };

  return (
    <Box>
      <Divider />
      <StyledBox gap={2} display="flex" justifyContent="center" mt={4}>
        <StyledButton
          onClick={() => setActiveTab(0)}
          variant="contained"
          color="info"
          className={activeTab === 0 ? "active" : ""}
        >
          {t("payments.withdrawals")}
        </StyledButton>
        <StyledButton
          onClick={() => setActiveTab(1)}
          variant="contained"
          color="info"
          className={activeTab === 1 ? "active" : ""}
        >
          {t("payments.deposits")}
        </StyledButton>
        <StyledButton
          onClick={() => setActiveTab(2)}
          variant="contained"
          color="info"
          className={activeTab === 2 ? "active" : ""}
        >
          {t("payments.adjustments")}
        </StyledButton>
        <StyledButton
          onClick={() => setActiveTab(3)}
          variant="contained"
          color="info"
          className={activeTab === 3 ? "active" : ""}
        >
          {t("payments.all")}
        </StyledButton>
      </StyledBox>
      <StyledBox display="flex" gap={2} pl={4} mt={4}>
        <Select
          sx={{
            m: 0,
            bgcolor: "#eaeaea",
            height: "40px",
            minWidth: "100px",
            fontSize: 14,
          }}
          onChange={handleDateTypeChange}
          value={activeDateType}
        >
          {predefinedDateRanges.map((range, index) => (
            <MenuItem value={index} key={index} sx={{ fontSize: "1.4rem" }}>
              {t(`payments.${range.name}`)}
            </MenuItem>
          ))}
          <MenuItem value={-1} sx={{ fontSize: "1.4rem" }}>
            {t("payments.customRange")}
          </MenuItem>
        </Select>
        {activeDateType == -1 && (
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(e: Date) => setStartDate(e)}
            maxDate={endDate}
          />
        )}
        {activeDateType == -1 && (
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(e: Date) => setEndDate(e)}
            minDate={startDate}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: 14 }}
          onClick={load}
        >
          {t("global.show")}
        </Button>
      </StyledBox>

      {isMobile
        ? data.map((item) => (
            <StyledTableMobileContainer>
              <StyledTableRowContainer>
                <StyledTableTitle>
                  {t(`payments.date_uppercase`)}
                </StyledTableTitle>
                <StyledTableContent>{item.date}</StyledTableContent>
              </StyledTableRowContainer>
              <StyledTableRowContainer>
                <StyledTableTitle>
                  {t(`payments.amount_uppercase`)}
                </StyledTableTitle>
                <StyledTableContent>{item.amount} ₺</StyledTableContent>
              </StyledTableRowContainer>
              <StyledTableRowContainer>
                <StyledTableTitle>{t(`payments.state`)}</StyledTableTitle>
                <StyledTableContent>
                  {t(`payments.${item.state.toLowerCase()}`) || item.state}
                </StyledTableContent>
              </StyledTableRowContainer>
              <StyledTableRowContainer>
                <StyledTableTitle>
                  {t(`payments.operationType`)}
                </StyledTableTitle>
                <StyledTableContent>
                  {t(`payments.${item.operationType.toLowerCase()}`) ||
                    item.operationType}
                </StyledTableContent>
              </StyledTableRowContainer>
              <StyledTableRowContainer>
                <StyledTableTitle>
                  {activeTab === 2
                    ? t(`payments.${item.info}`)
                    : t(`payments.paymentType`)}
                </StyledTableTitle>
                <StyledTableContent>
                  {activeTab === 2 ? item.info : item.paymentType}
                </StyledTableContent>
              </StyledTableRowContainer>
            </StyledTableMobileContainer>
          ))
        : data.length > 0 && (
            <>
              <StyledHistoryTableRowContainer>
                <StyledHistoryTableRow
                  className={`${activeTab === 2 && "adjustment"}`}
                >
                  <StyledHistoryTableTitle>
                    {t(`payments.date_uppercase`)}
                  </StyledHistoryTableTitle>
                  <StyledHistoryTableTitle>
                    {t(`payments.amount_uppercase`)}
                  </StyledHistoryTableTitle>
                  <StyledHistoryTableTitle>
                    {t(`payments.state`)}
                  </StyledHistoryTableTitle>
                  <StyledHistoryTableTitle>
                    {t(`payments.operationType`)}
                  </StyledHistoryTableTitle>
                  {activeTab === 2 ? (
                    <StyledHistoryTableTitle>
                      {t(`payments.info`)}
                    </StyledHistoryTableTitle>
                  ) : (
                    <StyledHistoryTableTitle>
                      {t(`payments.paymentType`)}
                    </StyledHistoryTableTitle>
                  )}
                </StyledHistoryTableRow>

                {data.map((item) => (
                  <StyledHistoryTableRow
                    className={`${activeTab === 2 && "adjustment"}`}
                  >
                    <StyledHistoryTableContent>
                      {item.date}
                    </StyledHistoryTableContent>
                    <StyledHistoryTableContent>
                      {item.amount} ₺
                    </StyledHistoryTableContent>
                    <StyledHistoryTableContent>
                      {t(`payments.${item.state.toLowerCase()}`) || item.state}
                    </StyledHistoryTableContent>
                    <StyledHistoryTableContent>
                      {t(`payments.${item.operationType.toLowerCase()}`) ||
                        item.operationType}
                    </StyledHistoryTableContent>
                    {activeTab === 2 ? (
                      <StyledHistoryTableContent>
                        {item.info}
                      </StyledHistoryTableContent>
                    ) : (
                      <StyledHistoryTableContent>
                        {item.paymentType}
                      </StyledHistoryTableContent>
                    )}
                  </StyledHistoryTableRow>
                ))}
              </StyledHistoryTableRowContainer>
            </>
          )}
    </Box>
  );
};

export default PaymentHistory;
