import React, { useEffect, useState } from "react";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { UniInput } from "../../components/CustomComponents";
import { Button } from "@mui/material";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import {
  StyledProfileDetailsWrapper,
  StyledInfo,
  StyledDivider,
  StyledPhoneUpdate,
} from "./ProfileDetails.styled";

const InfoCard = ({ title, value }) => {
  return (
    <h4 style={{ fontSize: "1.4rem", fontWeight: "300" }}>
      {title}: <span style={{ fontWeight: "500" }}>{value}</span>
    </h4>
  );
};

const ProfileDetails = () => {
  const [details, setDetails] = useState();
  const { t } = useCustomTranslation();
  useEffect(() => {
    try {
      uniAxiosInstance("user-service/authentication/details", {
        method: "GET",
      }).then((res) => {
        setDetails(res.data);
        console.log(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <StyledProfileDetailsWrapper>
      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.name`)} value={details?.name} />
      </StyledInfo>
      <StyledDivider />
      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.surname`)} value={details?.surName} />
      </StyledInfo>
      <StyledDivider />
      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.birthDate`)} value={details?.birthDate} />
      </StyledInfo>
      <StyledDivider />
      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.address`)} value={details?.address} />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.country`)} value={details?.country} />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.city`)} value={details?.city} />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.userName`)} value={details?.userName} />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard title={t(`profile.email`)} value={details?.email} />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo className="p-i-m-field-wrapper-w-auto">
        <InfoCard
          title={t(`profile.phoneNumber`)}
          value={details?.phoneCode + details?.phoneNumber}
        />
      </StyledInfo>
      <StyledDivider />

      <StyledInfo>
        <StyledPhoneUpdate>
          <UniInput
            label={t(`profile.updatePhone`)}
            //   onChange={handleOnChange}
            disabled={details?.isNewPhoneDisabled}
            value={details?.newPhoneNumber}
            errorMessage={t(`register.errorMessages.phoneNumberWithTurkey`)}
            inputName="newPhoneNumber"
            type="tel"
          />
          <Button
            variant="contained"
            size="large"
            sx={{ height: "4rem", marginTop: "3.3rem", fontSize: "1.4rem" }} //   onClick={handleOnClick}
            disabled={details?.isNewPhoneDisabled}
          >
            {t(`profile.updatePhoneBtn`)}
          </Button>
        </StyledPhoneUpdate>
        <StyledPhoneUpdate>
          <UniInput
            label="SMS"
            placeholder={t(`profile.enterCode`)}
            //   onChange={handleCodeChange}
            disabled={!details?.isNewPhoneDisabled}
            value={details?.sms}
            errorMessage={"SMS kodu 6 haneli olmalıdır."}
            inputName="sms"
          />
          <Button
            variant="contained"
            size="large"
            sx={{ height: "4rem", marginTop: "3.3rem", fontSize: "1.4rem" }}
            //   onClick={handleCodeClick}
          >
            {t(`profile.approve`)}
          </Button>
        </StyledPhoneUpdate>
      </StyledInfo>
      <StyledInfo className="p-i-m-field-wrapper-w-auto"></StyledInfo>
    </StyledProfileDetailsWrapper>
  );
};

export default ProfileDetails;
