import { Typography } from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";
import { StyledLinkItem } from "./LinkItem.styled";

interface LinkItemProps {
  id: number;
  category_id: number;
  title: string;
  position_order: number;
  url: string;
  content_type: number;
  device: number;
  is_url_target: boolean;
}

export const LinkItem: React.FC<LinkItemProps> = ({
  id,
  category_id,
  title,
  position_order,
  url,
  content_type,
  device,
  is_url_target,
}) => {
  if (content_type === 0) {
    return (
      <StyledLinkItem
        to={`/general-info/` + url}
        target={is_url_target ? `_blank` : `_self`}
      >
        <Typography sx={{ color: uniTokens.textSecondary, fontSize: "1.4rem" }}>
          {title}
        </Typography>
      </StyledLinkItem>
    );
  } else if (content_type === 1) {
    return (
      <StyledLinkItem
        to={`/` + url}
        target={is_url_target ? `_blank` : `_self`}
      >
        <Typography sx={{ color: uniTokens.textSecondary, fontSize: "1.4rem" }}>
          {title}
        </Typography>
      </StyledLinkItem>
    );
  } else {
    return (
      <StyledLinkItem
        to={`/` + url}
        target={is_url_target ? `_blank` : `_self`}
      >
        <Typography sx={{ color: uniTokens.textSecondary, fontSize: "1.4rem" }}>
          {title}
        </Typography>
      </StyledLinkItem>
    );
  }
};
