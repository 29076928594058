import { Box, Typography } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import RightMenuNavigation from "../../components/RightMenuNavigation/RightMenuNavigation";
import { uniTokens } from "../../theme/uniTokens";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import ChangePassword from "./ChangePassword";
import ProfileDetails from "./ProfileDetails";
import { TwoFaForm } from "./TwoFaForm";

const data = [
  { title: "myProfile", to: "/profile/details" },
  { title: "changePassword", to: "/profile/change-password" },
  { title: "twoFa", to: "/profile/2fa" },
];

const Profile = () => {
  const { t } = useCustomTranslation();
  const translatedData = data.map((item) => ({
    ...item,
    title: t(`profile.${item.title}`),
  }));

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: uniTokens.white,
        padding: "15px 0 0 0",
        margin: "24px 5px",
        minHeight: "80vh",
      })}
    >
      <Typography
        textAlign="center"
        sx={{ fontSize: 14, fontWeight: 900, mb: 1 }}
        variant="h1"
      >
        {t("profile.myProfile")}
      </Typography>
      <RightMenuNavigation data={translatedData} />
      <Routes>
        <Route path="/" element={<ProfileDetails />} />
        <Route path="details" element={<ProfileDetails />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="2fa" element={<TwoFaForm />} />
      </Routes>
    </Box>
  );
};

export default Profile;
