import { FC, ReactNode } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Outlet } from "react-router-dom";
import { theme } from "../theme/Global.styled";
import Layout from "../components/Layout/Layout";

const Root: FC<{ children?: ReactNode }> = ({ children }) => {
  // TODO: Make a Layout context for managing the layout specific props
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout showHeaderAndFooter>
        <Outlet />
        {children}
      </Layout>
    </ThemeProvider>
  );
};

export default Root;
