import { FC } from "react";
import { useState, useEffect } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import {
  StyledFooterColumn,
  StyledFooterContainer,
  StyledSocialMediaContainer,
  StyledSocialMediaIcon,
  StyledProviderContainer,
  StyledFooterSiteMap,
  StyledImg,
  StyledSiteMapHeader,
  StyledMidFooter,
  StyledResponsibleGaming,
  StyledResponsibleGamingItem,
  StyledResponsibleGamingLink,
  StyledDivider,
  StyledFooterLicense,
  StyledFooterLicenseText,
  StyledFooterPayment,
  StyledProviderHeader,
  StyledAddictivePart,
  StyledListItemText,
} from "./Footer.styled";
import trFlag from "../../assets/images/headerIcons/flagTR.svg";
import ukFlag from "../../assets/images/headerIcons/flagUK.svg";
import { LinkItem } from "./LinkItem";
import LanguageIcon from "@mui/icons-material/Language";
import { uniTokens } from "../../theme/uniTokens";
import useIsMobile from "../../utils/hooks/useIsMobile";
import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import plusEighteen from "../../assets/images/plus18.svg";
import oneTwoGaming from "../../assets/images/ourPartners/oneTwoGaming.svg";
import amusnetInteractive from "../../assets/images/ourPartners/amusnetInteractive.svg";
import betGamesTv from "../../assets/images/ourPartners/betGamesTv.svg";
import cg from "../../assets/images/ourPartners/cg.svg";
import ecogra from "../../assets/images/ourPartners/ecogra.svg";
import evolutionGaming from "../../assets/images/ourPartners/evolutionGaming.svg";
import ezugi from "../../assets/images/ourPartners/ezugi.svg";
import g4 from "../../assets/images/ourPartners/g4.svg";
import git from "../../assets/images/ourPartners/git.svg";
import goldenRace from "../../assets/images/ourPartners/goldenRace.svg";
import internationalBetting from "../../assets/images/ourPartners/internationalBetting.svg";
import luckyStreak from "../../assets/images/ourPartners/luckyStreak.svg";
import netEnt from "../../assets/images/ourPartners/netEnt.svg";
import playson from "../../assets/images/ourPartners/playson.svg";
import pragmaticPlay from "../../assets/images/ourPartners/pragmaticPlay.svg";
import protectIntegrity from "../../assets/images/ourPartners/protectIntegrity.svg";
import reducingCO from "../../assets/images/ourPartners/reducingCO.svg";
import vtvbet from "../../assets/images/ourPartners/vtvbet.svg";
import instagramIcon from "../../assets/images/socialMediaIcons/instagramIcon.svg";
import facebookIcon from "../../assets/images/socialMediaIcons/facebookIcon.svg";
import twitterIcon from "../../assets/images/socialMediaIcons/twitterIcon.svg";
import licenceLogo from "../../assets/licenceLogo.svg";
import paparaIcon from "../../assets/securePaymentMethods/paparaIcon.svg";
import jetonIcon from "../../assets/securePaymentMethods/jetonIcon.svg";
import payfixIcon from "../../assets/securePaymentMethods/payfixIcon.svg";
import coinpaymentsIcon from "../../assets/securePaymentMethods/coinpaymentsIcon.svg";
import mefeteIcon from "../../assets/securePaymentMethods/mefeteIcon.svg";
import envoysoftIcon from "../../assets/securePaymentMethods/envoysoftIcon.svg";
import bankahavalesiIcon from "../../assets/securePaymentMethods/bankahavalesiIcon.svg";
import parazulaIcon from "../../assets/securePaymentMethods/parazulaIcon.svg";
import cepbankIcon from "../../assets/securePaymentMethods/cepbankIcon.svg";
import paygigaIcon from "../../assets/securePaymentMethods/paygigaIcon.svg";
import tetherIcon from "../../assets/securePaymentMethods/tetherIcon.svg";
import FooterMobile from "./FooterMobile";
import { StyledLanguageChange } from "./Footer.styled";
import {
  Diversity1Rounded,
  ExpandLess,
  ExpandMore,
  StarBorder,
} from "@mui/icons-material";

const Footer: FC = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, changeLanguage } = useCustomTranslation();
  const socialMediaIcons = [
    {
      id: 1,
      name: "instagram",
      icon: instagramIcon,
      url: "https://www.instagram.com/",
    },
    {
      id: 2,
      name: "facebook",
      icon: facebookIcon,
      url: "https://www.facebook.com/",
    },
    {
      id: 3,
      name: "twitter",
      icon: twitterIcon,
      url: "https://twitter.com/",
    },
  ];
  const providersLogos = [
    oneTwoGaming,
    amusnetInteractive,
    betGamesTv,
    cg,
    ecogra,
    evolutionGaming,
    ezugi,
    g4,
    git,
    goldenRace,
    internationalBetting,
    luckyStreak,
    netEnt,
    playson,
    pragmaticPlay,
    protectIntegrity,
    reducingCO,
    vtvbet,
  ];
  const responsibleGaming = [
    {
      id: 1,
      name: t(`footer.responsibleGambling`),
      url: "/general-info/bilinci-oyun",
      urlEn: "/general-info/responsible-gambling",
    },
    {
      id: 2,
      name: t(`footer.termsAndConditions`),
      url: "/general-info/hukumler-kosullar",
      urlEn: "/general-info/terms-and-conditions",
    },
    {
      id: 3,
      name: t(`footer.privacyPolicy`),
      url: "/general-info/gizlilik-politikasi",
      urlEn: "/general-info/privacy-policy",
    },
    {
      id: 4,
      name: t(`footer.securityInformation`),
      url: "/general-info/guvenlik-bilgileri",
      urlEn: "/general-info/security-information",
    },
    {
      id: 5,
      name: t(`footer.cookies`),
      url: "/general-info/cerez-politikasi",
      urlEn: "/general-info/cookies",
    },
  ];
  const paymentMethodsIcons = [
    paparaIcon,
    jetonIcon,
    payfixIcon,
    coinpaymentsIcon,
    mefeteIcon,
    envoysoftIcon,
    bankahavalesiIcon,
    parazulaIcon,
    cepbankIcon,
    paygigaIcon,
    tetherIcon,
  ];

  const isMobile = useIsMobile();

  useEffect(() => {
    const apiUrl = "https://cms-test.basedashboard.com/api/footer";
    const requestBody = {
      lang: localStorage.getItem("language") || "tr",
      device: isMobile ? "mobile" : "desktop",
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    // TODO: use axios instance instead of fetch and location
    fetch(apiUrl, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setData(data.footer);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      });
  }, [isMobile, t]);
  useEffect(() => {
    changeLanguage(localStorage.getItem("language") || "tr");
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const renderedData = data.map((item: any) => {
    return (
      <StyledFooterColumn key={item.id}>
        <Typography sx={{ color: uniTokens.white }}>
          <StyledSiteMapHeader>{item.title}</StyledSiteMapHeader>
        </Typography>
        {item.contents.map((content: any) => (
          <LinkItem
            id={content.id}
            category_id={content.category_id}
            title={content.title}
            position_order={content.position_order}
            url={content.url}
            content_type={content.content_type}
            device={content.device}
            is_url_target={content.is_url_target}
          ></LinkItem>
        ))}
      </StyledFooterColumn>
    );
  });

  return (
    <StyledFooterContainer>
      {isMobile ? (
        <StyledLanguageChange
          sx={{
            backgroundColor: `${uniTokens.background}`,
            width: "100%",
          }}
        >
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <LanguageIcon sx={{ color: uniTokens.white }} fontSize="large" />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "1.6rem" }}
              primary={t(`footer.language`)}
            ></ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => changeLanguage("tr")}
                className="language-button"
              >
                <ListItemIcon>
                  <img src={trFlag} style={{ width: "2rem" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "1.6rem" }}
                  primary="Türkçe"
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => changeLanguage("en")}
                className="language-button"
              >
                <ListItemIcon>
                  <img src={ukFlag} style={{ width: "2rem" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "1.6rem" }}
                  primary="English"
                />
              </ListItemButton>
            </List>
          </Collapse>
        </StyledLanguageChange>
      ) : null}
      <StyledSocialMediaContainer>
        {socialMediaIcons.map((icon) => {
          return (
            <StyledSocialMediaIcon
              key={icon.id}
              href={icon.url}
              target="_blank"
            >
              <img src={icon.icon} alt={icon.name} />
            </StyledSocialMediaIcon>
          );
        })}
      </StyledSocialMediaContainer>
      <StyledDivider />
      {isMobile ? (
        <FooterMobile />
      ) : (
        <StyledFooterSiteMap>{renderedData}</StyledFooterSiteMap>
      )}
      <StyledDivider />
      <StyledMidFooter>
        <StyledAddictivePart>
          <StyledImg
            src={plusEighteen}
            style={{ marginRight: "10px", marginTop: "15px" }}
          />
          <Typography
            sx={{
              color: uniTokens.textSecondary,
              marginTop: "2.8rem",
              whiteSpace: "normal",
              lineHeight: 1.2,
              fontSize: "1.4rem",
              alignItems: "center",
            }}
          >
            {t(`footer.addictiveText`)}
          </Typography>
        </StyledAddictivePart>
        <StyledResponsibleGaming>
          {responsibleGaming.map((item, index) => {
            return (
              <StyledResponsibleGamingItem key={item.id}>
                <StyledResponsibleGamingLink
                  href={`${localStorage.getItem("language") === "tr" ? item.url : item.urlEn}`}
                >
                  {item.name}
                </StyledResponsibleGamingLink>

                {index !== responsibleGaming.length - 1 ? "⎮" : null}
              </StyledResponsibleGamingItem>
            );
          })}
        </StyledResponsibleGaming>
      </StyledMidFooter>
      <StyledDivider />
      <StyledProviderContainer>
        <StyledProviderHeader>{t(`footer.providers`)}</StyledProviderHeader>
        {providersLogos.map((logo) => {
          return <StyledImg key={logo} src={logo} alt="provider logo" />;
        })}
      </StyledProviderContainer>
      <StyledFooterPayment>
        <StyledProviderHeader>
          {t(`footer.paymentMethods`)}
        </StyledProviderHeader>
        {paymentMethodsIcons.map((icon) => {
          return <StyledImg key={icon} src={icon} alt="payment method logo" />;
        })}
      </StyledFooterPayment>
      <StyledDivider />
      <StyledFooterLicense>
        <StyledImg src={licenceLogo} style={{ marginBottom: "1rem" }} />
        <StyledFooterLicenseText>
          {t(`footer.licenceText`)}
        </StyledFooterLicenseText>
      </StyledFooterLicense>
    </StyledFooterContainer>
  );
};
export default Footer;
