import { useState } from "react";

function useBooleanStates(
  initialStates: { [s: string]: unknown } | ArrayLike<unknown>,
) {
  const stateEntries = Object.entries(initialStates).map(
    ([key, initialValue]) => {
      const [value, setter] = useState(initialValue);
      return [key, value, setter];
    },
  );

  const states = Object.fromEntries(
    stateEntries.map(([key, value]) => [key, value]),
  );
  const setters = Object.fromEntries(
    stateEntries.map(([key, _, setter]) => [
      `set${(key as string).charAt(0).toUpperCase() + (key as string).slice(1)}`,
      setter,
    ]),
  );

  return { ...states, ...setters };
}

export { useBooleanStates };
