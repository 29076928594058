const payments = {
  amount: "Tutar",
  accountNumber: "Hesap Numarası",
  bankType: "Banka Türü",
  customerNote: "Müşteri Notu",
  payments: "Ödemeler",
  iban: "IBAN",
  max: "Maks.",
  min: "Min.",
  nameSurname: "İsim Soyisim",
  selectNetwork: "Lütfen Ağ Seçin",
  yourRequestHasBeenReceived: "Talebiniz Alınmıştır",
  withdrawMoney: "Para Çekme",
  withdrawAmount: "Para Çekme Miktarı",
  paparaNumber: "Papara Numarası*",
  withdrawableAmount: "Çekilebilir Tutar",
  changePaymentMethod: "Ödeme Yöntemini Değiştir",
  changeWithdrawMethod: "Çekim Yöntemini Değiştir",
  walletNumber: "Cüzdan Numarası*",
  bonusTitle: "Bonus Türünü Seçiniz",
  bonusDescription: "Lütfen yatırım yaptıysanız bonus almak için seçim yapınız",
  deposit: "Yatırma",
  moneyDeposit: "Para Yatırma",
  selectBonusType: "Bonus Türünü Seçiniz",
  total: "Toplam",
  noRollover: "ÇEVRİMSİZ BONUS",
  rollever: "ÇEVRİMLİ BONUS",
  discountBonus: "KAYIP BONUS",
  firstDepositBonus: "İLK YATIRIM BONUSU",
  sporBonus: "SPOR",
  casinoBonus: "CASINO",
  pragmaticBonus: "PRAGMATIC (CASINO)",
  trialBonus: "DENEME BONUSU",
  giftCodeBonus: "HEDİYE KODU",
  approve: "Onaylıyorum",
  cancel: "İptal",
  withdraw: "Çekim",
  history: "Geçmiş",
  pendings: "Bekleyenler",
  lastTwoDays: "Son 2 Gün",
  today: "Bugün",
  week: "Hafta",
  month: "Ay",
  customRange: "Tarih Aralığı",
  date_uppercase: "TARİH",
  amount_uppercase: "MİKTAR",
  balance_uppercase: "BAKİYE",
  state: "DURUM",
  operationType: "İşlem Türü",
  info: "Bilgi",
  paymentType: "Ödeme Türü",
  approved: "Onaylandı",
  success: "Başarılı",
  "correction credit": "Bakiye Azaltma",
  "correction debit": "Bakiye Yükseltme",
  cancelDepositPopupTitleMessage:
    "Para yatırma talebiniz iptal edilecektir, kabul ediyor musunuz?",
  cancelDepositPopupSmallMessage: "",
  cancelDepositPopupSuccessMessage:
    "Para yatırma iptal işleminiz başarıyla tamamlandı.",
  cancelDepositPopupErrorMessage:
    "Para yatırma talebinizin iptali ile ilgili bir sorun oluştu, lütfen daha sonra tekrar deneyiniz.",

  cancelWithdrawPopupTitleMessage:
    "Para çekme talebiniz iptal edilecektir, kabul ediyor musunuz?",
  cancelWithdrawPopupSmallMessage: "",
  cancelWithdrawPopupSuccessMessage:
    "Para çekme iptal işleminiz başarıyla tamamlandı.",
  cancelWithdrawPopupErrorMessage:
    "Para çekme talebinizin iptali ile ilgili bir sorun oluştu, lütfen daha sonra tekrar deneyiniz.",
  noTransaction: "Herhangi bir işlem yok.",
  withdrawals: "Çekimler",
  deposits: "Yatırımlar",
  adjustments: "Düzeltmeler",
  all: "Hepsi",

  cancelledbyclient: "Müşteri Tarafından İptal Edildi",
} as const;

export default payments;
