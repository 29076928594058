import { FC, useReducer, useState } from "react";
import {
  StyledMainContainer,
  StyledRegistrationContainer,
  StyledButtonContainer,
} from "./Registration.styled";
import RegistrationFirstPage, {
  RegistrationFirstPageState,
} from "./RegistrationFirstPage";
import RegistrationSecondPage, {
  RegistrationSecondPageState,
} from "./RegistrationSecondPage";
import RegistrationThirdPage, {
  RegistrationThirdPageState,
} from "./RegistrationThirdPage";
import { UniModal } from "../../components/CustomComponents";
import Login from "../../components/Login/Login";
import { Stepper, Step, StepLabel, Typography, Button } from "@mui/material";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { register } from "../../redux/slices/authSlice";

export enum InputTypes {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
  GENDER = "gender",
  ADDRESS = "address",
  CITY = "city",
  COUNTRY = "country",
  MOBILE_NUMBER_PREFIX = "mobileNumberPrefix",
  MOBILE_NUMBER = "mobileNumber",
  CURRENCY = "currency",
  USER_NAME = "username",
  PASSWORD = "password",
  PASSWORD_AGAIN = "passwordAgain",
  IDENTITY_NUMBER = "identityNumber",
}
interface ChangeAction {
  type: InputTypes;
  payload: string;
}

const initialFormState = {
  firstName: "",
  firstNameError: "",
  lastName: "",
  lastNameError: "",
  email: "",
  emailError: "",
  day: "",
  month: "",
  year: "",
  dateOfBirthError: "",
  gender: "M",
  address: "",
  addressError: "",
  city: "",
  cityError: "",
  country: "TR",
  countryError: "",
  mobileNumberPrefix: "+90",
  mobileNumberPrefixError: "",
  mobileNumber: "",
  mobileNumberError: "",
  currency: "TRY",
  currencyError: "",
  username: "",
  usernameError: "",
  password: "",
  passwordError: "",
  passwordAgain: "",
  passwordAgainError: "",
  identityNumber: "",
  identityNumberError: "",
  birthDate: "",
};

const Registration: FC = () => {
  const { t } = useCustomTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const formReducer = (
    state: RegistrationFirstPageState &
      RegistrationSecondPageState &
      RegistrationThirdPageState,
    action: ChangeAction,
  ) => {
    const firstNameRegex = /^[a-zA-ZğüşöçıIİĞÜŞÖÇ ]+$/g;
    const lastNameRegex = /^[a-zA-ZğüşöçıIİĞÜŞÖÇ ]+$/g;
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const addressRegex = /^[a-zA-ZğüşöçıİIĞÜŞÖÇ.,-_ ]+$/g;
    const cityRegex = /^[a-zA-ZğüşöçıİIĞÜŞÖÇ ]+$/g;
    const usernameRegex = /^[a-zA-ZğüşöçıİIĞÜŞÖÇ.,-_]+$/g;
    const passwordRegex = /^(?!.*\s)(?=.*\d)(?=.*[a-zA-Z]).{8,19}$/;
    const identityNumberRegex = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
    switch (action.type) {
      case InputTypes.FIRST_NAME:
        const isFirstNameValid =
          firstNameRegex.test(action.payload) &&
          action.payload.length > 2 &&
          action.payload.length < 50;
        return {
          ...state,
          firstName: action.payload,
          firstNameError: isFirstNameValid
            ? ""
            : t(`registration.errorMessages.firstName`),
        };
      case InputTypes.LAST_NAME:
        const isLastNameValid =
          lastNameRegex.test(action.payload) &&
          action.payload.length > 2 &&
          action.payload.length < 50;
        return {
          ...state,
          lastName: action.payload,
          lastNameError: isLastNameValid
            ? ""
            : t(`registration.errorMessages.lastName`),
        };
      case InputTypes.EMAIL:
        const isEmailValid = emailRegex.test(action.payload);
        return {
          ...state,
          email: action.payload,
          emailError: isEmailValid ? "" : t(`registration.errorMessages.email`),
        };
      case InputTypes.DAY:
        return {
          ...state,
          day: action.payload,
        };
      case InputTypes.MONTH:
        return {
          ...state,
          month: action.payload,
        };
      case InputTypes.YEAR:
        return {
          ...state,
          year: action.payload,
        };
      case InputTypes.GENDER:
        return {
          ...state,
          gender: action.payload,
        };
      case InputTypes.ADDRESS:
        const isAddressValid =
          addressRegex.test(action.payload) &&
          action.payload.length > 2 &&
          action.payload.length < 250;
        return {
          ...state,
          address: action.payload,
          addressError: isAddressValid
            ? ""
            : t(`registration.errorMessages.address`),
        };
      case InputTypes.CITY:
        const isCityValid =
          cityRegex.test(action.payload) && action.payload.length > 2;
        return {
          ...state,
          city: action.payload,
          cityError: isCityValid ? "" : t(`registration.errorMessages.city`),
        };
      case InputTypes.COUNTRY:
        return {
          ...state,
          country: action.payload,
        };
      case InputTypes.MOBILE_NUMBER_PREFIX:
        const isMobileNumberPrefixValid = action.payload.length > 1;
        return {
          ...state,
          mobileNumberPrefix: action.payload,
          mobileNumberPrefixError: isMobileNumberPrefixValid
            ? ""
            : "Mobile number prefix is not valid",
        };
      case InputTypes.MOBILE_NUMBER:
        const isMobileNumberValid =
          action.payload.length > 6 && action.payload.length < 16;
        return {
          ...state,
          mobileNumber: action.payload,
          mobileNumberError: isMobileNumberValid
            ? ""
            : t(`registration.errorMessages.phoneNumber`),
        };
      case InputTypes.CURRENCY:
        return {
          ...state,
          currency: action.payload,
        };
      case InputTypes.USER_NAME:
        const isUsernameValid =
          usernameRegex.test(action.payload) &&
          action.payload.length > 3 &&
          action.payload.length < 16;
        return {
          ...state,
          username: action.payload,
          usernameError: isUsernameValid
            ? ""
            : t(`registration.errorMessages.username`),
        };
      case InputTypes.PASSWORD:
        const isPasswordValid =
          passwordRegex.test(action.payload) &&
          action.payload.length > 7 &&
          action.payload.length < 20;
        return {
          ...state,
          password: action.payload,
          passwordError: isPasswordValid
            ? ""
            : t(`registration.errorMessages.password`),
        };
      case InputTypes.PASSWORD_AGAIN:
        const isPasswordMatch = action.payload === state.password;
        return {
          ...state,
          passwordAgain: action.payload,
          passwordAgainError: isPasswordMatch
            ? ""
            : t(`registration.errorMessages.rePassword`),
        };
      case InputTypes.IDENTITY_NUMBER:
        const isIdentityNumberValid =
          identityNumberRegex.test(action.payload) &&
          action.payload.length === 11;
        return {
          ...state,
          identityNumber: action.payload,
          identityNumberError: isIdentityNumberValid
            ? ""
            : t(`registration.errorMessages.identityNumber`),
        };

      default:
        return state;
    }
  };
  const { error } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [openLoginModal, setLoginModalOpen] = useState(false);
  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const isValidFirstPage = () => {
    return (
      formState.firstName !== "" &&
      formState.firstNameError == "" &&
      formState.lastName !== "" &&
      formState.lastNameError == "" &&
      formState.email !== "" &&
      formState.emailError == "" &&
      formState.day !== "" &&
      formState.month !== "" &&
      formState.year !== "" &&
      formState.gender !== ""
    );
  };

  const isValidSecondPage = () => {
    return (
      formState.address !== "" &&
      formState.city !== "" &&
      formState.cityError == "" &&
      formState.country !== "" &&
      formState.mobileNumberPrefix !== "" &&
      formState.mobileNumber !== "" &&
      formState.currency !== ""
    );
  };

  const isValidThirdPage = () => {
    return (
      formState.username !== "" &&
      formState.usernameError == "" &&
      formState.password !== "" &&
      formState.passwordError == "" &&
      formState.passwordAgain !== "" &&
      formState.passwordAgain === formState.password &&
      formState.identityNumber !== ""
    );
  };

  const handleFormChange = (type: InputTypes, value: string) => {
    formDispatch({ type, payload: value });
  };

  const handleRegisterClick = () => {
    const { day, month, year } = formState;

    if (day && month && year) {
      const dayNumber = parseInt(day, 10);
      const monthNumber = parseInt(month, 10);
      const yearNumber = parseInt(year, 10);

      if (!isNaN(dayNumber) && !isNaN(monthNumber) && !isNaN(yearNumber)) {
        const birthDate = new Date(
          yearNumber,
          monthNumber - 1,
          dayNumber,
          0,
          0,
          0,
          0,
        );
        const formattedBirthDate = birthDate.toISOString();

        dispatch(
          register({
            firstName: formState.firstName,
            lastName: formState.lastName,
            email: formState.email,
            gender: formState.gender,
            address: formState.address,
            city: formState.city,
            country: formState.country,
            phone_code: formState.mobileNumberPrefix,
            phone: formState.mobileNumber,
            currency_name: formState.currency,
            username: formState.username,
            password: formState.password,
            citizenshipNumber: formState.identityNumber,
            agree: true,
            site_id: "18756337",
            birthDate: formattedBirthDate,
            language: "tr",
          }),
        );
      }
    }
  };

  const stepBack = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const stepForward = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  let activePage;

  switch (activeStep) {
    case 0:
      activePage = (
        <RegistrationFirstPage
          formChange={handleFormChange}
          formState={formState as RegistrationFirstPageState}
        />
      );
      break;
    case 1:
      activePage = (
        <RegistrationSecondPage
          formChange={handleFormChange}
          formState={formState as RegistrationSecondPageState}
        />
      );
      break;
    case 2:
      activePage = (
        <RegistrationThirdPage
          formChange={handleFormChange}
          formState={formState as RegistrationThirdPageState}
          checked0={false}
          checked1={false}
        />
      );
      break;
    default:
      activePage = null;
  }

  return (
    <StyledMainContainer>
      <StyledRegistrationContainer>
        <Typography
          variant="h4"
          sx={{
            marginLeft: "4rem",
            marginBottom: "2rem",
            paddingTop: "3rem",
            fontSize: "3.2rem",
          }}
        >
          {t(`registration.registerWithUs`)}
        </Typography>
        <Stepper
          activeStep={activeStep}
          sx={{
            marginLeft: "4rem",
            marginRight: "4rem",
            "& .MuiStepIcon-root": {
              width: 42,
              height: 42,
            },
          }}
        >
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
        {activePage}
        <StyledButtonContainer
          sx={{
            justifyContent: activeStep === 0 ? "flex-end" : "space-between",
          }}
        >
          {(activeStep === 1 || activeStep === 2) && (
            <Button
              sx={{ backgroundColor: "#E6D01B", color: "black", fontSize: 14 }}
              variant="contained"
              onClick={() => {
                stepBack();
              }}
            >
              {t(`registration.back`)}
            </Button>
          )}
          {activeStep === 2 ? (
            <Button
              variant="contained"
              disabled={!isValidThirdPage()}
              onClick={() => {
                handleRegisterClick();
              }}
              sx={{ fontSize: 14 }}
            >
              {t(`registration.join`)}
            </Button>
          ) : (
            <Button
              size="large"
              sx={{
                fontSize: "1.4rem",
              }}
              variant="contained"
              disabled={
                activeStep === 2 ||
                (activeStep === 0 && !isValidFirstPage()) ||
                (activeStep === 1 && !isValidSecondPage()) ||
                (activeStep === 2 && !isValidThirdPage())
              }
              onClick={() => {
                stepForward();
              }}
            >
              {t(`registration.continue`)}
            </Button>
          )}
        </StyledButtonContainer>
        <Typography
          sx={{ marginLeft: "3rem", paddingBottom: "1rem", fontSize: "1.6rem" }}
        >
          {t(`registration.alreadyHaveAccount`)}
          <Button
            size="large"
            onClick={() => setLoginModalOpen(true)}
            sx={{ paddingBottom: "1rem", fontSize: "1.6rem" }}
          >
            {t(`registration.login`)}
          </Button>
        </Typography>
      </StyledRegistrationContainer>
      {openLoginModal && (
        <UniModal
          open={openLoginModal}
          onClose={() => setLoginModalOpen(false)}
        >
          <Login />
        </UniModal>
      )}
    </StyledMainContainer>
  );
};
export default Registration;
