import styled from "styled-components";
import { Button, ButtonGroup, FormGroup } from "@mui/material";

export const StyledDetailContainer = styled.div`
  display: flex;
  padding: 0 2rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`;

export const StyledDetailItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding-left: 24px;

  ${(props) => props.theme.breakpoints.down("md")} {
    border-right: none;
    padding-left: 0;
  }
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  color: #147b45;
  text-decoration: underline;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1.5px;
`;

export const StyledCurrencyContainer = styled.div`
  display: flex;
  margin: 15px;
  gap: 32px;
`;

export const StyledCurrency = styled.div`
  display: flex;
  flex-direction: column;

  :nth-child(2) {
    border-left: 1px solid #ddd;
    padding-left: 32px;
  }
`;

export const StyledDetailForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px 20px;
  font-size: 1.2rem;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 0;
    margin-top: 24px;
  }
`;

export const StyledDetailFormTitle = styled.span`
  color: #828282;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 20px;
  ${(props) => props.theme.breakpoints.down("md")} {
    display: none;
  }
`;

export const StyledDetailFormButton = styled.button`
  width: 100%;
  height: 44px;
  padding: 1px 6px;
  background-color: #147b45;
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const StyledPaymentButton = styled(Button)`
  background-color: #e8e8e8;
  color: #282b2f;
  font-size: 1.4rem;
  width: 75px;
  height: 46px;

  &:hover {
    background-color: lightgray;
  }
`;
