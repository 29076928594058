import { FC, useEffect, useReducer, useState } from "react";
import {
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import UniInput from "../CustomComponents/Input/Input";
import { login, resetLoginErrors } from "../../redux/slices/authSlice";
import { uniTokens } from "../../theme/uniTokens";
import { LoginFAForm } from "../LoginFAForm";
import { InputTypes, ChangeAction, FormState, LoginProps } from "./Login.d";
import { StyledLoginBox, StyledLoginButton } from "./Login.styled";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";

const formReducer = (state: FormState, action: ChangeAction) => {
  const usernameRegex = /^[^\s]{4,15}$/;
  const passwordRegex = /^[^\s]{4,15}$/;

  switch (action.type) {
    case InputTypes.USER_NAME:
      const isUsernameValid = usernameRegex.test(action.payload);
      return {
        ...state,
        username: action.payload,
        isUsernameValid,
      };
    case InputTypes.PASSWORD:
      const isPasswordValid = passwordRegex.test(action.payload);
      return {
        ...state,
        password: action.payload,
        isPasswordValid,
      };
    default:
      return state;
  }
};

const Login: FC<LoginProps> = ({ setLoginModalOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { error } = useAppSelector((state) => state.auth);
  const { twoFactorAuthentication } = useAppSelector((state) => state.auth);
  const status = useAppSelector(
    // needs type casting like as { status: number } since error is stated as unknown at the slice
    (state) => (state.auth?.error as { status: number })?.status,
  );
  const { t } = useCustomTranslation();
  const [formState, formDispatch] = useReducer(formReducer, {
    username: "",
    password: "",
    usernameError: t("header.usernameError"),
    passwordError: t("header.passwordError"),
    isUsernameValid: true,
    isPasswordValid: true,
  });
  const [isCaptchaRequired, setIsCaptchaRequired] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<null | string>(null);
  const [showPassword, setShowPassword] = useState(false);

  const onChangeHandler: (type: InputTypes, value: string) => void = (
    type,
    value,
  ) => {
    formDispatch({ type, payload: value });
  };

  const handleLoginClick = async () => {
    const res = await dispatch(
      login({
        username: formState.username,
        password: formState.password,
        token: captchaToken,
      }),
    );
    setLoginModalOpen && res && setLoginModalOpen(false);
  };

  const handleCaptcha = (token: string | null) => {
    setCaptchaToken(token);
    setIsCaptchaRequired(false);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (status === 4132 || status === 4122) {
      setIsCaptchaRequired(true);
    }
  }, [status]);

  useEffect(() => {
    return () => {
      dispatch(resetLoginErrors());
    };
  }, [dispatch]);

  return twoFactorAuthentication ? (
    <LoginFAForm username={formState.username} password={formState.password} />
  ) : (
    <FormGroup>
      <Typography
        fontSize={"2.4rem"}
        variant={"subtitle1"}
        align="center"
        sx={{
          fontSize: "2.4rem",
          color: "#ffffff",
        }}
      >
        {t("login.login")}
      </Typography>
      <UniInput
        fontSize={"2.4rem"}
        id={InputTypes.USER_NAME}
        label={t("login.username")}
        value={formState.username}
        onChange={(value) => onChangeHandler(InputTypes.USER_NAME, value)}
        error={!formState.isUsernameValid}
        errorText={formState.usernameError}
        required
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLoginClick();
          }
        }}
      />
      <UniInput
        id={InputTypes.PASSWORD}
        label={t("login.password")}
        value={formState.password}
        onChange={(value) => onChangeHandler(InputTypes.PASSWORD, value)}
        size="small"
        error={!formState.isPasswordValid}
        errorText={formState.passwordError}
        required
        type={showPassword ? "text" : "password"}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleLoginClick();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledLoginButton
        variant="contained"
        color="success"
        disabled={
          !formState.username ||
          !formState.password ||
          !formState.isUsernameValid ||
          !formState.isPasswordValid ||
          isCaptchaRequired
        }
        onClick={() => {
          handleLoginClick();
        }}
      >
        {t("login.login")}
      </StyledLoginButton>
      {isCaptchaRequired && (
        <ReCAPTCHA
          sitekey="6LcJwUUpAAAAAGhjg7tvv4JRSOLA-PKa93-QJ1AJ"
          onChange={handleCaptcha}
          className="recaptcha-wrapper"
        />
      )}
      {(error as { status: number })?.status && (
        <Typography
          sx={{
            color: uniTokens.red,
            fontSize: "14px",
            mt: 2,
          }}
        >
          {t(`errorMessages.${(error as { status: number })?.status}`)}
        </Typography>
      )}
      <Typography
        align="center"
        fontSize="14px"
        sx={{
          color: uniTokens.darkGreen,
          fontWeight: "500",
          textDecoration: "underline",
          mt: 4,
        }}
      >
        {`${t("login.forgotPassword")} >`}
      </Typography>
      <StyledLoginBox>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: 300 }}>
          {t("login.registerText")}
        </Typography>
        <Button
          sx={{ bgcolor: "rgb(229, 208, 28)", fontSize: "1.6rem" }}
          variant="contained"
          onClick={() => {
            setLoginModalOpen && setLoginModalOpen(false);
            navigate("/registration");
            window.location.reload();
          }}
        >
          {t("login.registerCTA")}
        </Button>
      </StyledLoginBox>
    </FormGroup>
  );
};

export default Login;
