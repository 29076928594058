import styled from "styled-components";
import { uniTokens } from "../../theme/uniTokens";
import { Link } from "react-router-dom";

export const StyledStaticContentPageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  padding: 0.8rem;
  max-width: 163.2rem;
  width: 100%;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
  }

  .general-info-right-side-wrapper {
    flex: 0 0 20%;
  }
`;
export const StyledStaticContentContainer = styled.div`
  flex: 1 0 50%;
  background-color: ${uniTokens.white};
  padding: 3.6rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 1.6rem;
  }

  img {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 100%;
  }

  ul {
    list-style: initial;
    margin-left: 1.8rem;
  }

  li {
    font-size: 1.6rem;
    line-height: 2.3rem;
  }

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.3rem;
  }
`;

export const StyledStaticContentLeftMenuContainer = styled.aside`
  flex: 0 0 20%;
  background-color: ${uniTokens.grey};
  height: fit-content;
`;

export const StyledStaticContentLeftMenuItems = styled.ul`
  list-style: none;
`;

export const StyledStaticContentLeftMenuItem = styled.li`
  border-bottom: 0.1rem solid ${uniTokens.backgroundSecondary};
  color: ${uniTokens.white}
  min-height: 4.4rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  transition: background-color 0.2s ease-in-out;
`;

export const StyledStaticContentLeftMenuLink = styled(Link)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;

  span:nth-child(1) {
    color: ${uniTokens.white};
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.4rem;
  }
`;

export const StyledStaticContentBreadCrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-bottom: 1.8rem;
`;

export const StyledStaticContentBreadCrumbTitle = styled.h2`
  font-weight: 500;
  font-size: 2rem;
  color: ${uniTokens.grey};
`;
