import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, Dispatch, SetStateAction } from "react";
import PaymentList from "../../components/Payment/PaymentList";
import PaymentProviderDetail from "../../components/Payment/PaymentProviderDetail";
import BonusSelector from "../../components/Payment/BonusSelector";
import { IProvider } from "../../redux/slices/paymentSlice";

const Deposit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedProvider, setSelectedProvider]: [
    IProvider | {},
    Dispatch<SetStateAction<IProvider | {}>>,
  ] = useState({});

  useEffect(() => {
    if (!location.pathname.includes("deposit")) {
      navigate("deposit");
    }
  }, [location.pathname, navigate]);

  return (
    <Box>
      {(selectedProvider as IProvider).id ? (
        <PaymentProviderDetail
          selectedProvider={selectedProvider as IProvider}
          setSelectedProvider={setSelectedProvider}
        />
      ) : (
        <>
          <BonusSelector />
          <PaymentList
            paymentType="deposit"
            setSelectedProvider={setSelectedProvider}
          />
        </>
      )}
    </Box>
  );
};

export default Deposit;
