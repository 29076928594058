import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { StyledLayout } from "./Layout.styled";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import favicon from "../../assets/favicon.ico";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import { ProfileMenuContext } from "../../ProfileMenuContext";
import { Box } from "@mui/material";

interface ILayoutProps {
  children: ReactNode;
  showHeaderAndFooter: boolean;
}

const Layout: FC<ILayoutProps> = ({ children, showHeaderAndFooter }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <ProfileMenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      <StyledLayout>
        <Helmet>
          <title>Unibahis</title>
          <link rel="shortcut icon" href={favicon} />
          <script>
            const lang = localStorage.getItem('language') || 'tr';
            document.documentElement.setAttribute('lang', lang);
          </script>
        </Helmet>
        {showHeaderAndFooter && <Header />}
        <Box
          component={"main"}
          sx={{ pl: 8, pt: 8, flexGrow: 1, background: "#1a2c38" }}
        >
          {children}
          {showHeaderAndFooter && <Footer />}
        </Box>
        <ProfileMenu />
      </StyledLayout>
    </ProfileMenuContext.Provider>
  );
};

export default Layout;
