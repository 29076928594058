import { createTheme } from "@mui/material";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import UniBahisWoff from "../assets/fonts/betbase/Unibahis_Pro_2020.woff";
import UniBahisWoff2 from "../assets/fonts/betbase/Unibahis_Pro_2020.woff2";
import UniBahisTtf from "../assets/fonts/betbase/Unibahis_Pro_2020.ttf";
import UniBahisMacTTf from "../assets/fonts/betbase/Unibahis_Pro_2020_Mac.ttf";

export const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    // subtitle1 variant for the self-hosted Unibahis font
    subtitle1: {
      fontFamily: ["UnibahisPro", "Roboto", "sans-serif"].join(","),
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'UnibahisPro';
          font-weight: normal;
          font-style: normal;
          src: local('UnibahisPro'),
          url(${UniBahisWoff2}) format('woff2'),
          url(${UniBahisWoff}) format('woff'),
          url(${UniBahisTtf}) format('truetype'),
          url(${UniBahisMacTTf}) format('truetype');
        }`,
    },
    MuiTextField: {
      styleOverrides: {
        root: `
        & .MuiOutlinedInput-notchedOutline {
        border: none;
        }`,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: `box-shadow: none !important;
        text-transform: unset !important;
        `,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: `
        &:before {
          background-color: #3f3f3f;
        }
        `,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: `
        min-height: 38px;
        `,
      },
    },
  },
  palette: {
    primary: {
      main: "#1a2c38",
    },
    secondary: {
      main: "#fdc300",
    },
    info: {
      main: "#efefef",
    },
    success: {
      main: "#00ff00",
    },
  },
});
