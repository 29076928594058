const login = {
  login: "GİRİŞ YAP",
  username: "Kullanıcı Adı",
  password: "Şifre",
  forgotPassword: "Şifrenizi mi Unuttunuz?",
  registerText: "Hala bir hesabın yok mu?",
  registerCTA: "Şimdi Kayıt Ol",
  notLogin: "Bu sayfaya erişmek için giriş yapınız.",
};

export default login;
