import { FC, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchPromotionDetails,
  selectPromotionDetails,
} from "../redux/slices/promotionsSlice";
import {
  fetchGeneralInfo,
  selectGeneralInfo,
} from "../redux/slices/generalInfoSlice";
import {
  StyledStaticContentPageWrapper,
  StyledStaticContentContainer,
  StyledStaticContentLeftMenuContainer,
  StyledStaticContentLeftMenuItems,
  StyledStaticContentLeftMenuItem,
  StyledStaticContentLeftMenuLink,
  StyledStaticContentBreadCrumb,
} from "../components/StaticContent/StaticContent.styled";
import styled from "styled-components";

export const StyledRightSideWrapper = styled.div`
  .general-info-right-side-wrapper {
    width: 30%;
  }

  div.general-info-right-side-link {
    cursor: pointer;
  }

  .general-info-right-side {
    &-box {
      background: #d8d8d8;
      border-top: 4px solid #999999;
      margin-bottom: 16px;
      .general-info-right-side-item:not(:last-of-type) {
        border-bottom: 1px solid #ffffff;
      }
    }

    &-item {
      padding: 4px 8px 4px 16px;
    }

    &-title {
      font-weight: 300;
      font-size: 18px;
      line-height: 200%;
      color: #484848;
    }

    &-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 200%;
      color: #3aaa35;
      display: flex;
      align-items: center;
      gap: 8px;
      text-decoration: none;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 200%;
      color: #333333;

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 200%;
        color: #3aaa35;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .general-info-right-side-wrapper {
      width: 100%;
    }
  }

  @media screen and (min-width: 1280px) {
    .general-info-right-side-wrapper {
      width: 20%;
    }
  }
`;

const StaticContent: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { category, promotion = "", infoSlug = "" } = useParams();
  const promotionDetails = useAppSelector(selectPromotionDetails);
  const generalInfo = useAppSelector(selectGeneralInfo);
  const pageType = location.pathname.match(/general-info/gi)
    ? "generalInfo"
    : "promotion";

  useEffect(() => {
    if (pageType === "promotion") {
      dispatch(
        fetchPromotionDetails({
          device: "desktop",
          footer: false,
          footerLeftMenu: false,
          leftMenu: true,
          lang: "tr",
          url: `${category}/${promotion}`,
        }),
      );
    } else {
      dispatch(
        fetchGeneralInfo({
          device: "desktop",
          footer: false,
          footerLeftMenu: false,
          leftMenu: true,
          lang: "tr",
          url: infoSlug,
        }),
      );
    }
  }, [dispatch, category, promotion, infoSlug, pageType]);

  const content = pageType === "promotion" ? promotionDetails : generalInfo;
  const generateMenuItemUrl = (url: string) => {
    return pageType === "promotion"
      ? `/promotions/detail/${category}/${url}`
      : `/general-info/${url}`;
  };

  return (
    <StyledStaticContentPageWrapper>
      {content?.leftMenu && (
        <StyledStaticContentLeftMenuContainer>
          <StyledStaticContentLeftMenuItems>
            {content.leftMenu.map((menuItem) => (
              <StyledStaticContentLeftMenuItem>
                <StyledStaticContentLeftMenuLink
                  to={generateMenuItemUrl(menuItem.url)}
                >
                  <span>{menuItem.title}</span>
                  <span>
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.341886 12.6838C0.137715 12.2754 0.217747 11.7823 0.54057 11.4594L5 7L0.54057 2.54057C0.217747 2.21775 0.137716 1.72457 0.341887 1.31623C0.665653 0.668696 1.52865 0.528651 2.04057 1.04057L8 7L2.04057 12.9594C1.52865 13.4713 0.665652 13.3313 0.341886 12.6838Z"
                        fill="#828282"
                      ></path>
                    </svg>
                  </span>
                </StyledStaticContentLeftMenuLink>
              </StyledStaticContentLeftMenuItem>
            ))}
          </StyledStaticContentLeftMenuItems>
        </StyledStaticContentLeftMenuContainer>
      )}
      <StyledStaticContentContainer>
        {pageType === "promotion" && (
          <StyledStaticContentBreadCrumb>
            <Link to="/promotions">
              <svg
                width="39"
                height="39"
                viewBox="0 0 39 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="39" height="39" fill="#E8E8E8"></rect>
                <path
                  d="M21.5344 20.9234L22.9004 22.2717C23.5054 22.8689 23.5054 23.8454 22.9004 24.4426C22.3065 25.0288 21.3517 25.0288 20.7577 24.4426L17.1923 20.9234C16.3989 20.1403 16.3989 18.8597 17.1923 18.0766L20.7577 14.5574C21.3517 13.9712 22.3065 13.9712 22.9004 14.5574C23.5054 15.1546 23.5054 16.1311 22.9004 16.7283L21.5344 18.0766C20.741 18.8597 20.741 20.1403 21.5344 20.9234Z"
                  fill="#828282"
                ></path>
              </svg>
            </Link>
            <h2>{content?.title}</h2>
          </StyledStaticContentBreadCrumb>
        )}
        {content && (
          <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
        )}
      </StyledStaticContentContainer>
      <StyledRightSideWrapper className="general-info-right-side-wrapper">
        <div className="general-info-right-side-box">
          <div className="general-info-right-side-item">
            <h3 className="general-info-right-side-title">Destek ve Yardım</h3>
          </div>
          <div className="general-info-right-side-item">
            <a
              className="general-info-right-side-link"
              href="/general-info/guvenlik-bilgileri"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7.5 10.375C7.5 10.4437 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4437 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z"
                  fill="#3AAA35"
                ></path>
              </svg>
              Unibahis Güvenlik Merkezi
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.10046 0H0V14H14V9H12V12H2L2 2H5.10046V0ZM14 0V6L12 6V3.55598L6.92467 8.63148L5.51043 7.21729L10.7275 2H8.10046V0H14Z"
                  fill="#999999"
                ></path>
              </svg>
            </a>
          </div>
          <div className="general-info-right-side-item">
            <div className="general-info-right-side-link">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 0H0V11H9L12 14V11H14V0Z" fill="#3AAA35"></path>
              </svg>
              Canlı Destek
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.10046 0H0V14H14V9H12V12H2L2 2H5.10046V0ZM14 0V6L12 6V3.55598L6.92467 8.63148L5.51043 7.21729L10.7275 2H8.10046V0H14Z"
                  fill="#999999"
                ></path>
              </svg>
            </div>
          </div>
          <div className="general-info-right-side-item">
            <p className="general-info-right-side-text">
              Unibahis Canlı Destek 7/24 Hizmet Vermektedir.
            </p>
          </div>
        </div>
      </StyledRightSideWrapper>
      ;
    </StyledStaticContentPageWrapper>
  );
};
export default StaticContent;
