const footer = {
  sport: "Spor Bahisleri",
  casino: "Casino",
  liveCasino: "Canlı Casino",
  bonus: "Bonuslar",
  unibahis: "Unibahis",
  support: "Destek",
  addictiveText: "Bahis bağımlılık yapabilir. Lütfen bilinçli oynayın.",
  licenceText:
    "www.unibahis.com 164032 lisans numarası altında Abraham Mendez Chumaceiro Bulvarı 03, Çuraçao adresine kayıtlı United Betting B.V. grubu tarafından işletilmektedir. Bu websitesi Çuraçao eGaming tarafından lisanslandırılmıştır ve bu makam tarafından regüle edilmektedir.",
  responsibleGambling: "Bilinçli Oyun",
  termsAndConditions: "Hükümler ve Koşullar",
  privacyPolicy: "Gizlilik Politikası",
  securityInformation: "Güvenlik Bilgileri",
  cookies: "Çerezler",
  providers: "Oyun Sağlayıcıları",
  paymentMethods: "Güvenli Ödeme Yöntemleri",
  language: "Dili Değiştir",
};
export default footer;
