import dayjs from "dayjs";
import { startOfDay, endOfDay, subDays, subMonths } from "date-fns";
export const days = ["Day", ...Array.from({ length: 31 }, (_, i) => i + 1)];
export const months = ["Month", ...Array.from({ length: 12 }, (_, i) => i + 1)];
export const years = [
  "Year",
  ...Array.from({ length: 100 }, (_, i) => 2006 - i),
];

export const predefinedDateRanges = [
  {
    name: "today",
    fromDate: startOfDay(new Date()),
    toDate: endOfDay(new Date()),
  },
  {
    name: "lastTwoDays",
    fromDate: startOfDay(subDays(new Date(), 2)),
    toDate: endOfDay(new Date()),
  },
  {
    name: "week",
    fromDate: startOfDay(subDays(new Date(), 7)),
    toDate: endOfDay(new Date()),
  },
  {
    name: "month",
    fromDate: startOfDay(subMonths(new Date(), 1)),
    toDate: endOfDay(new Date()),
  },
];
