import "./regForm.css";
import { useState } from "react";
import { UniInput } from "../CustomComponents";
import { days, months, years } from "../../constants/dates";
import {
  StyledDateContainer,
  StyledPhoneNumberContainer,
  StyledIconWrapper,
} from "../../routes/Registration/Registration.styled";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import TelegramIcon from "@mui/icons-material/Telegram";
import { SecondPage } from "./SecondPage";
export const RegistrationForm = () => {
  const [isIsFirstPage, setIsFirstPage] = useState(true);
  return (
    <div className="contianer">
      <div style={{ display: isIsFirstPage ? "block" : "none" }}>
        <div className="header">
          <h1>Create an Account</h1>
          <h2>Step 1/2: Fill out your details</h2>
        </div>

        <UniInput label="Email" />
        <UniInput
          label="Username"
          helperText="Your username must be 3-14 characters long."
        />
        <UniInput label="Password" type="password" />
        <Typography variant="h6" sx={{ color: "#b1bad3" }}>
          Date of Birth
        </Typography>
        <StyledDateContainer>
          <Select
            sx={{
              minWidth: "30%",
              m: 0,
              height: "40px",
              fontSize: "1.6rem",
              color: "#ffffff",
              border: "1px solid #ffffff",
            }}
          >
            {days.map((day) => (
              <MenuItem
                value={day}
                key={day}
                disabled={day === "Day"}
                sx={{ fontSize: "1.4rem" }}
              >
                {day}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{
              minWidth: "30%",
              height: "40px",
              fontSize: "1.6rem",
              color: "#ffffff",
              border: "1px solid #ffffff",
            }}
          >
            {months.map((month) => (
              <MenuItem
                value={month}
                key={month}
                disabled={month === "Month"}
                sx={{ fontSize: "1.6rem" }}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{
              minWidth: "30%",
              height: "40px",
              fontSize: "1.6rem",
              color: "#ffffff",
              border: "1px solid #ffffff",
            }}
          >
            {years.map((year) => (
              <MenuItem
                value={year}
                key={year}
                disabled={year === "Year"}
                sx={{ fontSize: "1.4rem" }}
              >
                {year}
              </MenuItem>
            ))}
          </Select>
        </StyledDateContainer>
        <StyledPhoneNumberContainer>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            <UniInput
              InputProps={{
                readOnly: false,
              }}
              customStyle={{
                width: "25%",
                marginRight: "10px",
              }}
              label="Phone Number (Optional)"
            />
            <UniInput
              customStyle={{
                width: "100%",
                marginRight: "10px",
                marginLeft: 0,
              }}
            />
          </Box>
        </StyledPhoneNumberContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <input type="checkbox" id="vehicle1" name="vehicle1" value="" />
          <label
            htmlFor="vehicle1"
            style={{ fontSize: "1.5rem", color: "#ffffff" }}
          >
            Code (Optional)
          </label>
        </div>
        <div
          className="button-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "#1FFF20",
              color: "black",
              border: "none",
              padding: "10px 20px",
              fontSize: "1.5rem",
              cursor: "pointer",
              alignItems: "center",
              flexBasis: "100%",
              borderRadius: "4px",
            }}
            onClick={() => setIsFirstPage(false)}
          >
            Continue
          </button>
        </div>
        <div className="accounts">
          <div
            className="textPart"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              justifyContent: "center",
            }}
          >
            <h2>--OR--</h2>
          </div>
          <div className="account-logos">
            <StyledIconWrapper>
              <FacebookIcon
                style={{
                  fontSize: "3rem",
                  color: "#3b5998",
                  cursor: "pointer",
                }}
              />
            </StyledIconWrapper>
            <StyledIconWrapper>
              <GoogleIcon
                style={{
                  fontSize: "3rem",
                  color: "#db4437",
                  cursor: "pointer",
                }}
              />
            </StyledIconWrapper>
            <StyledIconWrapper>
              <TelegramIcon
                style={{
                  fontSize: "3rem",
                  color: "#0088cc",
                  cursor: "pointer",
                }}
              />
            </StyledIconWrapper>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 style={{ color: "#ffffff", marginTop: "1rem" }}>
              Already have an account? <a href="#">Sign In</a>
            </h2>
          </div>
        </div>
      </div>
      <div
        style={{
          display: isIsFirstPage ? "none" : "block",
        }}
      >
        <SecondPage />
      </div>
    </div>
  );
};
