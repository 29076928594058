const header = {
  help: "Get Help",
  termsAndConditions: "Terms and Conditions",
  login: "Login",
  register: "Register",
  sports: "Sports",
  liveSports: "Live Sports",
  casino: "Casino",
  liveCasino: "Live Casino",
  virtualSports: "Virtual Sports",
  promotions: "Promotions",
  language: "Languages",
  usernameError: "Username size must be between 4 and 15 characters",
  passwordError: "Password size must be between 6 and 15 characters",
  loginError: "Please check your login details and try again.",
  deposit: "Deposit",
  balance: "Balance",
  bonus: "Bonus",
};
export default header;
