import {
  Select,
  MenuItem,
  Button,
  Pagination,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { predefinedDateRanges } from "../../constants/dates";
import { StyledBox, StyledDatePicker } from "../Payments/PaymentHistory";
import React, { useEffect } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material/";
import styled from "styled-components";
import { uniAxiosInstance } from "../../utils/axiosInstances";

const STATE_COLORS = {
  Win: "#299B59",
  Bet: "#F2C94C",
  Loss: "#EB5757",
};

const StyledCasinoHistoryWrapper = styled.div`
  background: white;
  border-radius: 12px !important;
  font-size: 12px;

  .casino-table {
    width: 100%;
    padding: 5px;
    background: white;
    color: #16191d;
    margin: auto;
    padding: 21px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .table-header div {
    width: 20%;
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .expandable {
    overflow: auto;
    height: 0;
    opacity: 0;
  }

  .expanded {
    height: auto;
    opacity: 1;
  }

  .table-row-item {
    display: flex;
    justify-content: space-between;
    transition: all 0.3s;
  }
  .is-open {
  }

  .down-arrow-wrapper {
    cursor: pointer;
    text-align: -webkit-right;
    background-color: white;
    padding: 5px 0;
  }

  .arrow-styler {
    width: fit-content;
    margin-right: 10px;
    border-radius: 50%;
    padding: 0 10px;
    color: #147b45;
    transition: all 0.3s;
  }

  .mobile-arrow-up {
    transform: rotate(180deg);
  }

  .table-row-item div {
    width: 20%;
    align-self: center;
    text-align: left;
    margin-left: 7px;
  }
  .arrow-area {
    width: 30px !important;
  }
  .mobile {
    padding: 10px;
  }

  .mobile tr th {
    border-bottom: 1px solid rgba(196, 196, 196, 1);
    text-align: left;
  }

  .mobile tr th:first-child {
    text-align: left;
  }

  .mobile tr th:nth-child(2) {
    text-align: right;
    font-weight: 500;
  }
  .casino-table td {
    text-align: center;
    vertical-align: middle;
    padding: 2px;
  }

  .casino-table th {
    text-align: center;
    background: white;
    color: $skin-palette-14;
    padding: 8px;
    font-weight: 100;
  }

  .mobile td {
    padding: 5px;
    border-right: 1px solid $hero-color;
    font-weight: 900;
  }

  .mobile th {
    vertical-align: middle;
    width: 120px;
    color: #16191d;
    font-weight: 900;
  }

  .filter-item {
    width: 150px;
    margin-right: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 10px;
  }

  .custom-date-range-picker {
    -webkit-appearance: none;
    padding: 10px 10px;
    border-radius: 6px;
    font-weight: 100;
    background: #147b45;
    color: white;
    min-width: 160px;
    margin: 10px 0;
    transition: all 0.4s;
  }

  .custom-date-range-picker:hover {
    border: 1px solid rgba($hero-color, 0.4);
  }

  .pagination-area {
    text-align: end;
    margin-top: 10px;
  }

  .pagination-area button {
    margin: 0 4px;
    vertical-align: super;
  }

  .search-button {
    padding: 5px 30px;
    border-radius: 26px;
    border: none;
    background-color: rgba($hero-color, 1);
    color: white;
    align-self: center;
    height: 40px;
    font-weight: 100;
    margin-left: 20px;
    cursor: pointer;
  }

  .page-buttons {
    all: unset;
    background: #dedede;
    padding: 5px 10px;
    color: black;
    cursor: pointer;
    border-radius: 3px;
  }

  .active-page {
    background: #dedede;
  }

  @media only screen and (max-width: 700px) {
    .filter-item {
      width: 98%;
      display: flex;
      flex-direction: column;
    }
    .custom-date-range-picker {
      width: 100%;
    }
    .search-button {
      width: 100%;
      margin-bottom: 30px;
      margin-left: 0;
      margin-right: 10px;
    }
    .expanded {
      height: 160px;
    }
  }

  @media only screen and (min-width: 700px) {
    .filter-item {
      margin-left: 30px !important;
    }
  }
`;

const CasinoHistory = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { t } = useCustomTranslation();
  const [activeDateType, setActiveDateType] = React.useState(0);
  const [startDate, setStartDate] = React.useState<Date>(
    predefinedDateRanges[0].fromDate,
  );
  const [endDate, setEndDate] = React.useState<Date>(
    predefinedDateRanges[0].toDate,
  );
  const [data, setData] = React.useState<any[]>([]);
  const isMobile = useIsMobile();
  const [activeItems, setActiveItems] = React.useState<any[]>([]);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [displaySize, setDisplaySize] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);

  useEffect(() => {
    load(0);
  }, [activeTab]);

  const load = (page) => {
    const endPoint = `balance-api/casino/history?from=${startDate.valueOf()}&to=${endDate.valueOf()}&pageNumber=${
      page || pageNumber
    }&size=${displaySize}`;

    uniAxiosInstance(endPoint, { method: "GET" })
      .then((res) => {
        const { data, count } = res;
        console.log({ data });
        setData(data?.data);
        setTotalCount(count);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleDateTypeChange = (e: any) => {
    if (!e || !e.target || !e.target.value) {
      return;
    }

    if (e.target.value == -1) {
      setActiveDateType(e.target.value as number);
      return;
    }
    setActiveDateType(e.target.value as number);
    setStartDate(predefinedDateRanges[e.target.value].fromDate);
    setEndDate(predefinedDateRanges[e.target.value].toDate);
  };
  return (
    <StyledCasinoHistoryWrapper className="casino-history-wrapper">
      <StyledBox display="flex" gap={2} pl={4} mt={4}>
        <Select
          sx={{
            m: 0,
            bgcolor: "#eaeaea",
            height: "40px",
            minWidth: "100px",
            fontSize: 13,
          }}
          onChange={handleDateTypeChange}
          value={activeDateType}
        >
          {predefinedDateRanges.map((range, index) => (
            <MenuItem value={index} key={index}>
              {t(`payments.${range.name}`)}
            </MenuItem>
          ))}
          <MenuItem value={-1}>{t("payments.customRange")}</MenuItem>
        </Select>
        {activeDateType == -1 && (
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(e: Date) => setStartDate(e)}
            maxDate={endDate}
          />
        )}
        {activeDateType == -1 && (
          <StyledDatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(e: Date) => setEndDate(e)}
            minDate={startDate}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: 14 }}
          onClick={() => load(0)}
        >
          Goster
        </Button>
      </StyledBox>
      {data?.length > 0 ? (
        <div>
          {isMobile ? (
            data?.map((historyItem, index) => {
              return (
                <div
                  style={{
                    padding: 20,
                    marginBottom: 20,
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  }}
                >
                  <table className="casino-table mobile">
                    <tr>
                      <th>ID #</th>
                      <th>{historyItem.id}</th>
                    </tr>

                    <tr>
                      <th>{t(`global.playTime`)}</th>
                      <th>{historyItem.playDateTime}</th>
                    </tr>

                    <tr>
                      <th>{t(`global.gameName`)}</th>
                      <th>{historyItem.gameName}</th>
                    </tr>

                    <tr>
                      <th>{t(`global.bonusNameHistory`)}</th>
                      <th>{historyItem.bonusNameIfBonusPlayed}</th>
                    </tr>

                    <tr>
                      <th>{t(`global.stakeAmount`)}</th>
                      <th>{historyItem.amount}</th>
                    </tr>

                    <tr>
                      <th>{t(`global.winAmount`)}</th>
                      <th>{historyItem.winAmount}</th>
                    </tr>
                  </table>

                  <div className="down-arrow-wrapper">
                    {historyItem.details.map((detail) => {
                      return (
                        <div
                          className={`table-row-item expandable ${
                            activeItems.includes(historyItem) ? "expanded" : ""
                          }`}
                        >
                          <table className="casino-table mobile">
                            <tr>
                              <th>Balance Before</th>
                              <th>{detail.balanceBeforePlay}</th>
                            </tr>
                            <tr>
                              <th>Amount</th>
                              <th>{detail.betAmount}</th>
                            </tr>
                            <tr>
                              <th>Balance After</th>
                              <th>{detail.balanceAfterPlay}</th>
                            </tr>
                            <tr>
                              <th>Type</th>
                              <th>{`${detail.bonusPlayed ? "Bonus - " : ""}${
                                detail.status
                              }`}</th>
                            </tr>
                          </table>
                        </div>
                      );
                    })}
                    <div
                      className={`arrow-styler ${
                        activeItems.includes(historyItem)
                          ? "mobile-arrow-up"
                          : ""
                      }`}
                      //   onClick={() => this.handleToggle(historyItem)}
                    >
                      <KeyboardArrowDown />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="casino-table">
              <div className="table-header">
                <div className="arrow-area" />
                <div>ID #</div>
                <div>{t(`global.playTime`)}</div>
                <div>{t(`global.gameName`)}</div>
                <div>{t(`global.bonusNameHistory`)}</div>
                <div>{t(`global.stakeAmount`)}</div>
                <div>{t(`global.winAmount`)}</div>
              </div>

              <div>
                {data.map((historyItem) => {
                  return (
                    <div>
                      <div
                        style={{
                          borderLeft: `3px solid ${
                            STATE_COLORS[historyItem.status]
                          }`,
                        }}
                        className={`table-row-item ${
                          activeItems.includes(historyItem) ? "is-open" : ""
                        }`}
                        // onClick={() => this.handleToggle(historyItem)}
                      >
                        <div>{historyItem.id}</div>
                        <div>{historyItem.playDateTime}</div>
                        <div>{historyItem.gameName}</div>
                        <div>{historyItem.bonusNameIfBonusPlayed}</div>
                        <div>{historyItem.amount}</div>
                        <div style={{ fontWeight: 900 }}>
                          {historyItem.winAmount}
                        </div>
                        <div className="arrow-area">
                          {activeItems.includes(historyItem) ? (
                            <KeyboardArrowDown />
                          ) : (
                            <KeyboardArrowUp />
                          )}
                        </div>
                      </div>
                      {historyItem.details.map((detail) => {
                        return (
                          <div
                            className={`table-row-item expandable ${
                              activeItems.includes(historyItem)
                                ? "expanded"
                                : ""
                            }`}
                          >
                            <div className="arrow-area" />
                            <div />
                            <div>{detail.playDateTime}</div>
                            <div>
                              {t(`global.balanceBefore`)}:{" "}
                              {detail.balanceBeforePlay}
                            </div>
                            <div>{detail.betAmount}</div>
                            <div>
                              {t(`global.balanceAfter`)}:{" "}
                              {detail.balanceAfterPlay}
                            </div>

                            <div>{`${detail.bonusPlayed ? "Bonus - " : ""}${
                              detail.status === "Loss"
                                ? t(`global.loss`)
                                : detail.status === "Win"
                                ? t(`global.win`)
                                : detail.status
                            }`}</div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {/* {this.state.totalCount > 0 && (
            <Pagination
              changePageHandle={this.getAllCasinoHistoryData}
              totalCount={this.state.totalCount}
              displaySize={this.state.displaySize}
            />
          )} */}
        </div>
      ) : (
        <Typography textAlign="center" fontSize={14} margin="32px auto">
          {t(`global.noBetPlaced`)}
        </Typography>
      )}
    </StyledCasinoHistoryWrapper>
  );
};

export default CasinoHistory;
