import { Box, Typography } from "@mui/material";
import React from "react";
import { Routes, Route } from "react-router-dom";
import RightMenuNavigation from "../../components/RightMenuNavigation/RightMenuNavigation";
import { uniTokens } from "../../theme/uniTokens";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import CasinoHistory from "./CasinoHistory";

const data = [
  { title: "openBets", to: "open-bets" },
  { title: "betHistory", to: "bets" },
  { title: "casinoBets", to: "casino-bets" },
];

const GameHistory = () => {
  const { t } = useCustomTranslation();
  const translatedData = data.map((item) => ({
    ...item,
    title: t(`gameHistory.${item.title}`),
  }));

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: uniTokens.white,
        padding: "15px 0 0 0",
        margin: "24px 5px",
        minHeight: "80vh",
      })}
    >
      <Typography
        textAlign="center"
        sx={{ fontSize: 14, fontWeight: 900, mb: 1 }}
        variant="h1"
      >
        {t("profileMenu.gameHistory")}
      </Typography>
      <RightMenuNavigation data={translatedData} />
      <Routes>
        {/* <Route path="/" element={< />} />
        <Route path="deposit" element={< />} />
        <Route path="withdraw" element={< />} /> */}
        <Route path="casino-bets" element={<CasinoHistory />} />
      </Routes>
    </Box>
  );
};

export default GameHistory;
