import { Box } from "@mui/material";
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavLink = styled(NavLink)`
  color: #afb2b6 !important;
  font-weight: 600;
  font-size: 14px;
  &.active {
    color: green !important;
  }
`;

const RightMenuNavigation: FC<{ data: { to: string; title: string }[] }> = ({
  data,
}) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      paddingBottom="20px"
      gap={1}
      justifyContent="center"
    >
      {data.map((item) => {
        return (
          <StyledNavLink key={item.to} to={`${item.to}`}>
            {item.title}
          </StyledNavLink>
        );
      })}
    </Box>
  );
};

export default RightMenuNavigation;
