const login = {
  login: "LOG IN",
  username: "Username",
  password: "Password",
  forgotPassword: "Forgot Your Password?",
  registerText: "Dont't have an account?",
  registerCTA: "Register now",
  notLogin: "Please login to access this page.",
};

export default login;
