const global = {
  adjustment: "Adjustment",
  all: "All",
  all_uppercase: "ALL",
  american: "American",
  amount: "Amount",
  amount_uppercase: "Amount",
  approved: "Approved",
  balance: "Balance",
  balanceManagement: "Balance Management",
  balanceManagement_uppercase: "BALANCE MANAGEMENT",
  betHistory: "Bet History",
  bonus: "Bonus",
  bonuses: "Bonuses",
  bonuses_uppercase: "BONUSES",
  bonusName: "Name",
  cancel: "Cancel",
  cancelledbyclient: "Cancelled by Client",
  cancelledbyoperator: "Cancelled By Operator",
  casino: "Casino",
  casinoBalance: "Casino Balance",
  casinoBonus: "Casino Bonus",
  casinoHistory: "Casino History",
  casinoBets: "Casino Bets",
  changePassword: "Change Password",
  convertedBalance: "Converted Balance",
  couponId: "Coupon ID",
  "correction credit": "Correction Credit",
  "correction debit": "Correction Debit",
  customRange: "Custom Range",
  date: "Date",
  date_uppercase: "Date",
  decimal: "Decimal",
  deposit: "Deposit",
  deposits_uppercase: "Deposits",
  fractional: "Fractional",
  games: "Games",
  gameHistory_uppercase: "GAME HISTORY",
  history: "History",
  playTime: "Play Time",
  gameName: "Game Name",
  stakeAmount: "Stake Amount",
  winAmount: "Win Amount",
  bonusNameHistory: "Bonus Name",
  balanceBefore: "Balance Before",
  balanceAfter: "Balance After",
  loss: "Loss",
  lost: "Lost",
  win: "Win",
  history_uppercase: "HISTORY",
  hongkong: "Hongkong",
  indo: "Indo",
  lastLogin: "Last Login:",
  lastTwoDays: "Last Two Days",
  live: "Live Sports",
  liveCasino: "Live Casino",
  liveInPlay: "Live-In-Play",
  logout: "Logout",
  mainBalance: "Main Balance",
  malay: "Malay",
  matchResult: "Match Result",
  matches: "Matches",
  max: "Max.",
  messages: "Messages",
  messages_uppercase: "MESSAGES",
  min: "Min.",
  month: "Month",
  myProfile: "My Profile",
  myProfile_uppercase: "MY PROFILE",
  nameSurname: "Name Surname",
  no: "No",
  noTransaction: "There is no transaction currently",
  notResulted: "Not Resulted",
  oddsFormat: "Odds Format",
  openBets: "Open Bets",
  operationType: "Operation Type",
  passwordRules:
    "Password field is required. Your password must contain at least 8 and no more than 19 characters, including at least one letter and one number.",
  passwordRepeatRule: "Passwords do not match.",
  pendings: "Pendings",
  personalDetails: "Personal Details",
  preMatch: "Pre-Match",
  promotions: "Promotions",
  returnAmount: "Return Amount",
  show: "Show",
  hide: "Hide",
  sportBonus: "Sport Bonus",
  sport: "Sport",
  sports: "Sports",
  state: "State",
  success: "Success",
  error: "Error",
  today: "Today",
  totalFunds: "Total Funds",
  virtualSports: "Virtual Sports",
  yes: "Yes",
  week: "Week",
  withdraw: "Withdraw",
  withdrawals: "Withdrawals",
  withdrawals_uppercase: "WITHDRAWALS",
  won: "Won",
  cashout: "Cashout",
  cashoutBtn: "CASHOUT",
  cashoutDone: "Cashout Done",
  cashoutOut: "Cashout Out",
  userId: "User ID:",
  copiedUserId: "User id copied.",
  declined: "Declined",
  unsettled: "Unsettled",
  returned: "Returned",
  cashedOut: "Cashed Out",
  pick: "Pick",
  noGameMatched: "No games matching your criteria in the selected league",
  info: "Info",
  cashoutNewPrice: "New Price",
  playForReal: "Play for real",
  playForFun: "Play for fun",
  done: "Done",
  loadMore: "Load More",
  favorite: "Favorite",
  favorites: "Favorites",
  removeAll: "Remove All",
  events: "Events",
  leagues: "Leagues",
  score: "Score",
  h2hChart: "H2H Chart",
  stats: "Stats",
  timeline: "Timeline",
  dangerousAttack: "Dangerous Attack",
  corner: "Corner",
  shotOnTarget: "Shot On Target",
  shotOffTarget: "Shot Off Target",
  yellowCard: "Yellow Card",
  redCard: "Red Card",
  markets: "Markets",
  chooseYourLang: "Choose Your Language",
  gameEnded: "Game Not Found",
  backToList: "Back to List",
  paymentType: "Payment Type",
  casinoSearchText: "Write the game you are looking for...",
  firstHalf: "1st Half",
  secondHalf: "2nd Half",
  footerLegal:
    " www.unibahis.com is operated by the United Betting Group B.V. registered under No. 164032 at Curacao, Abraham Mendez Chumaceiro Boulevard 03. This website is licensed and regulated by Curaçao eGaming (Curaçao license No. 1668 JAZ issued by Curaçao eGaming).",
  noBetPlaced: "Not Bet Placed",
  noFavoritesText:
    "You have no favorite games or competitions. You can make games or competitions favorite by clicking a star in a game or competition row.",
  notStarted: "Not Started",
  firstSet: "1st Set",
  secondSet: "2nd Set",
  thirdSet: "3rd Set",
  fourthSet: "4th Set",
  fifthSet: "5th Set",
  casinoNoResult: `Your search did not have any matches`,
  loading: "Loading...",
  submit: "Submit",
  resetPassword: "Reset Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  invalidPassword:
    "Your password must contain at least 8 and no more than 19 characters, including at least one letter and one number.",
  passWordNotMatch: "Passwords do not match",
};

export default global;
