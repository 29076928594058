export const countries = [
  { iso: "AD", phoneCode: "+376", name: "Andorra", nameTR: "Andorra" },
  { iso: "AE", phoneCode: "+971", name: "UAE", nameTR: "BAE" },
  { iso: "AF", phoneCode: "+93", name: "Afghanistan", nameTR: "Afganistan" },
  {
    iso: "AG",
    phoneCode: "+1-268",
    name: "Antigua and Barbuda",
    nameTR: "Antigua ve Barbuda",
  },
  { iso: "AL", phoneCode: "+355", name: "Albania", nameTR: "Arnavutluk" },
  { iso: "AM", phoneCode: "+374", name: "Armenia", nameTR: "Ermenistan" },
  { iso: "AO", phoneCode: "+244", name: "Angola", nameTR: "Angola" },
  { iso: "AR", phoneCode: "+54", name: "Argentina", nameTR: "Arjantin" },
  {
    iso: "AS",
    phoneCode: "+1-684",
    name: "American Samoa",
    nameTR: "Amerikan Samoası",
  },
  { iso: "AT", phoneCode: "+43", name: "Austria", nameTR: "Avusturya" },
  { iso: "AU", phoneCode: "+61", name: "Australia", nameTR: "Avustralya" },
  { iso: "AW", phoneCode: "+297", name: "Aruba", nameTR: "Aruba" },
  { iso: "AZ", phoneCode: "+994", name: "Azerbaijan", nameTR: "Azerbaycan" },
  {
    iso: "BA",
    phoneCode: "+387",
    name: "Bosnia and Herzegovina",
    nameTR: "Bosna-Hersek",
  },
  { iso: "BB", phoneCode: "+1-246", name: "Barbados", nameTR: "Barbados" },
  { iso: "BD", phoneCode: "+880", name: "Bangladesh", nameTR: "Bangladeş" },
  { iso: "BE", phoneCode: "+32", name: "Belgium", nameTR: "Belçika" },
  {
    iso: "BF",
    phoneCode: "+226",
    name: "Burkina Faso",
    nameTR: "Burkina Faso",
  },
  { iso: "BG", phoneCode: "+359", name: "Bulgaria", nameTR: "Bulgaristan" },
  { iso: "BH", phoneCode: "+973", name: "Bahrain", nameTR: "Bahreyn" },
  { iso: "BI", phoneCode: "+257", name: "Burundi", nameTR: "Burundi" },
  { iso: "BJ", phoneCode: "+229", name: "Benin", nameTR: "Benin" },
  { iso: "BM", phoneCode: "+1-441", name: "Bermuda", nameTR: "Bermuda" },
  { iso: "BN", phoneCode: "+673", name: "Brunei", nameTR: "Brunei" },
  { iso: "BO", phoneCode: "+591", name: "Bolivia", nameTR: "Bolivya" },
  { iso: "BR", phoneCode: "+55", name: "Brazil", nameTR: "Brezilya" },
  { iso: "BS", phoneCode: "+1-242", name: "Bahamas", nameTR: "Bahamalar" },
  { iso: "BT", phoneCode: "+975", name: "Bhutan", nameTR: "Bhutan" },
  { iso: "BW", phoneCode: "+267", name: "Botswana", nameTR: "Botsvana" },
  { iso: "BY", phoneCode: "+375", name: "Belarus", nameTR: "Beyaz Rusya" },
  { iso: "BZ", phoneCode: "+501", name: "Belize", nameTR: "Belize" },
  { iso: "CA", phoneCode: "+1", name: "Canada", nameTR: "Kanada" },
  {
    iso: "CD",
    phoneCode: "+243",
    name: "Democratic Republic of the Congo",
    nameTR: "Kongo Demokratik Cumhuriyeti",
  },
  {
    iso: "CF",
    phoneCode: "+236",
    name: "Central African Republic",
    nameTR: "Orta Afrika Cumhuriyeti",
  },
  {
    iso: "CG",
    phoneCode: "+242",
    name: "Republic of the Congo",
    nameTR: "Kongo Cumhuriyeti",
  },
  { iso: "CH", phoneCode: "+41", name: "Switzerland", nameTR: "İsviçre" },
  {
    iso: "CI",
    phoneCode: "+225",
    name: "Cote d'Ivoire",
    nameTR: "Fildişi Sahili",
  },
  {
    iso: "CK",
    phoneCode: "+682",
    name: "Cook Islands",
    nameTR: "Cook Adaları",
  },
  { iso: "CL", phoneCode: "+56", name: "Chile", nameTR: "Şili" },
  { iso: "CM", phoneCode: "+237", name: "Cameroon", nameTR: "Kamerun" },
  { iso: "CN", phoneCode: "+86", name: "China", nameTR: "Çin" },
  { iso: "CO", phoneCode: "+57", name: "Colombia", nameTR: "Kolombiya" },
  { iso: "CR", phoneCode: "+506", name: "Costa Rica", nameTR: "Kosta Rika" },
  { iso: "CU", phoneCode: "+53", name: "Cuba", nameTR: "Küba" },
  {
    iso: "CV",
    phoneCode: "+238",
    name: "Cape Verde",
    nameTR: "Yeşil Burun Adaları",
  },
  { iso: "CY", phoneCode: "+357", name: "Cyprus", nameTR: "Kıbrıs" },
  {
    iso: "CZ",
    phoneCode: "+420",
    name: "Czech Republic",
    nameTR: "Çek Cumhuriyeti",
  },
  { iso: "DE", phoneCode: "+49", name: "Germany", nameTR: "Almanya" },
  { iso: "DJ", phoneCode: "+253", name: "Djibouti", nameTR: "Cibuti" },
  { iso: "DK", phoneCode: "+45", name: "Denmark", nameTR: "Danimarka" },
  { iso: "DM", phoneCode: "+1-767", name: "Dominica", nameTR: "Dominika" },
  {
    iso: "DO",
    phoneCode: "+1-809",
    name: "Dominican Republic",
    nameTR: "Dominik Cumhuriyeti",
  },
  { iso: "DZ", phoneCode: "+213", name: "Algeria", nameTR: "Cezayir" },
  { iso: "EC", phoneCode: "+593", name: "Ecuador", nameTR: "Ekvador" },
  { iso: "EE", phoneCode: "+372", name: "Estonia", nameTR: "Estonya" },
  { iso: "EG", phoneCode: "+20", name: "Egypt", nameTR: "Mısır" },
  { iso: "ER", phoneCode: "+291", name: "Eritrea", nameTR: "Eritre" },
  { iso: "ES", phoneCode: "+34", name: "Spain", nameTR: "İspanya" },
  { iso: "ET", phoneCode: "+251", name: "Ethiopia", nameTR: "Etiyopya" },
  { iso: "FI", phoneCode: "+358", name: "Finland", nameTR: "Finlandiya" },
  { iso: "FJ", phoneCode: "+679", name: "Fiji", nameTR: "Fiji" },
  {
    iso: "FM",
    phoneCode: "+691",
    name: "Federated States of Micronesia",
    nameTR: "Mikronezya Federal Devletleri",
  },
  {
    iso: "FO",
    phoneCode: "+298",
    name: "Faroe Islands",
    nameTR: "Faroe Adaları",
  },
  { iso: "FR", phoneCode: "+33", name: "France", nameTR: "Fransa" },
  { iso: "GA", phoneCode: "+241", name: "Gabon", nameTR: "Gabon" },
  { iso: "GD", phoneCode: "+1-473", name: "Grenada", nameTR: "Grenada" },
  { iso: "GE", phoneCode: "+995", name: "Georgia", nameTR: "Gürcistan" },
  { iso: "GH", phoneCode: "+233", name: "Ghana", nameTR: "Gana" },
  { iso: "GM", phoneCode: "+220", name: "Gambia", nameTR: "Gambiya" },
  { iso: "GN", phoneCode: "+224", name: "Guinea", nameTR: "Gine" },
  {
    iso: "GQ",
    phoneCode: "+240",
    name: "Equatorial Guinea",
    nameTR: "Ekvator Ginesi",
  },
  { iso: "GR", phoneCode: "+30", name: "Greece", nameTR: "Yunanistan" },
  { iso: "GT", phoneCode: "+502", name: "Guatemala", nameTR: "Guatemala" },
  { iso: "GU", phoneCode: "+1-671", name: "Guam", nameTR: "Guam" },
  {
    iso: "GW",
    phoneCode: "+245",
    name: "Guinea-Bissau",
    nameTR: "Gine-Bissau",
  },
  { iso: "GY", phoneCode: "+592", name: "Guyana", nameTR: "Guyana" },
  { iso: "HK", phoneCode: "+852", name: "Hong Kong", nameTR: "Hong Kong" },
  { iso: "HN", phoneCode: "+504", name: "Honduras", nameTR: "Honduras" },
  { iso: "HR", phoneCode: "+385", name: "Croatia", nameTR: "Hırvatistan" },
  {
    iso: "HT",
    phoneCode: "+509",
    name: "Republic of Haiti",
    nameTR: "Haiti Cumhuriyeti",
  },
  { iso: "HU", phoneCode: "+36", name: "Hungary", nameTR: "Macaristan" },
  { iso: "ID", phoneCode: "+62", name: "Indonesia", nameTR: "Endonezya" },
  { iso: "IE", phoneCode: "+353", name: "Ireland", nameTR: "İrlanda" },
  { iso: "IL", phoneCode: "+972", name: "Israel", nameTR: "İsrail" },
  { iso: "IN", phoneCode: "+91", name: "India", nameTR: "Hindistan" },
  { iso: "IQ", phoneCode: "+964", name: "Iraq", nameTR: "Irak" },
  { iso: "IR", phoneCode: "+98", name: "Iran", nameTR: "İran" },
  { iso: "IS", phoneCode: "+354", name: "Iceland", nameTR: "İzlanda" },
  { iso: "IT", phoneCode: "+39", name: "Italy", nameTR: "İtalya" },
  { iso: "JM", phoneCode: "+1-876", name: "Jamaica", nameTR: "Jamaika" },
  { iso: "JO", phoneCode: "+962", name: "Jordan", nameTR: "Ürdün" },
  { iso: "JP", phoneCode: "+81", name: "Japan", nameTR: "Japonya" },
  { iso: "KE", phoneCode: "+254", name: "Kenya", nameTR: "Kenya" },
  { iso: "KG", phoneCode: "+996", name: "Kyrgyzstan", nameTR: "Kırgızistan" },
  { iso: "KH", phoneCode: "+855", name: "Cambodia", nameTR: "Kamboçya" },
  { iso: "KI", phoneCode: "+686", name: "Kiribati", nameTR: "Kiribati" },
  { iso: "KM", phoneCode: "+269", name: "Comoros", nameTR: "Komorlar" },
  {
    iso: "KN",
    phoneCode: "+1-869",
    name: "Saint Kitts and Nevis",
    nameTR: "Saint Kitts ve Nevis",
  },
  { iso: "KP", phoneCode: "+850", name: "DPRK", nameTR: "Kuzey Kore" },
  { iso: "KR", phoneCode: "+82", name: "South Korea", nameTR: "Güney Kore" },
  { iso: "KW", phoneCode: "+965", name: "Kuwait", nameTR: "Kuveyt" },
  {
    iso: "KY",
    phoneCode: "+1-345",
    name: "Cayman Islands",
    nameTR: "Cayman Adaları",
  },
  { iso: "KZ", phoneCode: "+7", name: "Kazakhstan", nameTR: "Kazakistan" },
  { iso: "LA", phoneCode: "+856", name: "Laos", nameTR: "Laos" },
  { iso: "LB", phoneCode: "+961", name: "Lebanon", nameTR: "Lübnan" },
  {
    iso: "LC",
    phoneCode: "+1-758",
    name: "Saint Lucia",
    nameTR: "Saint Lucia",
  },
  {
    iso: "LI",
    phoneCode: "+423",
    name: "Liechtenstein",
    nameTR: "Lihtenştayn",
  },
  { iso: "LK", phoneCode: "+94", name: "Sri Lanka", nameTR: "Sri Lanka" },
  { iso: "LR", phoneCode: "+231", name: "Liberia", nameTR: "Liberya" },
  { iso: "LS", phoneCode: "+266", name: "Lesotho", nameTR: "Lesotho" },
  { iso: "LT", phoneCode: "+370", name: "Lithuania", nameTR: "Litvanya" },
  { iso: "LU", phoneCode: "+352", name: "Luxembourg", nameTR: "Lüksemburg" },
  { iso: "LV", phoneCode: "+371", name: "Latvia", nameTR: "Letonya" },
  { iso: "LY", phoneCode: "+218", name: "Libya", nameTR: "Libya" },
  { iso: "MA", phoneCode: "+212", name: "Morocco", nameTR: "Fas" },
  { iso: "MC", phoneCode: "+377", name: "Monaco", nameTR: "Monako" },
  { iso: "MD", phoneCode: "+373", name: "Moldova", nameTR: "Moldova" },
  { iso: "ME", phoneCode: "+382", name: "Montenegro", nameTR: "Karadağ" },
  { iso: "MG", phoneCode: "+261", name: "Madagascar", nameTR: "Madagaskar" },
  {
    iso: "MH",
    phoneCode: "+692",
    name: "Marshall Islands",
    nameTR: "Marshall Adaları",
  },
  { iso: "MK", phoneCode: "+389", name: "Macedonia", nameTR: "Makedonya" },
  { iso: "ML", phoneCode: "+223", name: "Mali", nameTR: "Mali" },
  { iso: "MM", phoneCode: "+95", name: "Myanmar", nameTR: "Myanmar" },
  { iso: "MN", phoneCode: "+976", name: "Mongolia", nameTR: "Moğolistan" },
  { iso: "MR", phoneCode: "+222", name: "Mauritania", nameTR: "Moritanya" },
  { iso: "MS", phoneCode: "+1-664", name: "Montserrat", nameTR: "Montserrat" },
  { iso: "MT", phoneCode: "+356", name: "Malta", nameTR: "Malta" },
  { iso: "MU", phoneCode: "+230", name: "Mauritius", nameTR: "Mauritius" },
  { iso: "MV", phoneCode: "+960", name: "Maldives", nameTR: "Maldivler" },
  { iso: "MW", phoneCode: "+265", name: "Malawi", nameTR: "Malavi" },
  { iso: "MX", phoneCode: "+52", name: "Mexico", nameTR: "Meksika" },
  { iso: "MY", phoneCode: "+60", name: "Malaysia", nameTR: "Malezya" },
  { iso: "MZ", phoneCode: "+258", name: "Mozambique", nameTR: "Mozambik" },
  { iso: "NA", phoneCode: "+264", name: "Namibia", nameTR: "Namibya" },
  {
    iso: "NC",
    phoneCode: "+687",
    name: "New Caledonia",
    nameTR: "Yeni Kaledonya",
  },
  { iso: "NE", phoneCode: "+227", name: "Niger", nameTR: "Nijer" },
  { iso: "NG", phoneCode: "+234", name: "Nigeria", nameTR: "Nijerya" },
  { iso: "NI", phoneCode: "+505", name: "Nicaragua", nameTR: "Nikaragua" },
  { iso: "NL", phoneCode: "+31", name: "Netherlands", nameTR: "Hollanda" },
  { iso: "NO", phoneCode: "+47", name: "Norway", nameTR: "Norveç" },
  { iso: "NP", phoneCode: "+977", name: "Nepal", nameTR: "Nepal" },
  { iso: "NR", phoneCode: "+674", name: "Nauru", nameTR: "Nauru" },
  { iso: "NU", phoneCode: "+683", name: "Niue", nameTR: "Niue" },
  { iso: "NZ", phoneCode: "+64", name: "New Zealand", nameTR: "Yeni Zelanda" },
  { iso: "OM", phoneCode: "+968", name: "Oman", nameTR: "Umman" },
  { iso: "PA", phoneCode: "+507", name: "Panama", nameTR: "Panama" },
  { iso: "PE", phoneCode: "+51", name: "Peru", nameTR: "Peru" },
  {
    iso: "PF",
    phoneCode: "+689",
    name: "French Polynesia",
    nameTR: "Fransız Polinezyası",
  },
  {
    iso: "PG",
    phoneCode: "+675",
    name: "Papua New Guinea",
    nameTR: "Papua Yeni Gine",
  },
  { iso: "PH", phoneCode: "+63", name: "Philippines", nameTR: "Filipinler" },
  { iso: "PK", phoneCode: "+92", name: "Pakistan", nameTR: "Pakistan" },
  { iso: "PL", phoneCode: "+48", name: "Poland", nameTR: "Polonya" },
  { iso: "PR", phoneCode: "+1-787", name: "Puerto Rico", nameTR: "Porto Riko" },
  { iso: "PS", phoneCode: "+970", name: "Palestine", nameTR: "Filistin" },
  { iso: "PT", phoneCode: "+351", name: "Portugal", nameTR: "Portekiz" },
  { iso: "PW", phoneCode: "+680", name: "Palau", nameTR: "Palau" },
  { iso: "PY", phoneCode: "+595", name: "Paraguay", nameTR: "Paraguay" },
  { iso: "QA", phoneCode: "+974", name: "Qatar", nameTR: "Katar" },
  { iso: "RO", phoneCode: "+40", name: "Romania", nameTR: "Romanya" },
  { iso: "RS", phoneCode: "+381", name: "Serbia", nameTR: "Sırbistan" },
  { iso: "RU", phoneCode: "+7", name: "Russia", nameTR: "Rusya" },
  { iso: "RW", phoneCode: "+250", name: "Rwanda", nameTR: "Ruanda" },
  {
    iso: "SA",
    phoneCode: "+966",
    name: "Saudi Arabia",
    nameTR: "Suudi Arabistan",
  },
  {
    iso: "SB",
    phoneCode: "+677",
    name: "Solomon Islands",
    nameTR: "Solomon Adaları",
  },
  { iso: "SC", phoneCode: "+248", name: "Seychelles", nameTR: "Seyşeller" },
  { iso: "SD", phoneCode: "+249", name: "Sudan", nameTR: "Sudan" },
  { iso: "SE", phoneCode: "+46", name: "Sweden", nameTR: "İsveç" },
  { iso: "SG", phoneCode: "+65", name: "Singapore", nameTR: "Singapur" },
  { iso: "SI", phoneCode: "+386", name: "Slovenia", nameTR: "Slovenya" },
  { iso: "SK", phoneCode: "+421", name: "Slovakia", nameTR: "Slovakya" },
  {
    iso: "SL",
    phoneCode: "+232",
    name: "Sierra Leone",
    nameTR: "Sierra Leone",
  },
  { iso: "SM", phoneCode: "+378", name: "San Marino", nameTR: "San Marino" },
  { iso: "SN", phoneCode: "+221", name: "Senegal", nameTR: "Senegal" },
  { iso: "SO", phoneCode: "+252", name: "Somalia", nameTR: "Somali" },
  { iso: "SR", phoneCode: "+597", name: "Suriname", nameTR: "Surinam" },
  {
    iso: "ST",
    phoneCode: "+239",
    name: "Sao Tome and Principe",
    nameTR: "Sao Tome ve Principe",
  },
  { iso: "SV", phoneCode: "+503", name: "El Salvador", nameTR: "El Salvador" },
  { iso: "SY", phoneCode: "+963", name: "Syria", nameTR: "Suriye" },
  { iso: "SZ", phoneCode: "+268", name: "Eswatini", nameTR: "Esvatini" },
  { iso: "TD", phoneCode: "+235", name: "Chad", nameTR: "Çad" },
  { iso: "TG", phoneCode: "+228", name: "Togo", nameTR: "Togo" },
  { iso: "TH", phoneCode: "+66", name: "Thailand", nameTR: "Tayland" },
  { iso: "TJ", phoneCode: "+992", name: "Tajikistan", nameTR: "Tacikistan" },
  { iso: "TK", phoneCode: "+690", name: "Tokelau", nameTR: "Tokelau" },
  { iso: "TL", phoneCode: "+670", name: "Timor-Leste", nameTR: "Doğu Timor" },
  {
    iso: "TM",
    phoneCode: "+993",
    name: "Turkmenistan",
    nameTR: "Türkmenistan",
  },
  { iso: "TN", phoneCode: "+216", name: "Tunisia", nameTR: "Tunus" },
  { iso: "TO", phoneCode: "+676", name: "Tonga", nameTR: "Tonga" },
  { iso: "TR", phoneCode: "+90", name: "Turkey", nameTR: "Türkiye" },
  {
    iso: "TT",
    phoneCode: "+1-868",
    name: "Trinidad and Tobago",
    nameTR: "Trinidad ve Tobago",
  },
  { iso: "TV", phoneCode: "+688", name: "Tuvalu", nameTR: "Tuvalu" },
  { iso: "TW", phoneCode: "+886", name: "Taiwan", nameTR: "Tayvan" },
  { iso: "TZ", phoneCode: "+255", name: "Tanzania", nameTR: "Tanzanya" },
  { iso: "UA", phoneCode: "+380", name: "Ukraine", nameTR: "Ukrayna" },
  { iso: "UG", phoneCode: "+256", name: "Uganda", nameTR: "Uganda" },
  { iso: "US", phoneCode: "+1", name: "USA", nameTR: "ABD" },
  { iso: "UY", phoneCode: "+598", name: "Uruguay", nameTR: "Uruguay" },
  { iso: "UZ", phoneCode: "+998", name: "Uzbekistan", nameTR: "Özbekistan" },
  {
    iso: "VC",
    phoneCode: "+1-784",
    name: "Saint Vincent and the Grenadines",
    nameTR: "Saint Vincent ve Grenadinler",
  },
  { iso: "VE", phoneCode: "+58", name: "Venezuela", nameTR: "Venezuela" },
  {
    iso: "VG",
    phoneCode: "+1-284",
    name: "British Virgin Islands",
    nameTR: "Britanya Virjin Adaları",
  },
  {
    iso: "VI",
    phoneCode: "+1-340",
    name: "US Virgin Islands",
    nameTR: "ABD Virjin Adaları",
  },
  { iso: "VN", phoneCode: "+84", name: "Vietnam", nameTR: "Vietnam" },
  { iso: "VU", phoneCode: "+678", name: "Vanuatu", nameTR: "Vanuatu" },
  { iso: "WS", phoneCode: "+685", name: "Samoa", nameTR: "Samoa" },
  { iso: "YE", phoneCode: "+967", name: "Yemen", nameTR: "Yemen" },
  { iso: "ZA", phoneCode: "+27", name: "South Africa", nameTR: "Güney Afrika" },
  { iso: "ZM", phoneCode: "+260", name: "Zambia", nameTR: "Zambiya" },
  { iso: "ZW", phoneCode: "+263", name: "Zimbabwe", nameTR: "Zimbabve" },
];
