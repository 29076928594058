import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { AxiosResponse } from "axios";

export interface IProvider {
  id: number;
  providerId: string;
  providerName: string;
  max: number;
  min: number;
  time: string;
  description: string;
  type: number;
  typeName: string;
  imageUrl: string;
}

interface IBank {
  code: number;
  desc: string;
}

interface IPaymentError {
  code: number;
  domain: string;
  message: string;
}

interface IPaymentState {
  providerListLoading: "idle" | "pending";
  providers: IProvider[];
  banks: IBank[];
  error?: IPaymentError;
}

interface IProviderListResponse {
  data: IProvider[];
}

interface IBankListResponse {
  code: number;
  data: IBank[];
  message: string;
}

const initialState: IPaymentState = {
  providerListLoading: "idle",
  providers: [],
  banks: [],
  error: undefined,
};

interface IThunkApi {
  dispatch: AppDispatch;
  state: RootState;
  rejectWithValue: IPaymentError;
}

type IProviderListRequestPayload = "deposit" | "withdraw";

export const fetchPaymentProviders = createAsyncThunk<
  IProviderListResponse,
  IProviderListRequestPayload,
  IThunkApi
>("paymentProviders/fetch", async (paymentType, { rejectWithValue }) => {
  try {
    const res: AxiosResponse<IProviderListResponse> = await uniAxiosInstance(
      `/payment-api/${paymentType}/providers`,
    );

    if (res.status !== 200 || (res.data as unknown as IPaymentError).message) {
      return rejectWithValue(res.data as unknown as IPaymentError);
    } else {
      return res.data;
    }
  } catch (err) {
    return rejectWithValue({
      error: (err as Error).message,
    });
  }
});

export const fetchBanks = createAsyncThunk<
  IBankListResponse,
  undefined,
  IThunkApi
>("paymentBanks/fetch", async (_, { rejectWithValue }) => {
  try {
    const res: AxiosResponse<IBankListResponse> = await uniAxiosInstance(
      `payment-api/transferable-banks`,
    );

    if (res.status !== 200) {
      return rejectWithValue(res.data as unknown as IPaymentError);
    } else {
      return res.data;
    }
  } catch (err) {
    return rejectWithValue({
      error: (err as Error).message,
    });
  }
});

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentProviders.pending, (state) => {
      if (state.providerListLoading === "idle") {
        state.providerListLoading = "pending";
      }
    });
    builder.addCase(fetchPaymentProviders.fulfilled, (state, action) => {
      if (state.providerListLoading === "pending") {
        state.providerListLoading = "idle";
        if (action.payload) {
          state.providers = action.payload.data;
        }

        state.error = undefined;
      }
    });
    builder.addCase(fetchPaymentProviders.rejected, (state, action) => {
      if (state.providerListLoading === "pending") {
        state.providerListLoading = "idle";

        if (action.payload) {
          state.error = action.payload as IPaymentError;
        }
      }
    });
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      if (action.payload) {
        state.banks = action.payload.data;
      }
    });
  },
});

export default paymentSlice.reducer;

export const selectPaymentProviders = (state: RootState) =>
  state.payment.providers;
export const selectPaymentProviderListLoading = (state: RootState) =>
  state.payment.providerListLoading;
export const selectBanks = (state: RootState) => state.payment.banks;
