import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledCodeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const StyledSubmitButton = styled(Button)`
  font-size: 1.4rem;
  margin-top: 1rem;
`;
