import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { cmsAxiosInstance } from "../../utils/axiosInstances";
import { AxiosResponse } from "axios";

type IPromotionLeftMenu = { id: number; title: string; url: string }[];

interface IPromotion {
  id: number;
  title: string;
  title_second: string;
  position_order: number;
  design_type: number;
  url: string;
  short_description: string;
  category_id: number;
  html: string;
  category_title: string;
  image: string;
  leftMenu: IPromotionLeftMenu;
}

interface IPromotionCategory {
  id: number;
  position_order: number;
  title: string;
  url: string;
  promotions: IPromotion[];
}

interface IPromotionsState {
  promotionCategories?: IPromotionCategory[];
  promotionDetails?: IPromotion;
  detailLoading: "idle" | "pending";
  loading: "idle" | "pending";
  error?: IPromotionsError;
}

interface IRequestPayload {
  device: "desktop" | "mobile";
  footer: boolean;
  lang: string;
}

interface IDetailsRequestPayload extends IRequestPayload {
  leftMenu: boolean;
  footerLeftMenu: boolean;
  url: string;
}

interface IResponse {
  success: boolean;
  message: string;
  lang: string;
  details?: unknown;
  promotions: IPromotionCategory[];
  footer: unknown[];
}

interface IDetailsResponse {
  success: boolean;
  message: string;
  lang: string;
  detail: {
    id: number;
    title: string;
    title_second: string;
    position_order: number;
    design_type: number;
    url: string;
    short_description: string;
    category_id: number;
    html: string;
    category_title: string;
    image: string;
  };
  leftMenu: IPromotionLeftMenu;
  footer: unknown[];
}

interface IPromotionsError {
  error: Error;
  code?: number;
}

const initialState: IPromotionsState = {
  promotionCategories: [],
  promotionDetails: undefined,
  detailLoading: "idle",
  loading: "idle",
  error: undefined,
};

interface IThunkApi {
  dispatch: AppDispatch;
  state: RootState;
  rejectWithValue: IPromotionsError;
}

export const fetchPromotions = createAsyncThunk<
  IResponse,
  IRequestPayload,
  IThunkApi
>(
  "promotions/fetch",
  async (promotionsFetchData: IRequestPayload, { rejectWithValue }) => {
    try {
      const res: AxiosResponse<IResponse> = await cmsAxiosInstance(
        "/promotion/0",
        {
          method: "POST",
          data: promotionsFetchData,
        },
      );

      if (
        res.status !== 200 ||
        (res.data as unknown as IPromotionsError).error
      ) {
        window.scrollTo(0, 0);
        return rejectWithValue((res.data as unknown as IPromotionsError).error);
      } else {
        window.scrollTo(0, 0);
        return res.data as IResponse;
      }
    } catch (err) {
      return rejectWithValue({
        error: (err as Error).message,
      });
    }
  },
);

export const fetchPromotionDetails = createAsyncThunk<
  IDetailsResponse,
  IDetailsRequestPayload,
  IThunkApi
>(
  "promotions/fetchDetails",
  async (
    { url, ...promotionDetailsFetchData }: IDetailsRequestPayload,
    { rejectWithValue },
  ) => {
    try {
      const res: AxiosResponse<IDetailsResponse> = await cmsAxiosInstance(
        `/promotion/detail/${url}`,
        {
          method: "POST",
          data: promotionDetailsFetchData,
        },
      );

      if (
        res.status !== 200 ||
        (res.data as unknown as IPromotionsError).error
      ) {
        window.scrollTo(0, 0);
        return rejectWithValue((res.data as unknown as IPromotionsError).error);
      } else {
        window.scrollTo(0, 0);
        return res.data as IDetailsResponse;
      }
    } catch (err) {
      return rejectWithValue({
        error: (err as Error).message,
      });
    }
  },
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPromotions.pending, (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(fetchPromotions.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (action.payload) {
          state.promotionCategories = action.payload.promotions;
        }

        state.error = undefined;
      }
    });
    builder.addCase(fetchPromotions.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";

        if (action.payload) {
          state.error = action.payload as IPromotionsError;
        }
      }
    });
    builder.addCase(fetchPromotionDetails.pending, (state) => {
      if (state.detailLoading === "idle") {
        state.detailLoading = "pending";
      }
    });
    builder.addCase(fetchPromotionDetails.fulfilled, (state, action) => {
      if (state.detailLoading === "pending") {
        state.detailLoading = "idle";
        if (action.payload) {
          state.promotionDetails = {
            ...action.payload.detail,
            leftMenu: action.payload.leftMenu,
          };
        }

        state.error = undefined;
      }
    });
    builder.addCase(fetchPromotionDetails.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";

        if (action.payload) {
          state.error = action.payload as IPromotionsError;
        }
      }
    });
  },
});

export default promotionsSlice.reducer;

export const selectPromotionCategories = (
  state: RootState,
): IPromotionsState["promotionCategories"] =>
  state.promotions.promotionCategories;
export const selectPromotionDetails = (
  state: RootState,
): IPromotionsState["promotionDetails"] => state.promotions.promotionDetails;
