import { Box, Typography } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import RightMenuNavigation from "../../components/RightMenuNavigation/RightMenuNavigation";
import { uniTokens } from "../../theme/uniTokens";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { SportsBookBonus } from "./SportsBookBonus";
import { CasinoBonus } from "./CasinoBonus";
import { BonusHistory } from "./BonusHistory";

const data = [
  { title: "sport", to: "/bonus/sport" },
  { title: "casino", to: "/bonus/casino" },
  { title: "history", to: "/bonus/history" },
];

export const Bonuses = () => {
  const { t } = useCustomTranslation();
  const translatedData = data.map((item) => ({
    ...item,
    title: t(`bonusHistory.${item.title}`),
  }));

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: uniTokens.white,
        padding: "15px 0 0 0",
        margin: "24px 5px",
        minHeight: "80vh",
      })}
    >
      <Typography
        textAlign="center"
        sx={{ fontSize: 14, fontWeight: 900, mb: 1 }}
        variant="h1"
      >
        {t(`payments.payments`)}
      </Typography>
      <RightMenuNavigation data={translatedData} />
      <Routes>
        <Route path="/" element={<SportsBookBonus />} />
        <Route path="sport" element={<SportsBookBonus />} />
        <Route path="casino" element={<CasinoBonus />} />
        <Route path="history" element={<BonusHistory />} />
      </Routes>
    </Box>
  );
};
