import { Dispatch, FC, SetStateAction, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  StyledPaymentProviderImg,
  StyledPaymentProviderTitle,
  StyledPaymentInfoTitle,
  StyledPaymentInfoText,
} from "../../components/Payment/Payment.styled";

import {
  StyledDetailContainer,
  StyledDetailItem,
  StyledLinkContainer,
  StyledLink,
  StyledCurrencyContainer,
  StyledCurrency,
  StyledDetailForm,
  StyledDetailFormTitle,
  StyledDetailFormButton,
  StyledPaymentButton,
} from "../../components/Payment/PaymentProviderDetail.styled";
import {
  ButtonGroup,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { UniInput } from "../CustomComponents";
import {
  IProvider,
  fetchBanks,
  selectBanks,
} from "../../redux/slices/paymentSlice";
import { selectUser } from "../../redux/slices/authSlice";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { TFunction } from "i18next";

interface IWithdrawProviderDetailProps {
  selectedProvider: IProvider;
  setSelectedProvider: Dispatch<SetStateAction<IProvider | {}>>;
}

interface ChangeAction {
  payload: object;
}

interface WithdrawBankFormState {
  accountNumber: string;
  amount: number;
  bankTypes: string[];
  bank?: number;
  bankTypesMap: { [n: string]: number };
  iban: string;
  fullName: string;
  customerNote: string;
  tetherNetwork?: string;
  tetherNetworkTypesMap: { [n: string]: number };
  specialWithdrawOptionsValue: string;
}

const initialWithdrawBankFormState: WithdrawBankFormState = {
  accountNumber: "",
  amount: 0,
  bankTypes: [],
  bank: undefined,
  bankTypesMap: {},
  iban: "",
  fullName: "",
  customerNote: "",
  tetherNetwork: "",
  tetherNetworkTypesMap: {},
  specialWithdrawOptionsValue: "",
};

const specialWithdrawOptionsMap = {
  UNIBAHIS_OZEL_EXPRESS_PAY: 41,
  UNIBAHIS_OZEL_BANK_HAVALE: 43,
  UNIBAHIS_OZEL_EXPRESS_PAPARA: 40,
  UNIBAHIS_OZEL_EXPRESS_PAYFIX: 42,
  UNIBAHIS_OZEL_PARAZULA: 44,
};
const specialWithdrawOptions = [
  "UNIBAHIS_OZEL_EXPRESS_PAY",
  "UNIBAHIS_OZEL_BANK_HAVALE",
  "UNIBAHIS_OZEL_EXPRESS_PAPARA",
  "UNIBAHIS_OZEL_EXPRESS_PAYFIX",
  "UNIBAHIS_OZEL_PARAZULA",
];
const specialWithdrawTypeNames = {
  41: "UnibahisOzelPay",
  43: "UnibahisOzelBankHavale",
  40: "UnibahisOzelPapara",
  42: "UnibahisOzelPayfix",
  44: "UnibahisOzelParazula",
};

const depositBankFormReducer = (
  state: WithdrawBankFormState,
  action: ChangeAction,
): WithdrawBankFormState => {
  return { ...state, ...action.payload };
};

const BankPaymentForm: FC<{
  withDrawBankFormState: WithdrawBankFormState;
  withDrawBankFormDispatch: Dispatch<ChangeAction>;
  providerName: string;
  t: TFunction;
}> = ({ withDrawBankFormState, withDrawBankFormDispatch, providerName, t }) => (
  <FormGroup sx={{ margin: "1rem" }}>
    {providerName === "BANK_HAVALE" && (
      <>
        <FormControl>
          <InputLabel
            variant="outlined"
            sx={{
              fontSize: "1.6rem",
              marginLeft: "-1rem",
            }}
          >
            {t(`payments.bankType`)}
          </InputLabel>
          <Select
            sx={{ marginTop: "1rem", fontSize: "1.4rem" }}
            value={"" + withDrawBankFormState.bank}
            onChange={(event: SelectChangeEvent) =>
              withDrawBankFormDispatch({
                payload: { bank: event.target.value },
              })
            }
          >
            {Object.entries(withDrawBankFormState.bankTypesMap).map(
              ([bankName, value]) => (
                <MenuItem
                  sx={{ fontSize: "1.4rem" }}
                  key={value}
                  value={value as number}
                >
                  {bankName}
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
        <UniInput
          label={t(`payments.nameSurname`)}
          value={withDrawBankFormState.fullName}
        ></UniInput>
      </>
    )}
    {(providerName === "BANK_HAVALE" || providerName === "UNIBAHIS_OZEL") && (
      <UniInput
        label={t(`payments.customerNote`)}
        onChange={(value) =>
          withDrawBankFormDispatch({
            payload: {
              customerNote: value,
            },
          })
        }
      ></UniInput>
    )}
    <UniInput
      label={t(`payments.amount`)}
      value={"" + withDrawBankFormState.amount}
      onChange={(value) => {
        const replacedValue = value.replace(/\D/g, "");

        if (replacedValue) {
          withDrawBankFormDispatch({
            payload: { amount: +replacedValue },
          });
        }
      }}
    ></UniInput>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6">Deposit</Typography>
      <Typography variant="h6">{withDrawBankFormState.amount} ₺</Typography>
    </div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="h6">Total</Typography>
      <Typography variant="h6">{withDrawBankFormState.amount} ₺</Typography>
    </div>
  </FormGroup>
);
const WithdrawProviderDetail: FC<IWithdrawProviderDetailProps> = ({
  selectedProvider,
  setSelectedProvider,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    description,
    providerId,
    providerName,
    type,
    typeName,
    imageUrl,
    min,
    max,
  } = selectedProvider;
  const [withDrawBankFormState, withDrawBankFormDispatch] = useReducer(
    depositBankFormReducer,
    initialWithdrawBankFormState,
  );
  const user = useAppSelector(selectUser);
  const banks = useAppSelector(selectBanks);

  useEffect(() => {
    dispatch(fetchBanks());
  }, [dispatch]);

  useEffect(() => {
    const tetherNetworkTypesMap: WithdrawBankFormState["tetherNetworkTypesMap"] =
      {
        TRC20: 1,
        ERC20: 2,
      };
    const bankTypesMap: WithdrawBankFormState["bankTypesMap"] = banks.reduce(
      (prev, curr) => {
        return { ...prev, [curr.desc]: curr.code };
      },
      {},
    );

    const bankTypes = Object.keys(bankTypesMap);
    const tetherNetworkTypes = Object.keys(tetherNetworkTypesMap);

    withDrawBankFormDispatch({
      payload: {
        bankTypes,
        bankTypesMap,
        bank: bankTypesMap[bankTypes[0]],
        fullName: `${user?.name} ${user?.surname}`,
        tetherNetworkTypes,
        tetherNetworkTypesMap,
        tetherNetwork: tetherNetworkTypes[0],
      },
    });
  }, [banks.length, user, providerName]);

  const doManuelWithdrawCoin = async () => {
    const {
      accountNumber,
      amount,
      bank,
      bankTypesMap,
      customerNote,
      iban,
      fullName,
      tetherNetwork,
      tetherNetworkTypesMap,
      specialWithdrawOptionsValue,
    } = withDrawBankFormState;

    const data: Partial<WithdrawBankFormState> & Partial<IProvider> = {
      providerId: providerId,
      providerName: providerName,
      type: type,
      typeName: typeName,
    };

    let flag = false;

    if (providerName === "EXPRESS_PAPARA") {
      // TODO: add a small validation
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.accountNumber = accountNumber;
      data.fullName = fullName;
      data.amount = amount;
    } else if (providerName === "EXPRESS_PAYFIX") {
      // TODO: add a small validation
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.accountNumber = accountNumber;
      data.amount = amount;
    } else if (providerName === "EXPRESS_PAY") {
      // TODO: a small validator library can help
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.tetherNetwork = tetherNetworkTypesMap[tetherNetwork as string];
      data.accountNumber = accountNumber;
      data.fullName = fullName;
      data.amount = amount;
    } else if (providerName === "BITCOIN") {
      // TODO: a small validator library can help
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.accountNumber = accountNumber;
      data.fullName = fullName;
      data.amount = amount;
    } else if (providerName === "BANK_HAVALE") {
      // TODO: a small validator library can help
      // this.checkValidation("iban") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);
      // !iban.isValid(iban) && (flag = true);

      if (flag) {
        return;
      }

      data.bank = bankTypesMap[bank as number];
      // TODO: check the ibanNumber field not to break backend request contract
      data.iban = iban;
      data.fullName = fullName;
      data.amount = amount;
      data.customerNote = customerNote;
    } else if (providerName === "PARAZULA") {
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.accountNumber = accountNumber;
      data.fullName = fullName;
      data.amount = amount;
    } else if (providerName === "UNIBAHIS_OZEL") {
      // this.checkValidation("accountNumber") && (flag = true);
      // this.checkValidation("fullName") && (flag = true);
      // this.checkValidation("amount") && (flag = true);

      if (flag) {
        return;
      }

      data.accountNumber = accountNumber;
      data.fullName = fullName;
      data.amount = amount;
      data.providerId = specialWithdrawOptionsMap[specialWithdrawOptionsValue];
      data.typeName =
        specialWithdrawTypeNames[
          specialWithdrawOptionsMap[specialWithdrawOptionsValue]
        ];
      data.providerName = specialWithdrawOptionsValue;
      if (specialWithdrawOptionsMap[specialWithdrawOptionsValue] === 43)
        data.bank = bankTypesMap[bank];
    }

    const response: AxiosResponse<unknown> = await uniAxiosInstance(
      "payment-api/withdraw/do-manual",
      {
        method: "POST",
        data,
      },
    );

    if (response.data && response.data.error && response.data.code) {
      // TODO: show an error modal with the translation
      // t(`errorMessages.${response.data.code}`)
    } else if (
      response.data &&
      response.data.code === 200 &&
      response.data.message
    ) {
      alert(t(`payments.yourRequestHasBeenReceived`));
    }
  };
  const handleReturn = () => {
    setSelectedProvider({});
  };
  return (
    <StyledDetailContainer>
      <StyledDetailItem>
        <StyledLinkContainer>
          <ChevronLeftIcon
            sx={{ width: "20px", height: "20px", color: "#147b45" }}
          />
          <StyledLink onClick={handleReturn}>
            {t(`payments.changePaymentMethod`)}
          </StyledLink>
        </StyledLinkContainer>
        <StyledPaymentProviderImg src={imageUrl} />
        <StyledPaymentProviderTitle>{description}</StyledPaymentProviderTitle>
        <StyledCurrencyContainer>
          <StyledCurrency>
            <StyledPaymentInfoTitle>{t(`payments.min`)}</StyledPaymentInfoTitle>
            <StyledPaymentInfoText>{min} ₺</StyledPaymentInfoText>
          </StyledCurrency>
          <StyledCurrency>
            <StyledPaymentInfoTitle>{t(`payments.max`)}</StyledPaymentInfoTitle>
            <StyledPaymentInfoText>{max} ₺</StyledPaymentInfoText>
          </StyledCurrency>
        </StyledCurrencyContainer>
      </StyledDetailItem>
      <StyledDetailForm>
        <StyledDetailFormTitle>
          {t(`depositProviders.${providerName}`)}
        </StyledDetailFormTitle>
        {type === -1 && (
          <BankPaymentForm
            withDrawBankFormDispatch={withDrawBankFormDispatch}
            withDrawBankFormState={withDrawBankFormState}
            providerName={providerName}
            t={t}
          />
        )}
        <StyledDetailFormButton onClick={doManuelWithdrawCoin}>
          {t(`payments.withdrawMoney`)}
        </StyledDetailFormButton>
      </StyledDetailForm>
    </StyledDetailContainer>
  );
};

export default WithdrawProviderDetail;
