import styled from "styled-components";
import { uniTokens } from "../../theme/uniTokens";
import { Divider, List, ListItemText } from "@mui/material";

export const StyledFooterContainer = styled.div`
  background-color: #1a2c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  padding: 2rem;
  gap: 0rem;
`;

export const StyledFooterSiteMap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-left: 4rem;
  gap: 0rem;
  padding-bottom: 2rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: ${uniTokens.grey};
    max-width: 100%;
    margin: 0rem;
    padding: 0rem;
  }
`;

export const StyledFooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  margin-right: 2rem;
`;

export const StyledSocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
  margin-left: 2rem;
  align-items: center;
  padding-left: 2rem;
  padding-top: 1rem;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 33px;
    height: 33px;
    margin: 1rem;
    padding-left: 0rem;
`;
export const StyledSocialMediaIcon = styled.a`
  padding: 0.5rem;
  justify-content: center;
  text-align: center;
  wrap: wrap;
  padding-top: 1rem;
  padding-left: 0rem;
`;

export const StyledProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  padding: 2.3rem;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 2rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: row;
    background-color: ${uniTokens.grey};
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }
`;

export const StyledProviderHeader = styled.h2`
  font-size: 1.6rem;
  color: ${uniTokens.textSecondary};
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const StyledImg = styled.img`
  padding: 0.8rem;
`;

export const StyledSiteMapHeader = styled.h2`
  font-size: 1.4rem;
`;

export const StyledMidFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  padding: 0rem;
  max-width: 100%;
  flex-wrap: nowrap;
  margin: 1rem 2rem;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: ${uniTokens.grey};
    max-width: 100%;
    wrap: wrap;
  }
`;

export const StyledResponsibleGaming = styled.ul`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: ${uniTokens.white};
  list-style-type: none;
  gap: 1rem;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: 2rem;
  padding-top: 1rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: row;
    align-items: start;
    background-color: ${uniTokens.grey};
    max-width: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 16px 0;
    gap: 4px;
    line-height: 1.2;
    justify-content: flex-start;
    font-size: 1.4rem;
  }
`;

export const StyledResponsibleGamingItem = styled.li`
  font-size: 1.4rem;
  &:hover {
    text-decoration: underline;
  }
`;
export const StyledResponsibleGamingLink = styled.a`
  text-decoration: none;
  color: ${uniTokens.textSecondary};
  margin-right: 4px;
  font-size: 1.4rem;

  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 100%;
  }
`;

export const StyledDivider = styled(Divider)`
  background-color: ${uniTokens.background};
  height: 1px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 12px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-top: 12px;
  }
`;

export const StyledFooterLicense = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 75%;
  flex-wrap: wrap;
  margin-left: 2rem;
  padding-bottom: 4rem;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${uniTokens.text};
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    max-width: 100%;
    margin-right: 0rem;
  }
`;
export const StyledFooterLicenseText = styled.p`
  color: ${uniTokens.textSecondary};
  font-size: 1.4rem;
  margin: 0 auto;
  flex: 1;
  margin-left: 2rem;
  line-height: 1.5;
  padding-top: 3rem;
  text-align: left;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    text-align: center;
    max-width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    width: 100%;
    font-size: 1.4rem;
  }
`;

export const StyledFooterPayment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.down("md")} {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  }
`;
export const StyledLanguageChange = styled(List)`
  display: flex;
  flex-direction: column;
  color: ${uniTokens.white};
  font-size: 2rem;
`;

export const StyledAddictivePart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0rem;
  width: auto;
  flex-wrap: wrap;
  margin-left: 2rem;
  white-space: nowrap;
  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0rem;
    margin: 0rem;
    flex-wrap: nowrap;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  font-size: 1.4rem;
  font-weight: 700;
`;
