import { useState, useEffect, useContext } from "react";
import { ButtonGroup, Button, Menu, Box, Divider } from "@mui/material";
import { useAppSelector } from "../../redux/hooks";
import {
  StyledButtonContainer,
  StyledHeaderContainer,
  StyledLogo,
  StyledToolBar,
  StyledLanguageContainer,
  StyledMenuItem,
  StyledMidHeaderContainer,
  StyledLogRegButtonsContainer,
  StyledProfileButton,
  StyledLanguageChangeButton,
  StyledRegisterButton,
  StyledLoginButton,
} from "./Header.styled";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { UniModal } from "../CustomComponents";
import Login from "../Login/Login";
import unibahisStyledLogo from "../../assets/images/headerIcons/unibahis-logo.svg";
import sportsIcon from "../../assets/images/headerIcons/sports-icon.svg";
import liveSportsIcon from "../../assets/images/headerIcons/live-sports-temp.svg";
import casinoIcon from "../../assets/images/headerIcons/casino-icon.svg";
import liveCasinoIcon from "../../assets/images/headerIcons/casino-icon.svg";
import virtualSportsIcon from "../../assets/images/headerIcons/live-sports.svg";
import promotionsIcon from "../../assets/images/headerIcons/promotionsIcon.svg";
import trFlag from "../../assets/images/headerIcons/flagTR.svg";
import ukFlag from "../../assets/images/headerIcons/flagUK.svg";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import depositIcon from "../../assets/images/headerIcons/deposit-icon.svg";
import PersonIcon from "@mui/icons-material/Person";
import Badge from "@mui/material/Badge";
import { uniTokens } from "../../theme/uniTokens";
import { UniMoneyAmount } from "../CustomComponents";
import useIsMobile from "../../utils/hooks/useIsMobile";
import HeaderMobile from "./HeaderMobile";
import NavBarMobile from "./NavBarMobile";

import Registration from "../../routes/Registration/Registration";
import { StyledLink } from "./HeaderMobile.styled";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { logout } from "../../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import { ProfileMenuContext } from "../../ProfileMenuContext";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { RegistrationForm } from "../RegistrationForm/RegistrationForm";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    backgroundColor: "#1a2c38",
    color: uniTokens.white,
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Header: React.FC = () => {
  const [showRegistration, setShowRegistration] = useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const totalBalance = useAppSelector((state) => state.auth.user?.totalBalance);
  const casinoBonus = useAppSelector((state) => state.auth.user?.casinoBonus);
  const unreadCount = useAppSelector((state) => state.auth.user?.unread_count);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isRegistrationModalOpen, setRegistrationModalOpen] = useState(false);
  const { t, changeLanguage } = useCustomTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout() as any);
    navigate("/");
  };

  const menuItems = [
    {
      id: 1,
      title: t(`header.sports`),
      icon: sportsIcon,
      url: "/prematch",
    },
    {
      id: 2,
      title: t(`header.liveSports`),
      icon: liveSportsIcon,
      url: "/live-sport",
    },
    {
      id: 3,
      title: t(`header.casino`),
      icon: casinoIcon,
      url: "/casino",
    },
    {
      id: 4,
      title: t(`header.liveCasino`),
      icon: liveCasinoIcon,
      url: "/live-casino",
    },
    {
      id: 5,
      title: t(`header.virtualSports`),
      icon: virtualSportsIcon,
      url: "/virtual-sports",
    },
  ];

  useEffect(() => {
    if (isAuthenticated) {
      setLoginModalOpen(false);
    }
  }, [isAuthenticated]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: any) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const { setIsMenuOpen } = useContext(ProfileMenuContext);
  return isMobile ? (
    <>
      <HeaderMobile /> <NavBarMobile />
    </>
  ) : (
    <>
      <AppBar position="fixed" open={openDrawer}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openDrawer && { display: "none" }),
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="h6" noWrap component="div">
              <img src={unibahisStyledLogo} style={{ width: "120px" }} />
            </Typography>
            {isAuthenticated ? (
              <ButtonGroup
                variant="contained"
                aria-label="outlined primary button group"
                sx={{ gap: "0.2rem" }}
              >
                <StyledProfileButton>
                  <img src={depositIcon} alt="Yatırım yap" />
                  <Link
                    to="/balance/deposit"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    {t(`header.deposit`)}
                  </Link>
                </StyledProfileButton>
                <StyledProfileButton onClick={() => setIsMenuOpen(true)}>
                  <Badge
                    badgeContent={unreadCount}
                    color="secondary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      width: 23,
                      height: 23,
                      borderRadius: "50%",
                      fontWeight: 400,
                    }}
                  >
                    <PersonIcon fontSize="large" />
                  </Badge>
                  {t(`header.balance`)}
                  <UniMoneyAmount amount={totalBalance} />
                </StyledProfileButton>
                <StyledProfileButton>
                  <img src={promotionsIcon} alt={t(`header.bonus`)} />
                  {t(`header.bonus`)}

                  <UniMoneyAmount amount={casinoBonus} />
                </StyledProfileButton>
              </ButtonGroup>
            ) : (
              <StyledLogRegButtonsContainer style={{ fontSize: "1.4rem" }}>
                <StyledLoginButton
                  variant="contained"
                  sx={{
                    bgcolor: "primary.main",
                    fontSize: "1.3rem",
                  }}
                  onClick={() => setLoginModalOpen(true)}
                >
                  {t(`header.login`)}
                </StyledLoginButton>
                <StyledRegisterButton
                  variant="contained"
                  sx={{
                    backgroundColor: "#FDC300",
                    color: uniTokens.grey,
                    marginLeft: "10px",
                    border: "none",
                    fontSize: "1.3rem",
                  }}
                  onClick={() => setRegistrationModalOpen(true)}
                >
                  {t(`header.register`)}
                </StyledRegisterButton>
              </StyledLogRegButtonsContainer>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={openDrawer}>
        <DrawerHeader sx={{ justifyContent: "space-around" }}>
          <Button variant="outlined" color="info">
            <span style={{ fontSize: "1.1rem" }}>Sport</span>
          </Button>
          <Button variant="contained" color="secondary">
            <span style={{ fontSize: "1.1rem" }}>Kasino</span>
          </Button>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon sx={{ color: "#fff" }} fontSize="large" />
            ) : (
              <ChevronLeftIcon sx={{ color: "#fff" }} fontSize="large" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {["Kasino", "Spor", "Canlı Kasino", "Canlı Spor"].map(
            (text, index) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: openDrawer ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: openDrawer ? 3 : "auto",
                      justifyContent: "center",
                      color: uniTokens.white,
                    }}
                  >
                    {index % 2 === 0 ? (
                      <InboxIcon fontSize="large" />
                    ) : (
                      <MailIcon fontSize="large" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={text}
                    sx={{
                      opacity: openDrawer ? 1 : 0,
                      fontSize: "1.5rem !important",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ),
          )}
        </List>
        <Divider />
        <List>
          {["Hesabım", "Yatırım", "Çıkış"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openDrawer ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 3 : "auto",
                    justifyContent: "center",
                    color: uniTokens.white,
                  }}
                >
                  {index % 2 === 0 ? (
                    <InboxIcon fontSize="large" />
                  ) : (
                    <MailIcon fontSize="large" />
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={text}
                  sx={{
                    opacity: openDrawer ? 1 : 0,
                    fontSize: "1.5rem !important",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <UniModal
        open={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      >
        <Login />
      </UniModal>
      <UniModal
        open={isRegistrationModalOpen}
        onClose={() => setRegistrationModalOpen(false)}
      >
        <RegistrationForm />
      </UniModal>
    </>
  );
};

export default Header;
