import header from "./header";
import footer from "./footer";
import login from "./login";
import errorMessages from "./errorMessages";
import registration from "./registration";
import profileMenu from "./profileMenu";
import payments from "./payments";
import depositProviders from "./depositProviders";
import messageTemplates from "./messageTemplates";
import global from "./global";
import profile from "./profile";
import gameHistory from "./gameHistory";
import bonusHistory from "./bonusHistory";

const en = {
  header,
  footer,
  login,
  errorMessages,
  profileMenu,
  registration,
  payments,
  depositProviders,
  messageTemplates,
  global,
  profile,
  gameHistory,
  bonusHistory,
};
export default en;
