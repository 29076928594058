const messageTemplates = {
  welcome: {
    subject: "Welcome to Unibahis",
    message:
      "Welcome to the World Giant Unibahis!\n" +
      "\n" +
      "You will have a real, safe and privileged betting experience at Unibahis, prepared by bookmakers for bettors!\n" +
      "\n" +
      "Invest now, get 200% Welcome Bonus, start earning!",
  },
  FreeSpinsFinished: {
    subject: "Your Free Spins Have Been Finished",
    message:
      "Dear Member\n" +
      "\n" +
      "Your free spins defined in your account within the scope of the campaign has been finished.\n" +
      "\n" +
      "You can continue to play with your main balance.",
  },
  CasinoBonusFinished: {
    subject: "Your Bonus Balance Has Been Finished",
    message:
      "Dear Member\n" +
      "\n" +
      "Your bonus defined in your account within the scope of the campaign has been finished.\n" +
      "\n" +
      "You can continue to bet with your main balance.",
  },
  CasinoBonusExpired: {
    subject: "Your Bonus Balance Has Been Expired",
    message:
      "Dear Member\n" +
      "\n" +
      "Your bonus has expired and has been removed from your balance.\n" +
      "\n" +
      "You can continue to bet with your main balance.",
  },
  SportsBookBonusExpired: {
    subject: "Your Bonus Balance Has Been Expired",
    message:
      "Dear Member\n" +
      "\n" +
      "Your bonus has expired and has been removed from your balance.\n" +
      "\n" +
      "You can continue to bet with your main balance.",
  },
};

export default messageTemplates;
