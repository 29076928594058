import { FC, Fragment, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchPromotions,
  selectPromotionCategories,
} from "../redux/slices/promotionsSlice";
import {
  StyledPromotionsCategoryTitle,
  StyledPromotionsContainer,
  StyledPromotion,
  StyledPromotionImage,
  StyledPromotionsPageWrapper,
  StyledPromotionTextContent,
  StyledPromotionTitle,
  StyledPromotionDescription,
} from "../components/Promotions/Promotions.styled";
import { StyledRightSideWrapper } from "./StaticContentPage";

const Promotions: FC = () => {
  const dispatch = useAppDispatch();
  const promotionCategories = useAppSelector(selectPromotionCategories);
  // Filtered the categories to have promotions
  const validPromotionCategories = promotionCategories?.filter(
    (category) => category.promotions.length,
  );

  useEffect(() => {
    dispatch(
      fetchPromotions({
        device: "desktop",
        footer: false,
        lang: "tr",
      }),
    );
  }, [dispatch]);

  return (
    <StyledPromotionsPageWrapper>
      <StyledPromotionsContainer>
        {validPromotionCategories?.map((category) => (
          <Fragment key={category.id}>
            <StyledPromotionsCategoryTitle>
              {category.title}
            </StyledPromotionsCategoryTitle>
            <div>
              {category.promotions.map((promotion) => (
                <StyledPromotion
                  className="promotion-box"
                  to={`detail/${category.url}/${promotion.url}`}
                >
                  <StyledPromotionImage src={promotion.image} />
                  <StyledPromotionTextContent>
                    <StyledPromotionTitle>
                      {promotion.title}
                    </StyledPromotionTitle>
                    <StyledPromotionDescription>
                      {promotion.title_second}
                    </StyledPromotionDescription>
                  </StyledPromotionTextContent>
                </StyledPromotion>
              ))}
            </div>
          </Fragment>
        ))}
      </StyledPromotionsContainer>
      <StyledRightSideWrapper className="general-info-right-side-wrapper">
        <div className="general-info-right-side-box">
          <div className="general-info-right-side-item">
            <h3 className="general-info-right-side-title">Destek ve Yardım</h3>
          </div>
          <div className="general-info-right-side-item">
            <a
              className="general-info-right-side-link"
              href="/general-info/guvenlik-bilgileri"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7.5 10.375C7.5 10.4437 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4437 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z"
                  fill="#3AAA35"
                ></path>
              </svg>
              Unibahis Güvenlik Merkezi
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.10046 0H0V14H14V9H12V12H2L2 2H5.10046V0ZM14 0V6L12 6V3.55598L6.92467 8.63148L5.51043 7.21729L10.7275 2H8.10046V0H14Z"
                  fill="#999999"
                ></path>
              </svg>
            </a>
          </div>
          <div className="general-info-right-side-item">
            <div className="general-info-right-side-link">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 0H0V11H9L12 14V11H14V0Z" fill="#3AAA35"></path>
              </svg>
              Canlı Destek
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.10046 0H0V14H14V9H12V12H2L2 2H5.10046V0ZM14 0V6L12 6V3.55598L6.92467 8.63148L5.51043 7.21729L10.7275 2H8.10046V0H14Z"
                  fill="#999999"
                ></path>
              </svg>
            </div>
          </div>
          <div className="general-info-right-side-item">
            <p className="general-info-right-side-text">
              Unibahis Canlı Destek 7/24 Hizmet Vermektedir.
            </p>
          </div>
        </div>
      </StyledRightSideWrapper>
    </StyledPromotionsPageWrapper>
  );
};
export default Promotions;
