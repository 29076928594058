import React, { useEffect } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import styled from "styled-components";
import { UniModal } from "../../components/CustomComponents";
import { Box, Button, Typography } from "@mui/material";

const StyledPendingContainer = styled.div`
  color: #000000;
  padding: 20px;
  background-color: #fff;
`;

const StyledPendingHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  font-size: 10px;
  font-weight: 400;
  border-top: 1px solid #e8e8e8;
`;

const StyledPendingContentContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  margin: 0 20px;
`;

const StyledPendingContainerColumn = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  text-transform: uppercase;
`;

const PaymentPendings = () => {
  const { t } = useCustomTranslation();
  const [data, setData] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");
  useEffect(() => {
    uniAxiosInstance("payment-api/get", { method: "post", data: {} }).then(
      (res) => {
        setData(res.data.data);
      },
    );
  }, []);

  const cancelPending = (item) => {
    const requestBody = {
      id: item.id,
      operationType: item.operationType,
    };

    uniAxiosInstance("payment-api/update", {
      method: "post",
      data: requestBody,
    })
      .then((res) => {
        setData([...data.filter((pend) => pend.id !== item.id)]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };
  return (
    <>
      {data.length > 0 ? (
        <>
          <StyledPendingContainer>
            <StyledPendingHeaderContainer>
              <StyledPendingContainerColumn>
                {t(`payments.date_uppercase`)}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {t(`payments.amount_uppercase`)}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {t(`payments.operationType`)}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {t(`payments.paymentType`)}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {t(`payments.cancel`)}
              </StyledPendingContainerColumn>
            </StyledPendingHeaderContainer>
          </StyledPendingContainer>
          {data.map((item) => (
            <StyledPendingContentContainer key={item.id}>
              <StyledPendingContainerColumn>
                {item.date}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {item.amount} ₺
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {t(`payments.${item.operationType.toLowerCase()}`) ||
                  t(`payments.${item.state.toLowerCase()}`) ||
                  item.operationType ||
                  item.state}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                {item.paymentType}
              </StyledPendingContainerColumn>
              <StyledPendingContainerColumn>
                <div className="balance-history-cancel">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setSelectedItem(item);
                      setIsModalOpen(true);
                    }}
                  >
                    {t(`payments.cancel`)}
                  </Button>
                </div>
              </StyledPendingContainerColumn>
            </StyledPendingContentContainer>
          ))}
        </>
      ) : (
        <Box mt={4} textAlign="center">
          <Typography fontSize={14}>{t(`payments.noTransaction`)}</Typography>
        </Box>
      )}
      <UniModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Typography fontSize={14}>
          {selectedItem.operationType === "WITHDRAW"
            ? t(`payments.cancelWithdrawPopupTitleMessage`)
            : t(`payments.cancelDepositPopupTitleMessage`)}
        </Typography>

        <Box display="flex" justifyContent="center" gap={2} mt={2}>
          <Button
            sx={{
              fontSize: "1.3rem",
              padding: "8px 32px",
            }}
            variant="outlined"
            color="inherit"
            onClick={() => setIsModalOpen(false)}
          >
            Hayir
          </Button>
          <Button
            sx={{
              fontSize: "1.3rem",
              padding: "8px 32px",
            }}
            variant="contained"
            onClick={() => cancelPending(selectedItem)}
          >
            Evet
          </Button>
        </Box>
      </UniModal>
    </>
  );
};

export default PaymentPendings;
