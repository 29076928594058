const footer = {
  sport: "Sportsbook",
  casino: "Casino",
  liveCasino: "Live Casino",
  bonus: "Bonuses",
  unibahis: "Unibahis",
  support: "Support",
  addictiveText: "Gambling can be addictive. Please play responsibly.",
  licenceText:
    "www.unibahis.com is operated by the United Betting Group B.V. registered under No. 164032 at Curacao, Abraham Mendez Chumaceiro Boulevard 03. This website is licensed and regulated by Curaçao eGaming (Curaçao license No. 1668 JAZ issued by Curaçao eGaming).",
  responsibleGambling: "Responsible Gambling",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  securityInformation: "Security Information",
  cookies: "Cookies",
  providers: "Providers",
  paymentMethods: "Secure Payment Methods",
  language: "Change Language",
};
export default footer;
