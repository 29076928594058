const messageTemplates = {
  welcome: {
    subject: "Unibahis'e Hoşgeldiniz",
    message:
      "Dünya Devi Unibahis’e Hoşgeldin!\n" +
      "\n" +
      "Bahisçiler için, bahisçiler tarafından hazırlanan Unibahis’te gerçek, güvenli ve ayrıcalıklı bir bahis deneyimi yaşayacaksın!\n" +
      "\n" +
      "Hemen yatırım yap, %200 Hoşgeldin Bonusunu al, kazanmaya başla!",
  },
  FreeSpinsFinished: {
    subject: "Free Spin Hakkınız Bitti",
    message:
      "Sayın Üyemiz\n" +
      "\n" +
      "Kampanya kapsamında hesabınıza tanımlanan free spinler tükenmiştir.\n" +
      "\n" +
      "Anaparanız ile oynamaya devam edebilirsiniz.",
  },
  CasinoBonusFinished: {
    subject: "Bonus Bakiyeniz Bitti",
    message:
      "Sayın Üyemiz\n" +
      "\n" +
      "Kampanya kapsamında hesabınıza tanımlanan bonusunuz tükenmiştir.\n" +
      "\n" +
      "Anaparanız ile bahis almaya devam edebilirsiniz.",
  },
  CasinoBonusExpired: {
    subject: "Bonus Bakiyenizin Süresi Doldu",
    message:
      "Sayın Üyemiz\n" +
      "\n" +
      "Hesabınıza tanımlanan bonusun süresi dolduğundan dolayı bonusunuz iptal edilmiştir." +
      "\n" +
      "Anaparanız ile bahis almaya devam edebilirsiniz.",
  },
  SportsBookBonusExpired: {
    subject: "Bonus Bakiyenizin Süresi Doldu",
    message:
      "Sayın Üyemiz\n" +
      "\n" +
      "Hesabınıza tanımlanan bonusun süresi dolduğundan dolayı bonusunuz iptal edilmiştir." +
      "\n" +
      "Anaparanız ile bahis almaya devam edebilirsiniz.",
  },
};

export default messageTemplates;
