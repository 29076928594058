import React, { useState, useEffect } from "react";
import "./Counter.css";

export const Counter = () => {
  const totalTime = 180;
  const circleRadius = 40;
  const [remainingTime, setRemainingTime] = useState(totalTime);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const id = setInterval(updateCountdown, 1000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, []);

  const updateCountdown = () => {
    setRemainingTime((prevRemainingTime) => {
      if (prevRemainingTime <= 1) {
        clearInterval(intervalId as unknown as number); // Cast intervalId to number
        return 0;
      }
      return prevRemainingTime - 1;
    });
  };

  const calculateDashArray = () => {
    const circumference = 2 * Math.PI * circleRadius;
    const percentageRemaining = (remainingTime / totalTime) * 100;
    return `${(circumference * percentageRemaining) / 100} ${circumference}`;
  };

  return (
    <div className="counter">
      <div className="circle">
        <svg className="svg" width="100" height="100">
          <circle className="circle-bg" r="40" cx="50" cy="50"></circle>
          <circle
            className="circle-progress"
            r="40"
            cx="50"
            cy="50"
            style={{
              strokeDasharray: calculateDashArray(),
            }}
          ></circle>
          <text
            className="counter-text"
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {`${Math.floor(remainingTime / 60)
              .toString()
              .padStart(2, "0")}:${(remainingTime % 60)
              .toString()
              .padStart(2, "0")}`}
          </text>
        </svg>
      </div>
    </div>
  );
};
