import "./banner.css";
import XIcon from "@mui/icons-material/X";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import kasinoImage from "../../assets/images/kasino-banner.jpg";
export const Banner = () => {
  return (
    <div className="container">
      <div className="left-side">
        <h3>Play Smarter</h3>
        <button>Register Instantly</button>
        <div className="text">
          <hr />
          <h3>OR</h3>
          <hr />
        </div>
        <div className="accounts-contianer">
          <ul>
            <li>
              <a href="#">
                <GoogleIcon
                  sx={{
                    fontSize: "4rem",
                    backgroundColor: "lightgrey",
                    borderRadius: "10px",
                  }}
                />
              </a>
            </li>
            <li>
              <a href="">
                <FacebookIcon
                  sx={{
                    fontSize: "4rem",
                    backgroundColor: "lightgrey",
                    borderRadius: "12px",
                    padding: "1rem",
                  }}
                />
              </a>
            </li>
            <li>
              <a href="">
                <XIcon
                  sx={{
                    fontSize: "4rem",
                    backgroundColor: "lightgrey",
                    borderRadius: "12px",
                    padding: "1rem",
                  }}
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="right-side">
        <img src={kasinoImage} alt="casino" />
      </div>
    </div>
  );
};
