import "./kasino.css";
import kasinoImage1 from "../../assets/images/kasino-promotions1.jpg";
import kasinoImage2 from "../../assets/images/kasino-promotions2.jpg";
import CasinoIcon from "@mui/icons-material/Casino";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
export const KasinoPromotions = () => {
  return (
    <div className="promotions-container">
      <div className="promotions">
        <h2>
          <CasinoIcon />
          Casino
        </h2>
        <img src={kasinoImage1} alt="kasino image 1" />
        <h3>Leading Online Casino</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed dolorem
          voluptatum quia, quaerat iusto sunt saepe officia quis eaque
          repudiandae cupiditate, ipsa optio rerum at dolore repellat id magnam
          totam, quos perspiciatis quasi? Nobis pariatur iure expedita excepturi
          nostrum repellendus, accusantium vel ratione rem hic dicta numquam
          consequuntur eveniet eligendi.
        </p>
        <button>Go to Casino</button>
      </div>
      <div className="promotions">
        <h2>
          <SportsBasketballIcon />
          Sports
        </h2>
        <img src={kasinoImage2} alt="kasino image 2" />
        <h3>Best Sports Betting Online</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed dolorem
          voluptatum quia, quaerat iusto sunt saepe officia quis eaque
          repudiandae cupiditate, ipsa optio rerum at dolore repellat id magnam
          totam, quos perspiciatis quasi? Nobis pariatur iure expedita excepturi
          nostrum repellendus, accusantium vel ratione rem hic dicta numquam
          consequuntur eveniet eligendi.
        </p>
        <button>Go to Sportsbook</button>
      </div>
    </div>
  );
};
