import styled from "styled-components";
import { Menu, Toolbar, MenuItem, Button } from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";
import { Link } from "react-router-dom";

export const StyledHeaderContainer = styled.div`
  background-color: ${uniTokens.green};
  display: flex;
  align-items: center;
  padding: 10px;
  color: ${uniTokens.white};
  justify-content: space-between;
  max-width: 100vw;
  overflow: auto;
`;

export const StyledToolBar = styled(Toolbar)`
  display: flex;
  justify-content: end;
  background-color: ${uniTokens.green};
  margin: 0px;
  padding: 0;
  color: ${uniTokens.white};
`;

export const StyledLogo = styled.img`
  display: flex;
  justify-content: start;
  color: ${uniTokens.white};
  max-width: 120px;
`;

export const StyledMenuContainer = styled(Menu)`
  .MuiPaper-root {
    background-color: ${uniTokens.green};
    color: ${uniTokens.white};
  }
`;

export const StyledButtonContainer = styled.span`
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0;
  margin: 0 0 0 4px;
  &:hover {
    //
  }
`;

export const StyledLogRegButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin: 10px;

  &:hover {
    brightness: 0.9;

`;
export const StyledLanguageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  padding: 0;
  color: ${uniTokens.white};
`;

export const StyledMenuItem = styled(Link)<{ isPromotion?: boolean }>`
  color: ${uniTokens.white};
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin: 0 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover {
    //
  }

  img {
    ${(props) => props.theme.breakpoints.down("md")} {
      display: ${(props) => {
        return !props.isPromotion ? "none" : "block";
      }};
    }
  }

  span {
    white-space: nowrap;
    ${(props) => props.theme.breakpoints.down("md")} {
      display: ${(props) => {
        return props.isPromotion ? "none" : "block";
      }};
    }
  }
`;

export const StyledLanguageChangeButton = styled(Link)`
  color: ${uniTokens.white};
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0;
  margin: 0 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const StyledMidHeaderContainer = styled(Toolbar)`
  background-color: ${uniTokens.green};
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${uniTokens.grey};
`;

export const StyledProfileButton = styled(Button)`
  display: flex;
  line-height: 1.2rem;
  font-size: 1.2rem;
  gap: 0.2rem;
  align-items: center;
  text-transform: uppercase !important;
  padding: 0.9rem;
`;

export const StyledLoginButton = styled(Button)`
  &:hover {
    background-color: #fdc300;
  }
`;

export const StyledRegisterButton = styled(Button)`
  &:hover {
    background-color: #e5d01c;
  }
`;
