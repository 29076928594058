export enum InputTypes {
  USER_NAME = "username",
  PASSWORD = "password",
}

export interface ChangeAction {
  type: InputTypes;
  payload: string;
}

export interface FormState {
  username: string;
  password: string;
  usernameError: string;
  passwordError: string;
  isPasswordValid: boolean;
  isUsernameValid: boolean;
}

export interface LoginProps {
  //TODO: check if this is required or not
  setLoginModalOpen?: (isOpen: boolean) => void;
}
