const depositProviders = {
  EXPRESS_PAPARA: "Papara ile Para Yatırma",
  EXPRESS_PAY: "USDT(TRC20) ile Para yatırma",
  EXPRESS_COIN: "Kripto Para ile Para Yatırıma",
  EXPRESS_HAVALE: "Express Havale ile Para Yatırma",
  EXPRESS_CEP_BANK: "Cepbank ile Para Yatırma",
  EXPRESS_QR: "Fast QR ile Para Yatırma",
  EXPRESS_CREDIT_CARD: "Kredi Kartı ile Para Yatırma",
  EXPRESS_PAYFIX: "Payfix ile Para Yatırma",
  MINIPAY_HAVALE: "Havale min. 100 TL",
  BITCOIN:
    "Bitcoin ile hemen yatırım yapabilirsiniz. Ethereum hesabımızı öğrenmek ve ödeme yapmak için Canlı Destek'e bağlanabilirsiniz",
  ETHEREUM:
    "Ethereum ile hemen yatırım yapabilirsiniz. Ethereum hesabımızı öğrenmek ve ödeme yapmak için Canlı Destek'e bağlanabilirsiniz",
  RIPPLE:
    "Ripple ile hemen yatırım yapabilirsiniz. Ethereum hesabımızı öğrenmek ve ödeme yapmak için Canlı Destek'e bağlanabilirsiniz",
  BANK_HAVALE: "Banka Havalesi ile Para Yatırma",
  PARAZULA: "Parazula ile Para Yatırma",
  MEFETE: "Mefete ile Para Yatirma",
  UNIBAHIS_OZEL: "Unibahis Ozel Para Yatirma",
} as const;

export default depositProviders;
