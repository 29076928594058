const header = {
  help: "Yardım",
  termsAndConditions: "Hükümler ve Koşullar",
  login: "Giriş",
  register: "Kayıt Ol",
  sports: "Spor Bahisleri",
  liveSports: "Canlı Bahis",
  casino: "Casino",
  liveCasino: "Canlı Casino",
  virtualSports: "Sanal Sporlar",
  promotions: "Promosyonlar",
  language: "Diller",
  usernameError: "Kullanıcı Adı 4 ile 15 karakter arasinda izin verilmektedir.",
  passwordError: "Şifre 6 ile 15 karakter arasinda izin verilmektedir.",
  loginError: "Lütfen giriş bilgilerinizi kontrol edip tekrar deneyiniz.",
  deposit: "Yatırım",
  balance: "Bakiye",
  bonus: "Bonus",
};
export default header;
