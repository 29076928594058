import { FC } from "react";
import { UniInput } from "../../components/CustomComponents";
import { Typography, Select, MenuItem, InputLabel, Box } from "@mui/material";
import { countries } from "../../constants/countries";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import {
  StyledFormGroup,
  StyledHeader,
  StyledPhoneNumberContainer,
} from "./Registration.styled";
import { InputTypes } from "./Registration";
import { Label } from "@mui/icons-material";

interface RegistrationSecondPageProps {
  formChange: (type: InputTypes, value: string) => void;
  formState: RegistrationSecondPageState;
}

export interface RegistrationSecondPageState {
  firstName: string;
  address: string;
  city: string;
  email: string;
  country: string;
  mobileNumberPrefix: string;
  mobileNumber: string;
  currency: string;
  addressError: string;
  cityError: string;
}

const RegistrationSecondPage: FC<RegistrationSecondPageProps> = ({
  formChange,
  formState,
}) => {
  const { t } = useCustomTranslation();

  const handleInputChange = (type: InputTypes) => (value: string) => {
    if (value.length > 16) {
      return;
    }
    if (type === InputTypes.MOBILE_NUMBER) {
      value = value.replace(/\D/g, "");
    }
    formChange(type, value);
  };

  return (
    <div>
      <StyledFormGroup>
        <StyledHeader variant="h6">
          {t(`registration.hi`)} {formState.firstName}{" "}
          {t(`registration.greatHavingYouWithUs`)}
        </StyledHeader>
        <UniInput
          label={t(`registration.address`)}
          onChange={handleInputChange(InputTypes.ADDRESS)}
          value={formState.address}
          error={!!formState.addressError}
          helperText={formState.addressError}
        />
        <UniInput
          label={t(`registration.city`)}
          onChange={handleInputChange(InputTypes.CITY)}
          value={formState.city}
          error={!!formState.cityError}
          helperText={formState.cityError}
        />
        <Typography fontSize={14}>
          {t(`registration.countryOfResidence`)}
        </Typography>

        <Select
          sx={{ marginBottom: 2, width: "100%", height: "40px", fontSize: 12 }}
          onChange={(event) => {
            const selectedCountry = event.target.value as string;
            handleInputChange(InputTypes.COUNTRY)(selectedCountry);
            const selectedCountryData = countries.find(
              (country) => country.iso === selectedCountry,
            );
            if (selectedCountryData) {
              handleInputChange(InputTypes.MOBILE_NUMBER_PREFIX)(
                selectedCountryData.phoneCode,
              );
            } else {
              handleInputChange(InputTypes.MOBILE_NUMBER_PREFIX)("");
            }
          }}
          value={formState.country}
        >
          {countries.map((country) => (
            <MenuItem
              key={country.iso}
              value={country.iso}
              selected={country.iso === "TR"}
              sx={{ fontSize: 16 }}
            >
              {localStorage.getItem("language") === "tr"
                ? country.nameTR
                : country.name}
            </MenuItem>
          ))}
        </Select>
        <StyledPhoneNumberContainer>
          <Box display="flex">
            <UniInput
              InputProps={{
                readOnly: true,
              }}
              onChange={handleInputChange(InputTypes.MOBILE_NUMBER_PREFIX)}
              value={formState.mobileNumberPrefix}
              customStyle={{
                width: "25%",
                marginRight: "10px",
              }}
              label={t(`registration.mobileNumber`)}
            />
            <UniInput
              onChange={handleInputChange(InputTypes.MOBILE_NUMBER)}
              value={formState.mobileNumber}
              customStyle={{
                width: "100%",
                marginRight: "10px",
                marginLeft: 0,
              }}
            />
          </Box>
        </StyledPhoneNumberContainer>
        <div>
          <Typography fontSize={14}>{t(`registration.currency`)}</Typography>
          <Select
            defaultValue="TRY"
            sx={{ minWidth: "100%", height: "40px", fontSize: 16 }}
            onChange={(event) =>
              handleInputChange(InputTypes.CURRENCY)(
                event.target.value as string,
              )
            }
            value={formState.currency}
          >
            <MenuItem style={{ fontSize: "1.6rem" }} selected value="TRY">
              TRY
            </MenuItem>
          </Select>
        </div>
      </StyledFormGroup>
    </div>
  );
};

export default RegistrationSecondPage;
