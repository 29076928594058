const payments = {
  amount: "Amount",
  accountNumber: "Account Number",
  bankType: "Bank Type",
  customerNote: "Customer Note",
  payments: "Payments",
  iban: "IBAN",
  max: "Max.",
  min: "Min.",
  nameSurname: "Name Surname",
  selectNetwork: "Please Select Network",
  yourRequestHasBeenReceived: "Your Request Has Been Received",
  withdrawMoney: "Withdraw Money",
  withdrawAmount: "Withdraw Amount",
  paparaNumber: "Papara Number*",
  withdrawableAmount: "Withdrawable Amount",
  changePaymentMethod: "Change Payment Method",
  changeWithdrawMethod: "Change Withdraw Method",
  walletNumber: "Wallet Number*",
  bonusTitle: "Choose Bonus Type",
  bonusDescription: "If you have a deposit, please choose to receive a bonus",
  deposit: "Deposit",
  moneyDeposit: "Deposit Money",
  selectBonusType: "Select Bonus Type",
  total: "Total",
  noRollover: "NO ROLLOVER BONUS",
  rollever: "ROLLOVER BONUS",
  discountBonus: "DISCOUNT BONUS",
  firstDepositBonus: "FIRST DEPOSIT BONUS",
  sporBonus: "SPOR",
  casinoBonus: "CASINO",
  pragmaticBonus: "PRAGMATIC (CASINO)",
  trialBonus: "TRIAL BONUS",
  giftCodeBonus: "GIFT CODE",
  approve: "Approve",
  cancel: "Cancel",
  withdraw: "Withdraw",
  history: "History",
  pendings: "Pendings",
  lastTwoDays: "Last 2 Days",
  today: "Today",
  week: "Week",
  month: "Month",
  customRange: "Custom Range",
  date_uppercase: "DATE",
  amount_uppercase: "AMOUNT",
  balance_uppercase: "BALANCE",
  state: "STATE",
  operationType: "Operation Type",
  info: "Info",
  paymentType: "Payment Type",
  approved: "Approved",
  success: "Success",
  "correction credit": "Credit Correction",
  "correction debit": "Debit Correction",
  cancelDepositPopupTitleMessage:
    "Your deposit request will be cancelled, do you approve?",
  cancelDepositPopupSmallMessage: "",
  cancelDepositPopupSuccessMessage:
    "Your cancelation of deposit is successfully done.",
  cancelDepositPopupErrorMessage:
    "There was a problem with your cancelation of deposit request, please try again later.",

  cancelWithdrawPopupTitleMessage:
    "Your withdrawal request will be cancelled, do you approve?",
  cancelWithdrawPopupSmallMessage: "",
  cancelWithdrawPopupSuccessMessage:
    "Your cancelation of withdraw is successfully done.",
  cancelWithdrawPopupErrorMessage:
    "There was a problem with your cancelation of withdrawal request, please try again later.",
  noTransaction: "There is no transaction.",
  withdrawals: "Withdrawals",
  deposits: "Deposits",
  adjustments: "Adjustments",
  all: "All",

  cancelledbyclient: "Cancelled by Client",
} as const;

export default payments;
