const profileMenu = {
  deposit: "Yatırım",
  withdraw: "Çekim",
  history: "Geçmiş",
  pendings: "Bekleyenler",
  openBets: "Açık Bahisler",
  betHistory: "Bahis Geçmişi",
  casinoHistory: "Casino Geçmişi",
  gameHistory: "Oyun Geçmişi",
  sport: "Spor",
  casino: "Casino",
  bonusHistory: "Bonus Geçmişi",
  personalDetails: "Kişisel Detaylar",
  changePassword: "Şifre Değiştir",
  balanceManagement: "Bakiye Yönetimi",
  bonuses: "Bonuslar",
  myProfile: "Kişisel Bilgiler",
  messages: "Mesajlar",
  twoFa: "2FA",
};
export default profileMenu;
