import styled from "styled-components";
import { Backdrop, Box } from "@mui/material";

export const StyledModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
  background-color: ${({ theme: { palette } }) => palette.primary.main};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(6)};
  min-width: 320px;

  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
    height: 100%;
    padding 8rem 2rem 2rem 2rem;
    padding-top: 8rem;
    margin: 0rem;
  }
`;

export const StyledBackdrop = styled(Backdrop)`
  background-color: #000000bf;
`;
