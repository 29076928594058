import {
  Typography,
  Container,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { UniInput } from "../../components/CustomComponents";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { uniAxiosInstance } from "../../utils/axiosInstances";

const ResetPassword: React.FC = () => {
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const passwordRegex = /^(?!.*\s)(?=.*\d)(?=.*[a-zA-Z]).{8,19}$/;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [token, setToken] = useState("");
  const { t } = useCustomTranslation();
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const isPasswordValid = passwordRegex.test(passwordValue);
  const doPasswordsMatch = passwordValue === confirmPasswordValue;

  useEffect(() => {
    console.log("hi");
    setToken(window.location.search.split("=")[1]);
  }, []);

  return (
    <Container
      sx={{ textAlign: "center", marginTop: "2rem", marginBottom: "2rem" }}
    >
      <Typography variant="h4">{t("global.resetPassword")}</Typography>
      <UniInput
        label={t("global.newPassword")}
        type={showPassword ? "text" : "password"}
        value={passwordValue}
        onChange={(value: string) => setPasswordValue(value)}
        helperText={
          passwordValue && !isPasswordValid ? t(`global.invalidPassword`) : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <UniInput
        label={t("global.confirmNewPassword")}
        type={showConfirmPassword ? "text" : "password"}
        value={confirmPasswordValue}
        onChange={(value: string) => setConfirmPasswordValue(value)}
        helperText={
          confirmPasswordValue && !doPasswordsMatch
            ? t(`global.passWordNotMatch`)
            : ""
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ fontSize: "2rem" }}
        onClick={() => {
          uniAxiosInstance(
            `user-bff/update-password?token=${token}utm_source&password=${passwordValue}`,
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        {t("global.resetPassword")}
      </Button>
    </Container>
  );
};

export default ResetPassword;
