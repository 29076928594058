import { useTranslation } from "react-i18next";

const useCustomTranslation = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
  };
  return { t, i18n, changeLanguage };
};
export default useCustomTranslation;
