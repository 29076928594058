export const uniTokens = {
  grey: "#333333",
  textSecondary: "#BBB",
  black: "#000000",
  white: "#FFFFFF",
  green: "#147b45",
  red: "#FF0000",
  darkGreen: "#00531d",
  background: "#2A2A2A",
  backgroundSecondary: "#222222",
  backgroundHeaderButtonGreen: "#269053",
  backgroundHeaderButtonYellow: "#E6D01C",
  divider: "#3f3f3f",
  backgroundLightGrey: "#E8E8E8",
  backgroundSemiGrey: "#D8D8D8",
};
