const profileMenu = {
  deposit: "Deposit",
  withdraw: "Withdraw",
  history: "History",
  pendings: "Pendings",
  openBets: "Open Bets",
  betHistory: "Bet History",
  casinoHistory: "Casino History",
  gameHistory: "Game History",
  sport: "Sport",
  casino: "Casino",
  bonusHistory: "Bonus History",
  personalDetails: "Personal Details",
  changePassword: "Change Password",
  balanceManagement: "Balance Management",
  bonuses: "Bonuses",
  myProfile: "My Profile",
  messages: "Messages",
  twoFa: "2FA",
};
export default profileMenu;
