import styled from "styled-components";

export const StyledHelperTextWrapper = styled.span<{ color: string }>`
  margin-left: -13px;
  padding: 0;
  display: inline-block;
  white-space: normal;
  max-width: 100%;
  color: ${(props) => props.color};
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  min-height: 10px;
  color: #b1bad3;
`;
