import { FC, useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import {
  List,
  ListItemText,
  Typography,
  Collapse,
  Divider,
  Button,
} from "@mui/material";
import { ExpandLess, ExpandMore, Link } from "@mui/icons-material";
import {
  MessagesStyledContainer,
  StyledListItemButton,
  StyledMessagesHeader,
  UnauthenticatedMessage,
  StyledLoginButton,
} from "./Messages.styled";
import Login from "../../components/Login/Login";
import { UniModal } from "../../components/CustomComponents";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { uniTokens } from "../../theme/uniTokens";

const Messages: FC = () => {
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [newMessages, setMessages] = useState<any[]>([]);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const state = useAppSelector((state) => state.auth);
  const { t } = useCustomTranslation();
  const handleClick = (itemId: number) => {
    const isOpen = openItems.includes(itemId);
    if (isOpen) {
      setOpenItems(openItems.filter((id) => id !== itemId));
    } else {
      setOpenItems([...openItems, itemId]);
    }
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      uniAxiosInstance
        .get(`/messages-api/all-by-user?userId=${state.user?.userId}`)
        .then((res) => {
          setMessages(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <MessagesStyledContainer>
      {!state.isAuthenticated ? (
        <UnauthenticatedMessage>
          <StyledLoginButton
            onClick={() => {
              setLoginOpen(true);
            }}
          >
            {t(`login.notLogin`)}
          </StyledLoginButton>
        </UnauthenticatedMessage>
      ) : (
        <>
          <StyledMessagesHeader variant="h3">
            {t(`global.messages`)}
          </StyledMessagesHeader>
          <List>
            <Divider />
            {newMessages.map((message) => (
              <div>
                <StyledListItemButton
                  key={message.id}
                  onClick={() => {
                    handleClick(message.id);
                  }}
                >
                  <ListItemText>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "400",
                        color: `${uniTokens.textSecondary}`,
                        fontSize: "1.7rem",
                        fontFamily: "Roboto",
                      }}
                    >
                      {message.createDateTime}
                    </Typography>
                    <Typography variant="h5" sx={{ fontSize: "1.8rem" }}>
                      {message.subject === "welcome"
                        ? t(`messageTemplates.welcome.subject`)
                        : message.subject === "FreeSpinsFinished"
                        ? t(`messageTemplates.FreeSpinsFinished.subject`)
                        : message.subject === "CasinoBonusFinished"
                        ? t(`messageTemplates.CasinoBonusFinished.subject`)
                        : message.subject === "CasinoBonusExpired"
                        ? t(`messageTemplates.CasinoBonusExpired.subject`)
                        : message.subject === "SportsBookExpired"
                        ? t(`messageTemplates.SportsBookExpired.subject`)
                        : null}
                    </Typography>
                  </ListItemText>
                  {openItems.includes(message.id) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </StyledListItemButton>
                <Divider />
                <Collapse in={openItems.includes(message.id)}>
                  <Typography
                    sx={{
                      marginTop: "3rem",
                      marginBottom: "3rem",
                      color: `${uniTokens.text}`,
                      fontSize: "1.4rem",
                    }}
                  >
                    {message.subject === "welcome"
                      ? t(`messageTemplates.welcome.message`)
                      : message.subject === "FreeSpinsFinished"
                      ? t(`messageTemplates.FreeSpinsFinished.message`)
                      : message.subject === "CasinoBonusFinished"
                      ? t(`messageTemplates.CasinoBonusFinished.message`)
                      : message.subject === "CasinoBonusExpired"
                      ? t(`messageTemplates.CasinoBonusExpired.message`)
                      : message.subject === "SportsBookExpired"
                      ? t(`messageTemplates.SportsBookExpired.message`)
                      : // TODO: add other messages templates
                        null}
                  </Typography>
                </Collapse>
              </div>
            ))}
          </List>
        </>
      )}
      {loginOpen && (
        <UniModal open={loginOpen} onClose={() => setLoginOpen(false)}>
          <Login />
        </UniModal>
      )}
    </MessagesStyledContainer>
  );
};

export default Messages;
