const profile = {
  timeoutLimits: "Timeout Limits",
  realityChecks: "Reality Checks",
  depositLimits: "Deposit Limits",
  name: "Name",
  surname: "Surname",
  birthDate: "Birth Date",
  address: "Address",
  city: "City",
  country: "Country",
  userName: "Username",
  phoneNumber: "Phone Number",
  email: "E-mail",
  profile: "Profile",
  newPassword: "New Password",
  repeatNewPassword: "Repeat New Password",
  currentPassword: "Current Password",
  changePassword: "Change Password",
  successChangePasswordMessage: "Password Changed Successfully",
  errorChangePasswordMessage:
    "Please check your login information and try again.",
  successResetPasswordMessage: "Your password has been successfully changed.",
  successResetPasswordDetailFirst:
    "You can change your password anytime by going to the",
  successResetPasswordCTA: "My Details",
  successResetPasswordDetailSecond: "area of your account.",
  myProfile: "My Profile",
  updatePhone: "Update Phone Number",
  updatePhonePlaceholder: "New Phone Number (5XX XXX XX XX)",
  updatePhoneBtn: "Update",
  approve: "Approve",
  enterCode: "Enter code",
  resetPassword: "Reset Your Password",
  linkExpired: "Your link has expired.",
  linkExpiredDetail:
    "The link you used to reset your password has expired. Don’t worry, we can provide you a new link.",
  twoFa: "2FA",
  sendSMS: "Send SMS",
  activate2Fa: "Activate 2FA",
  deactivate2Fa: "Deactivate 2FA",
  resendSMS: "Resend SMS",
};

export default profile;
