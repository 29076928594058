import React, { FC } from "react";
import { TextField } from "@mui/material";

import { uniTokens } from "../../../theme/uniTokens";
import { StyledHelperTextWrapper } from "./Input.styled";
import { IInputProps } from "./Input.d";

const UniInput: FC<IInputProps> = ({
  id,
  label,
  onChange,
  value,
  variant = "outlined",
  size = "small",
  error = false,
  helperText = "",
  required = true,
  InputProps,
  type,
  customStyle,
  onKeyDown,
  placeholder = "",
  errorText,
}) => {
  const inputStyle = {
    border: "1px solid #bbb",
    fontSize: "1.6rem",
    borderRadius: "2px",
    ...customStyle,
    width: "100%",
    color: "#ffffff",
  };
  const inputLabelProps = {
    shrink: false,
    style: {
      color: "#8e99af",
      fontSize: "1.4rem",
      top: "-25px",
      padding: 0,
      transform: "unset",
      overflow: "visible",
    },
  };
  const handleNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "tel") {
      const validCharacters = "0123456789";
      if (!validCharacters.includes(event.key) && event.key !== "Backspace") {
        event.preventDefault();
      }
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      type={type}
      InputProps={{
        ...InputProps,
        sx: inputStyle,
      }}
      placeholder={placeholder}
      InputLabelProps={{ ...inputLabelProps }}
      sx={{
        marginTop: 4,
        width: customStyle?.width || "100%",
      }}
      onKeyDown={type === "tel" ? handleNumericInput : onKeyDown}
      value={value}
      onChange={(event) => {
        if (type === "tel" && isNaN(Number(event.target.value))) {
          return;
        }
        onChange?.(event.target.value);
      }}
      size={size}
      error={error}
      helperText={
        <StyledHelperTextWrapper
          color={error ? uniTokens.red : uniTokens.green}
        >
          {error ? errorText : helperText ? helperText : ""}
        </StyledHelperTextWrapper>
      }
      required={required}
    />
  );
};

export default UniInput;
