import {
  Button,
  Container,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { uniTokens } from "../../theme/uniTokens";

export const MessagesStyledContainer = styled.div`
  display: flex;
  background-color: ${uniTokens.white};
  flex-direction: column;
  height: 100%;
  width: 75%;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 0.5rem;
`;

export const StyledMessagesHeader = styled(Typography)`
  margin-top: 1rem;
  font-size: 4.8rem;
  font-family: UnibahisPro;
`;

export const StyledListItemButton = styled(ListItemButton)`
  display: flex;
  margin-left: 0;
  padding-left: 0;
`;

export const UnauthenticatedMessage = styled.div`
  display: flex;
  background-color: transparent;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50mm;
  color: ${uniTokens.textSecondary};
`;

export const StyledLoginButton = styled(Button)`
  color: grey;
  font-size: 1.5rem;
`;
