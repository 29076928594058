import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../store";
import { uniAxiosInstance } from "../../utils/axiosInstances";
import { AxiosResponse } from "axios";

interface IUser {
  userId: string;
  name: string;
  casinoBonus: number;
  sportsBookBonus: number;
  phoneCode: string;
  phoneNumber: string;
  email: string;
  lastLoginDate: string;
  agree: boolean;
  accessToken: string;
  address: string;
  country: string;
  city: string;
  birthDate: string;
  zendeskJwtToken: string;
  playerCategory: string;
  playerCategoryGroup: number;
  surname: string;
  username: string;
  totalBalance: number;
  currency_name: string;
  unread_count: number;
  error?: unknown;
  twoFactorAuthentication?: number;
}

interface IAuthState {
  user?: IUser;
  isAuthenticated: boolean;
  loading: "idle" | "pending";
  error?: unknown;
  twoFactorAuthentication?: boolean;
  username?: string;
}

interface IRequestPayload {
  deviceType?: "WEB" | "MOBILE";
  username: string;
  password: string;
  token?: string | null;
}

const initialUserData: IUser | undefined = JSON.parse(
  localStorage.getItem("uniRefreshData") || "{}",
).privateUser;

const initialState: IAuthState = {
  user: initialUserData,
  isAuthenticated: !!initialUserData,
  loading: "idle",
  error: undefined,
  twoFactorAuthentication: false,
};

interface IThunkApi {
  dispatch: AppDispatch;
  state: RootState;
  rejectWithValue: unknown;
}

export const login = createAsyncThunk<IUser, IRequestPayload, IThunkApi>(
  "auth/login",
  async (loginData: Partial<IRequestPayload>, { rejectWithValue }) => {
    console.log({ loginData });
    try {
      loginData.token &&
        uniAxiosInstance.interceptors.request.use(
          function (config) {
            if (loginData.token) {
              config.headers["g-captcha-token"] = loginData.token;
            }
            return config;
          },
          function (error) {
            delete error.config.headers["g-captcha-token"];
            return Promise.reject(error);
          },
        );
      const res: AxiosResponse<IUser> = await uniAxiosInstance(
        "user-bff/login",
        {
          method: "POST",
          data: {
            username: loginData.username,
            password: loginData.password,
            deviceType: "WEB",
          },
        },
      );

      if (res.data.error) {
        window.scrollTo(0, 0);
        return rejectWithValue(res.data.error);
      } else if (res.data.twoFactorAuthentication === 1) {
        return rejectWithValue(res.data);
      } else {
        window.scrollTo(0, 0);
        uniAxiosInstance.defaults.headers.common["Auth-Token"] =
          res.data.accessToken;
        uniAxiosInstance.defaults.headers.common["User-Id"] = res.data.userId;
        return res.data as IUser;
      }
    } catch (err) {
      return rejectWithValue({
        error: (err as Error).message,
      });
    }
  },
);

interface IRegisterRequestPayload {
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  address: string;
  city: string;
  country: string;
  phone: string;
  phone_code: string;
  currency_name: string;
  username: string;
  password: string;
  citizenshipNumber: string;
  agree: Boolean;
  site_id: string;
  birthDate: string;
  language: string;
}

export const register = createAsyncThunk<
  unknown,
  IRegisterRequestPayload,
  IThunkApi
>(
  "auth/register",
  async (
    registerData: IRegisterRequestPayload,
    { rejectWithValue, dispatch },
  ) => {
    const captchaToken = await (window as any).grecaptcha.execute(
      "6Let79gkAAAAAHqhd1z-ZujHBbJk_ApLY26l2cTz",
      { action: "submit" },
    );

    if (!captchaToken) {
      return rejectWithValue({
        error: { message: "Invalid reCAPTCHA token" },
      });
    }

    try {
      const res: AxiosResponse<{
        registeredStatus?: boolean;
        error?: unknown;
      }> = await uniAxiosInstance(
        `user-bff/register?siteLang=${localStorage.getItem("language")}`,
        {
          method: "POST",
          data: { ...registerData, deviceType: "WEB" },
        },
      );
      if (!res.data?.registeredStatus || res.data.error) {
        window.scrollTo(0, 0);
        return rejectWithValue(res.data.error);
      } else {
        try {
          const res: AxiosResponse<IUser> = await uniAxiosInstance(
            "user-bff/login",
            {
              method: "POST",
              data: {
                username: registerData.username,
                password: registerData.password,
                deviceType: "WEB",
              },
            },
          );

          if (res.data.error) {
            window.scrollTo(0, 0);
            return rejectWithValue(res.data.error);
          } else {
            window.scrollTo(0, 0);
            uniAxiosInstance.defaults.headers.common["Auth-Token"] =
              res.data.accessToken;
            uniAxiosInstance.defaults.headers.common["User-Id"] =
              res.data.userId;
            return res.data as IUser;
          }
        } catch (err) {
          return rejectWithValue({
            error: (err as Error).message,
          });
        }
        window.scrollTo(0, 0);
      }
    } catch (err) {
      return rejectWithValue({
        error: (err as Error).message,
      });
    }
  },
);

export const logout = createAsyncThunk<void, void, IThunkApi>(
  "auth/logout",
  async (_, { dispatch }) => {
    try {
      uniAxiosInstance.get("user-bff/log-out").then((res) => {
        console.log({ res });
      });
    } catch (err) {
      return;
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLoginErrors(state) {
      state.twoFactorAuthentication = false;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        if (action.payload) {
          state.user = action.payload;
          if (action.payload.twoFactorAuthentication === 1) {
            state.twoFactorAuthentication = true;
          }

          // store the user to persist on full page refreshes
          localStorage.setItem(
            "uniRefreshData",
            JSON.stringify({
              token: action.payload.accessToken,
              userId: action.payload.userId,
              privateUser: action.payload,
            }),
          );
        }
        state.isAuthenticated = true;
        state.error = undefined;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";

        if ((action.payload as IUser)?.twoFactorAuthentication === 1) {
          state.twoFactorAuthentication = true;
        }
        state.error = action.payload;
      }
    });
    builder.addCase(register.pending, (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(register.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
      }
    });
    builder.addCase(logout.pending, (state) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.user = undefined;
        state.isAuthenticated = false;
        state.error = undefined;
        localStorage.removeItem("uniRefreshData");
        window.location.replace("/");
      }
    });
  },
});

export default authSlice.reducer;
export const { resetLoginErrors } = authSlice.actions;
export const selectUser = (state: RootState) => state.auth.user;
