import styled from "styled-components";
import { Link } from "react-router-dom";
import { uniTokens } from "../../theme/uniTokens";

export const StyledPromotionsPageWrapper = styled.div`
  display: flex;
  padding: 1.6rem;
`;
export const StyledPromotionsContainer = styled.div`
  width: 75%;
  margin-right: 1.6rem;
  background-color: ${uniTokens.backgroundLightGrey};
`;

export const StyledPromotionsCategoryTitle = styled.div`
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  color: ${uniTokens.grey};
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 44px;
  background: ${uniTokens.backgroundSemiGrey};
`;

export const StyledPromotion = styled(Link)`
  display: flex;
  padding: 3.2rem;
  border-top: 1px solid ${uniTokens.backgroundLightGrey};
  background-color: ${uniTokens.white};
  color: ${uniTokens.black};
`;

export const StyledPromotionImage = styled.img`
  width: 6.4rem;
  height: 6.4rem;
`;

export const StyledPromotionTextContent = styled.div`
  padding-left: 1.6rem;
`;

export const StyledPromotionTitle = styled.h4`
  color: ${uniTokens.black};
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

export const StyledPromotionDescription = styled.div`
  color: ${uniTokens.black};
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
`;
