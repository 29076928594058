import styled from "styled-components";
import { Box, Button } from "@mui/material";

export const StyledLoginBox = styled(Box)`
  background: #eee;
  border-radius: 4px;
  padding: 15px;
  color: #000;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
  font-size: 1.4rem;
`;

export const StyledLoginButton = styled(Button)`
  color: #000;
  font-weight: 500;
  height: 44px;
  font-family: "UnibahisPro", serif;
  font-size: 20px;
  margin-top: 4px;
  &:hover {
    background-color: #57ff57
`;
