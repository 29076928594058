import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const BonusSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  padding: 15px 15px 0 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
`;

export const BonusSelectorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.44px;
  line-height: 150%;
`;

export const BonusSelectorTitle = styled.span`
  color: #000;
`;

export const BonusSelectorDescription = styled.span`
  color: #828282;
`;

export const BonusSelectorButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

export const BonusSelectorButton = styled.button`
  min-width: 100px;
  margin: 0 10px 10px 0;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  background: ${({ isSelected }) => (isSelected ? "#299b59" : "#4f4f4f")};
  color: #fafafa;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;

  ${(props) => props.theme.breakpoints.down("md")} {
    min-width: 100%;
  }

  :hover {
    background: #299b59;
  }
`;

export default function BonusSelector() {
  const { t } = useTranslation();
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [selectedSubButtonId, setSelectedSubButtonId] = useState(null);

  const buttonData = [
    {
      buttonId: 2,
      butonName: t("payments.noRollover"),
      buttonType: [1, 10],
    },
    { buttonId: 1, butonName: t("payments.rollever"), buttonType: [1] },
    {
      buttonId: 3,
      butonName: t("payments.discountBonus"),
      buttonType: [1, 2, 10],
    },
    {
      buttonId: 4,
      butonName: t("payments.firstDepositBonus"),
      buttonType: [1, 2],
    },
  ];

  const buttonTypes = [
    { buttonTypeId: 1, buttonTypeName: t("payments.sporBonus") },
    { buttonTypeId: 2, buttonTypeName: t("payments.casinoBonus") },
    { buttonTypeId: 10, buttonTypeName: t("payments.pragmaticBonus") },
  ];

  const handleClick = (id) => {
    setSelectedButtonId(id);
    setSelectedSubButtonId(null);
  };

  return (
    <BonusSelectorContainer>
      <BonusSelectorTextContainer>
        <BonusSelectorTitle>{t("payments.bonusTitle")}</BonusSelectorTitle>
        <BonusSelectorDescription>
          {t("payments.bonusDescription")}
        </BonusSelectorDescription>
      </BonusSelectorTextContainer>
      <BonusSelectorButtonContainer>
        {buttonData.map((button) => (
          <BonusSelectorButton
            isSelected={button.buttonId === selectedButtonId}
            key={button.buttonId}
            onClick={() => handleClick(button.buttonId)}
          >
            {button.butonName}
          </BonusSelectorButton>
        ))}
      </BonusSelectorButtonContainer>

      {selectedButtonId && (
        <>
          <BonusSelectorTitle>{t("payments.bonusTitle")}</BonusSelectorTitle>
          <BonusSelectorButtonContainer>
            {buttonData
              .find((button) => button.buttonId === selectedButtonId)
              .buttonType.map((typeId) => (
                <BonusSelectorButton
                  key={typeId}
                  onClick={() => setSelectedSubButtonId(typeId)}
                  isSelected={typeId === selectedSubButtonId}
                >
                  {
                    buttonTypes.find((type) => type.buttonTypeId === typeId)
                      .buttonTypeName
                  }
                </BonusSelectorButton>
              ))}
          </BonusSelectorButtonContainer>
        </>
      )}
    </BonusSelectorContainer>
  );
}
