const errorMessages = {
  4001: "Uzun süre işlem yapmadığınız için oturumunuz sonlandı. Lütfen tekrar giriş yapınız.",
  4002: "",
  4003: "",
  4004: "Çıkış yapıldı. Lütfen tekrar giriş yapın veya müşteri hizmetleri ile iletişime geçin.",
  4005: "",
  4009: "",
  4010: "",
  4011: "",
  4012: "",
  4013: "",
  4014: "",
  4015: "",
  4058: "Lütfen giriş bilgilerinizi kontrol edip tekrar deneyiniz.",
  4059: "Çok fazla hatalı üye no ve/veya şifre girdiniz! Canlı destek hattına bağlanarak konu ile alakalı bilgi alabilirsiniz.",
  4060: "Giriş talebiniz ile ilgili sorun oluştu. Lütfen canlı destek üzerinden bilgi alınız.",
  4061: "Aktif bonusunuz varken çekim yapamazsınız.",
  4062: "",
  4063: "",
  4064: "",
  4065: "",
  4066: "",
  4067: "",
  4068: "",
  4069: "",
  4070: "",
  4071: "",
  4072: "",
  4073: "",
  4074: "Bekleyen farklı bir yatırım talebiniz bulunmaktadır.",
  4075: "Bekleyen farklı bir çekim talebiniz bulunmaktadır.",
  4076: "Girilen tutar max ve min arasinda degildir.",
  4077: "Bakiyeniz bu işlem için yeterli değildir.",
  4088: "Çekim talebiniz ile ilgili sorun oluştu. Lütfen canlı destek üzerinden bilgi alınız.",
  4099: "Deposit talebiniz ile ilgili sorun oluştu. Lütfen canlı destek üzerinden bilgi alınız.",
  4100: "Giriş talebiniz ile ilgili sorun oluştu. Lütfen canlı destek üzerinden bilgi alınız.",
  4101: "Bahis talebiniz ile ilgili sorun oluştu. Lütfen canlı destek üzerinden bilgi alınız.",
  4102: "",
  4103: "",
  4104: "",
  4105: "TC kimlik numaranız uyuşmuyor.",
  4122: "Beklenilmeyen bir hata oluştu, lütfen daha sonra tekrar deneyin.",
  4123: "Lütfen giriş bilgilerinizi kontrol edip tekrar deneyiniz.",
};

export default errorMessages;
