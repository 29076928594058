import styled from "styled-components";
import { Box, Button, ButtonGroup, Link } from "@mui/material";
import { uniTokens } from "../../theme/uniTokens";

export const StyledMobileHeaderContainer = styled.div`
  background-color: ${uniTokens.green};
  display: flex;
  align-items: center;
  color: ${uniTokens.white};
  justify-content: space-between;
  overflow: auto;
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: start;
  color: ${uniTokens.white};
`;
export const StyledMainLogo = styled.img`
  height: 50px;
  width: 110px;
  margin-left: 10px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-right: 10px;
`;
export const StyledButton = styled(Button)`
  background-color: ${uniTokens.green};
  color: ${uniTokens.white};
`;

export const StyledBox = styled(Box)`
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  font-weight: 700;
  gap: 10px;
  margin-right: -10rem;
`;
