import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
} from "@mui/material";
import {
  StyledFormGroup,
  StyledHeader,
  StyledDateContainer,
  StyledFormHelperText,
} from "./Registration.styled";
import { FC } from "react";
import { UniInput } from "../../components/CustomComponents";
import useCustomTranslation from "../../utils/hooks/useCustomTranslation";
import { days, months, years } from "../../constants/dates";
import { InputTypes } from "./Registration";
import useIsMobile from "../../utils/hooks/useIsMobile";
import { uniTokens } from "../../theme/uniTokens";

interface RegistrationFirstPageProps {
  formChange: (type: InputTypes, value: string) => void;
  formState: RegistrationFirstPageState;
}

export interface RegistrationFirstPageState {
  firstName: string;
  lastName: string;
  email: string;
  day: string;
  month: string;
  year: string;
  gender: string;
  firstNameError: string;
  lastNameError: string;
  emailError: string;
}

const RegistrationFirstPage: FC<RegistrationFirstPageProps> = ({
  formChange,
  formState,
}) => {
  const { t } = useCustomTranslation();
  const isMobile = useIsMobile();
  const handleInputChange = (type: InputTypes) => (value: string) => {
    formChange(type, value);
  };

  const handleGenderChange = (gender: string) => {
    formChange(InputTypes.GENDER, gender);
  };

  const [dateFieldsSelected, setDateFieldsSelected] = useState(false);

  const isDateIncomplete =
    dateFieldsSelected &&
    (formState.day === "" || formState.month === "" || formState.year === "");

  return (
    <div>
      <StyledFormGroup sx={{ width: "100%" }}>
        <StyledHeader variant="h6" sx={{ fontSize: "2rem" }}>
          {t(`registration.firstHeader`)}
        </StyledHeader>
        <UniInput
          label={t(`registration.firstName`)}
          onChange={handleInputChange(InputTypes.FIRST_NAME)}
          value={formState.firstName}
          error={!!formState.firstNameError}
          helperText={formState.firstNameError}
        />
        <UniInput
          label={t(`registration.lastName`)}
          onChange={handleInputChange(InputTypes.LAST_NAME)}
          value={formState.lastName}
          error={!!formState.lastNameError}
          helperText={formState.lastNameError}
        />
        <UniInput
          label={t(`registration.email`)}
          onChange={handleInputChange(InputTypes.EMAIL)}
          value={formState.email}
          error={!!formState.emailError}
          helperText={formState.emailError}
        />
        <Typography sx={{ fontSize: "1.6rem" }}>
          {t(`registration.dateOfBirth`)}
        </Typography>
        <StyledDateContainer>
          <Select
            sx={{ minWidth: "30%", m: 0, height: "40px", fontSize: "1.6rem" }}
            onChange={(event) => {
              handleInputChange(InputTypes.DAY)(event.target.value as string);
              setDateFieldsSelected(true);
            }}
            value={formState.day}
          >
            {days.map((day) => (
              <MenuItem
                value={day}
                key={day}
                disabled={day === "Day"}
                sx={{ fontSize: "1.4rem" }}
              >
                {day}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{ minWidth: "30%", height: "40px", fontSize: "1.6rem" }}
            onChange={(event) => {
              handleInputChange(InputTypes.MONTH)(event.target.value as string);
              setDateFieldsSelected(true);
            }}
            value={formState.month}
          >
            {months.map((month) => (
              <MenuItem
                value={month}
                key={month}
                disabled={month === "Month"}
                sx={{ fontSize: "1.6rem" }}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
          <Select
            sx={{ minWidth: "30%", height: "40px", fontSize: "1.6rem" }}
            onChange={(event) => {
              handleInputChange(InputTypes.YEAR)(event.target.value as string);
              setDateFieldsSelected(true);
            }}
            value={formState.year}
          >
            {years.map((year) => (
              <MenuItem
                value={year}
                key={year}
                disabled={year === "Year"}
                sx={{ fontSize: "1.4rem" }}
              >
                {year}
              </MenuItem>
            ))}
          </Select>

          {isDateIncomplete && (
            <StyledFormHelperText>
              {t(`registration.errorMessages.dateOfBirth`)}
            </StyledFormHelperText>
          )}
        </StyledDateContainer>
        <FormControl>
          <FormLabel sx={{ fontSize: "1.6rem" }}>
            {t(`registration.gender`)}
          </FormLabel>
          {isMobile ? (
            <ButtonGroup
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                border: "1px solid black",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleGenderChange("F");
                }}
                sx={{
                  backgroundColor:
                    formState.gender === "F"
                      ? uniTokens.green
                      : uniTokens.white,
                  color:
                    formState.gender === "F"
                      ? uniTokens.white
                      : uniTokens.black,
                  width: "50%",
                  border: "0.5px solid black",
                  fontSize: "12",
                }}
              >
                {t(`registration.female`)}
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleGenderChange("M");
                }}
                sx={{
                  backgroundColor:
                    formState.gender === "M"
                      ? uniTokens.green
                      : uniTokens.white,
                  color:
                    formState.gender === "M"
                      ? uniTokens.white
                      : uniTokens.black,
                  width: "50%",
                  border: "0.5px solid black",
                  fontSize: 12,
                }}
              >
                {t(`registration.male`)}
              </Button>
            </ButtonGroup>
          ) : (
            <RadioGroup
              name="gender"
              value={formState.gender}
              row
              onChange={(event) => {
                handleInputChange(InputTypes.GENDER)(event.target.value);
              }}
            >
              <FormControlLabel
                value="F"
                control={<Radio />}
                label={t(`registration.female`)}
                onChange={(checked) =>
                  handleInputChange(InputTypes.GENDER)(
                    checked ? "male" : "female",
                  )
                }
                componentsProps={{ typography: { fontSize: "1.6rem" } }}
              />
              <FormControlLabel
                value="M"
                control={<Radio size="medium" />}
                label={t(`registration.male`)}
                onChange={(checked) =>
                  handleInputChange(InputTypes.GENDER)(
                    checked ? "female" : "male",
                  )
                }
                componentsProps={{ typography: { fontSize: "1.6rem" } }}
              />
            </RadioGroup>
          )}
        </FormControl>
      </StyledFormGroup>
    </div>
  );
};

export default RegistrationFirstPage;
