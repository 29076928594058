import { FC } from "react";
import SliderContainer from "../components/SliderContainer/SliderContainer";
import HomePromotions from "../components/HomePromotions/HomePromotions";
import { Banner } from "~/Banner/Banner";
import { KasinoPromotions } from "~/KasinoPromotions/KasinoPromotions";

const Index: FC = () => (
  <div style={{ marginBottom: "auto" }}>
    <Banner />
    <KasinoPromotions />
    {/* <SliderContainer />
    <HomePromotions /> */}
  </div>
);

export default Index;
