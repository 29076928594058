import { FC } from "react";

interface IMoneyAmountProps {
  amount?: number;
}

const MoneyAmount: FC<IMoneyAmountProps> = ({ amount }) => {
  if (amount === undefined) {
    return null;
  }
  return <span>{parseFloat(String(amount)).toFixed(Math.abs(2))} ₺</span>;
};
export default MoneyAmount;
