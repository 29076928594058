import styled from "styled-components";
import { uniTokens } from "../../theme/uniTokens";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export const StyledMobileNavBar = styled.div`
  background-color: ${uniTokens.background};
  display: flex;
  align-items: center;
  height: 65px;
  color: ${uniTokens.white};
  justify-content: space-between;
  max-width: 100%;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledList = styled(List)`
  display: flex;
  gap: 4rem;
  margin-left: 2rem;
  margin-right: 0rem;
  width: 100%;
  flex-direction: row;
  background-color: ${uniTokens.backgroundSecondary};
`;

export const StyledListItem = styled(ListItemButton)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  margin-left: 0.5rem;

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  color: ${uniTokens.textSecondary};
  width: auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 0.5rem;
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${uniTokens.textSecondary};
  display: inline-block;
  min-width: unset;
`;

export const StyledNavBarList = styled(List)`
  display: flex;
  gap: 1.2rem;
  margin-top: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;
