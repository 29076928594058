import styled from "styled-components";

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;

  ${(props) => props.theme.breakpoints.down("md")} {
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
  }
`;
